import React, { useState } from "react"
import { ReactComponent as LikeIcon } from "Container/icons/like.svg"
import { ReactComponent as DislikeIcon } from "Container/icons/dislike.svg"
import { ReactComponent as LikeActiveIcon } from "Container/icons/likeActive.svg"
import { ReactComponent as DislikeActiveIcon } from "Container/icons/dislikeActive.svg"
import ratingApi from "./helper"
import DislikeModalComponent from "./DislikeModalComponent"

const RatingComponent = ({ order }) => {
  const [currentRating, setCurrentRating] = useState({
    pending: false,
    value: order.rating,
  })
  const [modalOpen, toggleModal] = useState(false)
  const withoutRating =
    currentRating.value === null || currentRating.value === undefined

  const likeActive = !withoutRating && currentRating.value
  const dislikeActive = !withoutRating && !currentRating.value

  return (
    <div className="RatingWrapper">
      {withoutRating && <div className="RatingTitle">Как вам доставка?</div>}
      <div className="RatingButtonsWrapper">
        <div
          className={`RatingButton ${
            !withoutRating ? `RatingButtonDisabled` : `RatingButtonActive`
          }`}
          onClick={() => {
            if (withoutRating) {
              ratingApi({
                orderId: order.id,
                value: true,
                ratingData: currentRating,
                setRatingData: setCurrentRating,
                disData: null,
              })
            }
          }}
        >
          {likeActive ? <LikeActiveIcon /> : <LikeIcon />}
        </div>
        <div
          className={`RatingButton ${
            !withoutRating ? `RatingButtonDisabled` : `RatingButtonActive`
          }`}
          onClick={() => {
            if (withoutRating) toggleModal(true)
          }}
        >
          {dislikeActive ? <DislikeActiveIcon /> : <DislikeIcon />}
        </div>
        {modalOpen && (
          <DislikeModalComponent
            close={() => toggleModal(false)}
            orderId={order.id}
            setCurrentRating={setCurrentRating}
            currentRating={currentRating}
          />
        )}
      </div>
    </div>
  )
}

export default RatingComponent
