import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { ReactComponent as BackIcon } from "Container/icons/arrow-back.svg"
import { ReactComponent as AttachIcon } from "Container/icons/attach-mini.svg"
import { MESSAGE_ROUTE } from "Container/BaseLayout/constants"
import moment from "moment"
import { getFileIcon } from "Container/Orders/RatingComponent/helper"
import { ReactComponent as DownloadIcon } from "Container/icons/download.svg"
import getMessage, { downloadMessageFiles } from "./helper"

const getAttachWord = value => {
  if (value === 1) return `вложение`
  if (value > 1 < 5) return `вложения`
  if (value > 4) return `вложений`
  return `вложений`
}

const MessagePreviewRoute = ({ ultimateLogout, currentHall }) => {
  const history = useHistory()
  const { id } = useParams()
  const [data, setData] = useState({})
  const haveFiles = data && data.files && !!data.files.length
  useEffect(
    () =>
      getMessage({
        id,
        data,
        history,
        setData,
        ultimateLogout,
        hallNumber: currentHall,
      }),
    [],
  )
  const [loadPending, loadSetPending] = useState(false)
  const contentArray = data.content ? data.content.split(`\n\n`) : []
  return (
    <div className="MessagePreviewWrapper WidthAll">
      <div
        className="MessagePreviewBackWrapper FlexRow"
        onClick={() => history.push(MESSAGE_ROUTE)}
      >
        <BackIcon />
        <div className="MessagePreviewBackText">Назад</div>
      </div>
      <div className="MessagePreviewContentWrapper">
        <div className="FlexRow">
          <div className="MessageContentTitle">{data.title}</div>
          <div className="MessageContentDate">
            {moment(data.scheduledDate).format(`DD.MM.YYYY HH:mm`)}
          </div>
        </div>
        <div className="MessageContentBody">
          {contentArray.map(item => (
            <div className="MessageContentPart">{item}</div>
          ))}
        </div>
        {haveFiles && (
          <div className="MessageFilesWrapper">
            <div className="MessageFilesRectangle" />
            <div className="FlexRow">
              <div style={{ paddingTop: `2px` }}>
                <AttachIcon />
              </div>
              <div className="MessageFilesInfoCount">{`${
                data.files.length
              } ${getAttachWord(data.files.length)}`}</div>
              {data.files.length > 1 && (
                <div
                  className={`MessageFilesInfoAll ${
                    loadPending ? `` : `MessageFilesInfoAllHover`
                  }`}
                  onClick={() => {
                    if (!loadPending) {
                      downloadMessageFiles({
                        ultimateLogout,
                        history,
                        id,
                        loadSetPending,
                        hallNumber: currentHall,
                      })
                    }
                  }}
                >
                  Сохранить все
                </div>
              )}
            </div>
            <div className="MessageFileItemsWrapper">
              {data.files.map(file => (
                <div className="MessageUploadItemRender">
                  <div className="UploadItemTitle">
                    <div
                      className="UploadItemIcon"
                      style={{ marginTop: `2px` }}
                    >
                      {getFileIcon(file.type)}
                    </div>
                    <div className="UploadItemLabel UploadDownloadLink">
                      <a href={file.link} download={`${file.fileName}`}>
                        {file.fileName}
                      </a>
                    </div>
                  </div>

                  <div
                    style={{ paddingLeft: `16px` }}
                    className="UploadItemDownload"
                  >
                    <DownloadIcon />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MessagePreviewRoute
