import React from "react"
import { Col, Row } from "antd"
import call from "Container/icons/contacts_call.svg"
import mail from "Container/icons/contacts_mail.svg"

const FAQRoute = () => (
  <Row gutter={24}>
    <Col span={24}>
      <div className="ContactsWrapper">
        <div className="ContactsWrapper-header">
          <h1>Контакты</h1>
        </div>
        <div className="ContactsWrapper-body">
          <p className="ContactsWrapper-desc">
            Возникли вопросы? Свяжись с нами любым удобным способом
          </p>
          <div className="ContactsWrapper-list">
            <div className="ContactsWrapper-item">
              <img
                className="ContactsWrapper-icon"
                alt="Телефон горячей линии"
                src={call}
              />
              <div className="ContactsWrapper-info">
                <strong>Телефон горячей линии:</strong>
                <a className="ContactsWrapper-phone" href="tel:+74951383555">
                  +7 495 138 35 55
                </a>
                <p>Введите добавочный номер:</p>
                <ul>
                  <li>
                    по г. Москве и Московской области —{" "}
                    <span className="highlight">01</span>
                  </li>
                  <li>
                    по г. Ростов и Ростовской области —{" "}
                    <span className="highlight">02</span>
                  </li>
                  <li>
                    по г. Краснодар и Краснодарскому краю —{" "}
                    <span className="highlight">03</span>
                  </li>
                  <li>
                    по г. Тула и Тульской области —{" "}
                    <span className="highlight">04</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="ContactsWrapper-item">
              <img
                className="ContactsWrapper-icon"
                alt="Электронная почта техподдержки"
                src={mail}
              />
              <div className="ContactsWrapper-info">
                <strong>Электронная почта техподдержки:</strong>
                <ul>
                  <li>
                    по г. Москве и Московской области
                    <a href="mailto:zakaz@keytering.plus">
                      zakaz@keytering.plus
                    </a>
                  </li>
                  <li>
                    по г. Ростов и Ростовской области
                    <a href="mailto:rostov@keytering.plus">
                      rostov@keytering.plus
                    </a>
                  </li>
                  <li>
                    по г. Краснодар и Краснодарскому краю
                    <a href="mailto:kdr@keytering.plus">kdr@keytering.plus</a>
                  </li>
                  <li>
                    по г. Тула и Тульской области
                    <a href="mailto:zakaz@keytering.plus">
                      zakaz@keytering.plus
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  </Row>
)

export default FAQRoute
