import React, { useEffect, useState } from "react"
import { Col, Row, Collapse } from "antd"

import betRequest from "api/request"

import { ReactComponent as FAQIcon } from "Container/icons/faq-icon.svg"

import img1 from "Container/icons/images/faq_1.png"
import img2 from "Container/icons/images/faq_2.jpg"
import img4 from "Container/icons/images/faq_4.png"

const { Panel } = Collapse

/**
 * @typedef FileData
 * @property {string} name
 * @property {string} link
 */

/**
 * @typedef FolderData
 * @property {string} folder
 * @property {Array.<FileData>} files
 */

/**
 * @typedef FolderState
 * @property {Array.<FolderData>} data
 * @property {boolean} pending
 */

const FAQRoute = () => {
  const [openCategory, toggleCategory] = useState(null)

  const [fileList, setFileList] = useState(
    /** @type {FolderState} */ {
      data: [],
      pending: false,
    },
  )

  useEffect(() => {
    setFileList({ ...fileList, pending: true })
    betRequest({
      url: `docs`,
      method: `get`,
    })
      .then(({ data }) => setFileList({ data, pending: false }))
      .catch(() => setFileList({ ...fileList, pending: false }))
  }, [])

  return (
    <Row gutter={24}>
      <Col span={18}>
        <div className="FAQWrapper">
          <div className="FAQWrapper-header">
            <h1>Часто задаваемые вопросы</h1>
          </div>
          <div className="FAQWrapper-list">
            <Collapse
              accordion
              defaultActiveKey="1"
              activeKey={openCategory}
              onChange={key => toggleCategory(key)}
              ghost
            >
              {openCategory !== `1` && (
                <div className="FAQItemPanelRectangle" />
              )}
              <Panel
                className={`FAQItemPanel ${
                  openCategory === `1` ? `FAQItemActive` : ``
                }`}
                showArrow={false}
                header="Как сделать заказ?"
                extra={
                  <FAQIcon
                    class={
                      openCategory === `1` ? `FAQIconPlus` : `FAQIconCross`
                    }
                  />
                }
                key="1"
              >
                <p>
                  <img
                    className="f-right"
                    style={{ width: `200px` }}
                    alt="Как сделать заказ"
                    src={img1}
                  />
                  По клику на иконку в шапке сайта или из «Кабинет» → «Прайс
                  товаров» можно перейти к прайсу товаров для заказа.
                </p>
                <p>
                  Для удобства прайс разделён на несколько категорий продукции.
                  При наведении курсора мыши в область категории товара можно
                  просмотреть значения из прайса по выбранной категории. При
                  наведении курсора на пустую область страницы будет
                  прокручиваться весь прайс.
                </p>
                <p className="highlight d-block">
                  Если партнёр авторизуется в сервисе под группой клубов, при
                  заказе ему необходимо выбрать в интерфейсе тот клуб, для
                  которого делается заказ.
                </p>
                <p>
                  <span className="highlight">
                    Для оформления заказа необходимо:
                  </span>
                </p>
                <ol>
                  <li>
                    Выбрать товар, нажав на «+». При этом автоматически
                    проставляется количественное значение «1» и товар добавится
                    в корзину
                  </li>
                  <li>
                    При необходимости количество можно скорректировать в большую
                    сторону, используя ручное заполнение или кнопку «+», при
                    нажатии которой значение увеличится на единицу. Внутри
                    кнопки отобразится общая сумма по товару
                  </li>
                  <li>
                    После добавления товаров в корзину нужно перейти в раздел
                    <span className="highlight">Корзина</span> для оформления
                    заказа. Это можно сделать при помощи кнопки в нижней части
                    экрана или в навигационном меню сверху.
                  </li>
                  <li>
                    После перехода в раздел{" "}
                    <span className="highlight">Корзина</span> и проверки
                    правильности заказа, необходимо перейти к оформлению заказа.{" "}
                    <span className="highlight">Для этого:</span>
                    <br />В правой части страницы корректно заполнить ФИО и
                    номер телефона заказчика, так как данная информация будет
                    передана экспедитору при доставке;{" "}
                  </li>
                  <li>
                    При необходимости оставьте комментарий, который будет учтен
                    сотрудником, принимающим заказ;
                  </li>
                  <li>
                    Нажать кнопку «Оформить заказ». Отобразится уведомление об
                    успешном заказе, после чего заказ отобразится в разделе
                    «Заказы».
                  </li>
                </ol>
                <p>
                  <span className="highlight">Примечание:</span> Минимальная
                  сумма заказа составляет 7 500 рублей. В указанную сумму входят
                  услуги по доставке и разгрузке.
                </p>
                <p>
                  <span className="highlight">Важно:</span> Корректировка или
                  отмена заказа в интернет-магазине не предусмотрена. При
                  необходимости отмены заказа следует связаться с сотрудниками
                  сервиса по телефону{" "}
                  <a href="tel:+79636528284">+7 963 652-82-84</a> или сообщить
                  информацию на электронную почты{" "}
                  <a href="mailto:zakaz@keytering.plus">zakaz@keytering.plus</a>
                  .
                </p>
              </Panel>
              {openCategory !== `1` && openCategory !== `2` && (
                <div className="FAQItemPanelRectangle" />
              )}
              <Panel
                className={`FAQItemPanel ${
                  openCategory === `2` ? `FAQItemActive` : ``
                }`}
                showArrow={false}
                header="Как отследить статус заказа?"
                extra={
                  <FAQIcon
                    class={
                      openCategory === `2` ? `FAQIconPlus` : `FAQIconCross`
                    }
                  />
                }
                key="2"
              >
                <p>
                  <img
                    className="f-right"
                    style={{ width: `200px` }}
                    alt="Как сделать заказ"
                    src={img2}
                  />
                  В сервисе реализован механизм сохранения информации заказов.
                  Заказы в списке отображаются от последнего к первому.
                  Сотруднику Клуба доступна информация о предыдущих заказах по
                  дате и времени, наименованию, количестве, сумме, сотруднику,
                  оформившему заказ. В истории заказов можно посмотреть текущий
                  статус заказа и увидеть комментарий сотрудника Keytering Plus.
                </p>
                <br />
                <p className="highlight">Реализованы следующие статусы:</p>
                <ol>
                  <li>
                    <span className="badge bg-primary">Новый</span> — заказ
                    получен;
                  </li>
                  <li>
                    <span className="badge bg-success">Одобрен</span> — заказ
                    принят в работу;
                  </li>
                  <li>
                    <span className="badge bg-danger">Отклонён</span> — заказ
                    отклонён, выполнение невозможно;
                  </li>
                  <li>
                    <span className="badge bg-warning">Передан поставщику</span>{" "}
                    — запланирована доставка;
                  </li>
                  <li>
                    <span className="badge bg-info">Доставлен</span> — заказ
                    доставлен.
                  </li>
                </ol>
              </Panel>
              {openCategory !== `2` && openCategory !== `3` && (
                <div className="FAQItemPanelRectangle" />
              )}
              <Panel
                className={`FAQItemPanel ${
                  openCategory === `3` ? `FAQItemActive` : ``
                }`}
                showArrow={false}
                header="Как повторить заказ?"
                extra={
                  <FAQIcon
                    class={
                      openCategory === `3` ? `FAQIconPlus` : `FAQIconCross`
                    }
                  />
                }
                key="3"
              >
                <p>
                  При необходимости заказ может повторен с помощью. Для этого
                  необходимо перейти в историю заказов и выбрать нужный заказ.
                  При нажатии кнопки «Повторить заказ» откроется форма,
                  аналогичная форме оформления заказа.
                </p>
                <p>
                  В форме необходимо заполнить личные данные и при необходимости
                  указать комментарий, после чего подтвердить заказ кнопкой
                  «Оформить заказ». Заказ также отобразится в разделе «Заказы».
                </p>
              </Panel>
              {openCategory !== `3` && openCategory !== `5` && (
                <div className="FAQItemPanelRectangle" />
              )}
              <Panel
                className={`FAQItemPanel ${
                  openCategory === `5` ? `FAQItemActive` : ``
                }`}
                showArrow={false}
                header="Как заполнить отчет об остатках в клубе?"
                extra={
                  <FAQIcon
                    class={
                      openCategory === `5` ? `FAQIconPlus` : `FAQIconCross`
                    }
                  />
                }
                key="5"
              >
                <p>
                  <img
                    className="f-right"
                    style={{ width: `200px` }}
                    alt="Как заполнить отчет"
                    src={img4}
                  />
                  Перед размещением нового заказа клуб должен указать, сколько
                  единиц того или иного товара осталось в наличии в клубе.
                </p>
                <p className="highlight">Для добавления отчета необходимо:</p>
                <ol>
                  <li>Перейти в раздел Остатки → Отчет по остаткам;</li>
                  <li>Выбрать из списка нужные товары;</li>
                  <li>Указать количество оставшихся единиц товара;</li>
                  <li>Указать ФИО и контакты того, кто заполняет отчет;</li>
                  <li>
                    Нажать кнопку «Отправить». Отчет будет отправлен сотруднику
                    сервиса Keytering Plus.
                  </li>
                </ol>
                <p>
                  Также можно просмотреть историю отчетов по остаткам в разделе
                  Остатки → История остатков.
                </p>
              </Panel>
              {openCategory !== `5` && (
                <div className="FAQItemPanelRectangle" />
              )}
              <Panel
                className={`FAQItemPanel ${
                  openCategory === `7` ? `FAQItemActive` : ``
                }`}
                showArrow={false}
                header="Документация для работы"
                extra={
                  <FAQIcon
                    class={
                      openCategory === `7` ? `FAQIconPlus` : `FAQIconCross`
                    }
                  />
                }
                key="7"
              >
                <Collapse className="LicenseAccordion" accordion ghost>
                  {fileList.data.map(item => (
                    <Panel
                      header={<div className="highlight">{item.folder}</div>}
                    >
                      <div className="LicenseBlock">
                        {item.files.map(file => (
                          <a href={file.link} target="_blank" rel="noreferrer">
                            {file.name}
                          </a>
                        ))}
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              </Panel>
              {openCategory !== `7` && (
                <div className="FAQItemPanelRectangle" />
              )}
            </Collapse>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default FAQRoute
