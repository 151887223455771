import React, { useState, useEffect } from "react"
import { Select } from "antd"
import Request from "api/request"
import { useHistory } from "react-router-dom"

import "types/main"

/**
 * @callback logoutCallback // logout from everywhere
 */

/**
 * @callback setExceptions
 * @param { Array.<string>} guids // 1 argument - array of product guids
 */

/**
 * @callback setProducts
 * @param { ProductItemData } data // 1 argument - data: { array of product data }[]
 */

/**
 * @typedef SearchProps
 * @property {string} placeholder
 * @property {ProductItemData} productItems
 * @property {Array.<string>} exceptionsData // array of product guids
 * @property {logoutCallback} ultimateLogout
 * @property {setExceptions} setExceptionsData
 * @property {setProducts} setProductItems
 */

/**
 * @param { SearchProps } props
 * @return {JSX.Element}
 */
const Search = ({
  setProductItems,
  productItems,
  placeholder,
  ultimateLogout,
  exceptionsData,
  setExceptionsData,
}) => {
  const history = useHistory()
  const [searchState, setSearch] = useState(/** @type {?string} */ undefined)
  const [options, setOptions] = useState(/** @type {Array<ProductItem>} */ [])

  useEffect(() => {
    if (searchState && searchState?.length > 2) {
      Request({
        ultimateLogout,
        method: `post`,
        url: `leftover/search`,
        requestBody: {
          name: searchState,
          exceptions: exceptionsData?.length ? exceptionsData : undefined,
          hall: localStorage.getItem("hall"),
        },
        history,
      }).then(({ data: { items } }) => {
        const newOptions = (items || []).map(item => ({
          value: item.name,
          label: item.name,
          option: item,
        }))
        setOptions(newOptions)
      })
    } else {
      setOptions([])
    }
  }, [searchState, exceptionsData])

  return (
    <div className="SearchWrapper">
      <Select
        showSearch
        value={null}
        placeholder={placeholder}
        onChange={(value, { option }) => {
          setProductItems({
            ...productItems,
            data: [...productItems.data, { ...option }],
          })
          setExceptionsData([...exceptionsData, option.guid])
          setSearch(undefined)
        }}
        options={options}
        onSearch={search => {
          setSearch(search)
        }}
      />
    </div>
  )
}

export default Search
