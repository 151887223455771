import React, { useState } from "react"
import { Col, Row, notification } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import Request from "api/request"
import { useHistory } from "react-router-dom"
import CustomerData from "Container/CustomerData"
import SearchBar from "./SearchBar"
import GoodsList from "./GoodsList"

const CreateLeftover = ({ ultimateLogout }) => {
  const history = useHistory()
  const [customerData, setCustomerData] = useState({
    fullName: "",
    phone: "",
  })

  const [productItems, setProductItems] = useState({ data: [] })
  const [cartItems, setCartItems] = useState({})
  const [exceptionsData, setExceptionsData] = useState([])

  const cartItemsReq = []

  Object.keys(cartItems).forEach(item => {
    if (cartItems[item].count > 0) {
      cartItemsReq.push({
        count: cartItems[item].count,
        product_guid: item,
        price: cartItems[item].price,
      })
    }
  })

  const sendLeftover = () => {
    const requestData = {
      ...customerData,
      phone: customerData.phone
        .replaceAll(`-`, "")
        .replace(`(`, "")
        .replace(`)`, "")
        .replace(`+`, ""),
      address: localStorage.getItem("address"),
      hall: localStorage.getItem("hall"),
      items: cartItemsReq,
    }

    Request({
      ultimateLogout,
      method: `post`,
      url: `leftover/create`,
      requestBody: requestData,
      history,
    }).then(() => {
      setProductItems({ data: [] })
      setCartItems({})
      setExceptionsData([])
      setCustomerData({
        fullName: "",
        phone: "",
      })
      notification.success({
        message: `Отчет отправлен.`,
        icon: <CheckCircleOutlined style={{ color: `#25BA7C` }} />,
      })
    })
  }

  return (
    <Row gutter={24}>
      <Col className="LeftoverColumns">
        <div className="Leftover">
          <div className="Leftover-header">
            <h1>Отчёт по остаткам</h1>
          </div>
          <div className="Leftover-searchBar">
            <SearchBar
              productItems={productItems}
              setProductItems={setProductItems}
              ultimateLogout={ultimateLogout}
              exceptionsData={exceptionsData}
              setExceptionsData={setExceptionsData}
            />
          </div>
          <div className="Leftover-goodsList">
            <GoodsList
              cartItems={cartItems}
              setCartItems={setCartItems}
              productItems={productItems}
            />
          </div>
        </div>
      </Col>
      <Col>
        <div className="Leftover-sidebar">
          <CustomerData
            sendLeftover={sendLeftover}
            customerData={customerData}
            setCustomerData={setCustomerData}
            emptyLeftover={Boolean(cartItemsReq && !cartItemsReq.length)}
          />
        </div>
      </Col>
    </Row>
  )
}

export default CreateLeftover
