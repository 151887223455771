import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Select } from "antd"
import { InputComponent } from "Components/Input"
import TableComponent from "Components/Table"
import StatusComponent from "Components/Status"
import { ButtonDefault } from "Components/Button"
import { RangePickerComponent } from "Components/DatePicker"
import SelectComponent from "Components/Select"
import { ReactComponent as LikeIcon } from "Container/icons/like.svg"
import { ReactComponent as LikeActiveIcon } from "Container/icons/likeActive.svg"
import { ReactComponent as DislikeActiveIcon } from "Container/icons/dislikeActive.svg"

import { ReactComponent as ExcelIcon } from "Container/icons/icon-excel.svg"

import { downloadFile, useStatuses } from "api/hooks"

import moment from "moment"
import makeCurrency from "api/constants"

const { Option } = Select

const getColorPorno = color =>
  color
    ? `rgb(${color.substr(5, 100).split(`,`).slice(0, 3).join(`, `)})`
    : `#fff`

const Orders = ({ ultimateLogout }) => {
  const history = useHistory()
  const [searchValue, setSearchValue] = useState({})
  const [sortValue, setSortValue] = useState({})
  const [loadPending, loadSetPending] = useState(false)
  const { data: statusData, pending: statusPending } = useStatuses({
    ultimateLogout,
    history,
  })
  const columns = [
    {
      title: `Номер заказа`,
      key: `id`,
      dataIndex: `id`,
      className: `TableWrapper-black`,
    },
    {
      title: `Дата создания`,
      key: `date`,
      dataIndex: `date`,
      render: date => (date ? moment(date).format(`DD.MM.YYYY`) : null),
    },
    {
      title: `Сумма`,
      key: `sum`,
      dataIndex: `sum`,
      render: sum => <div>{makeCurrency(sum)} ₽</div>,
    },
    {
      title: `Клуб`,
      key: `hallNumber`,
      dataIndex: `hallNumber`,
      className: `TableWrapper-black2`,
    },
    {
      title: `Контактное лицо`,
      key: `fullName`,
      dataIndex: `fullName`,
      className: `TableWrapper-black2`,
    },
    {
      title: `Телефон`,
      key: `phone`,
      dataIndex: `phone`,
      className: `TableWrapper-black2`,
    },
    {
      title: `Статус`,
      key: `status`,
      dataIndex: `status`,
      render: (status, { statusComment }) => (
        <StatusComponent
          id={status}
          withComment={statusComment}
          statusData={statusData}
          adminStatus
        />
      ),
    },
    {
      key: `id`,
      dataIndex: `id`,
      responsive: [false],
      render: () => <div />,
    },
    {
      title: `Оценка`,
      key: `rating`,
      dataIndex: `rating`,
      render: rating =>
        // eslint-disable-next-line no-nested-ternary
        rating === 1 ? (
          <LikeActiveIcon />
        ) : rating === 0 ? (
          <DislikeActiveIcon />
        ) : (
          ``
        ),
    },
  ]
  return (
    <div>
      <div className="AdminPanel-contentTitle">
        <h1>Заказы</h1>
      </div>
      <div className="AdminPanel-contentHeader">
        <div className="AdminPanel-contentLeft">
          <div className="AdminPanel-contentCell InputWrapper-ordersSearch">
            <InputComponent
              placeholder="Введите номер клуба"
              size="small"
              onKeyDown={({ target: { value }, key }) => {
                if (key === `enter` || key === `Enter`) {
                  setSearchValue({
                    ...searchValue,
                    hallNumber: value || undefined,
                  })
                }
              }}
            />
          </div>
          <div className="AdminPanel-contentCell AdminPanel-rangepicker-wrapper">
            <RangePickerComponent
              size="small"
              value={[searchValue.dateStart, searchValue.dateFinish]}
              placeholder={[`Выберите`, `даты`]}
              onChange={value => {
                setSearchValue({
                  ...searchValue,
                  dateStart: value
                    ? value[0].startOf(`day`).valueOf()
                    : undefined,
                  dateFinish: value
                    ? value[1].endOf(`day`).valueOf()
                    : undefined,
                })
              }}
            />
          </div>
          <div className="AdminPanel-contentCell SelectComponent-status">
            <SelectComponent
              style={{ width: `184px` }}
              placeholder="Все статусы"
              value={searchValue.status}
              onChange={value => {
                setSearchValue({ ...searchValue, status: value })
              }}
              disabled={statusPending}
              loading={statusPending}
              size="small"
              allowClear
            >
              {statusData.map(item => (
                <Option value={item.id} key={`${item.id}_${item.status}`}>
                  <div className="DisplayRow">
                    <div
                      className="StatusRound"
                      style={{
                        backgroundColor: getColorPorno(item.commentColor),
                      }}
                    />
                    <div>{item.status}</div>
                  </div>
                </Option>
              ))}
            </SelectComponent>
          </div>
          <div className="AdminPanel-contentCell AdminPanel-contentCell-rating">
            <div
              className="ButtonDefault-icon"
              onClick={() => {
                if (searchValue.rating === undefined) {
                  setSearchValue({ ...searchValue, rating: 1 })
                }
                if (searchValue.rating === 1) {
                  setSearchValue({ ...searchValue, rating: 0 })
                }
                if (searchValue.rating === 0) {
                  setSearchValue({ ...searchValue, rating: undefined })
                }
              }}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {searchValue.rating === 1 ? (
                <LikeActiveIcon />
              ) : searchValue.rating === 0 ? (
                <DislikeActiveIcon />
              ) : (
                <LikeIcon />
              )}
            </div>
          </div>
        </div>
        <div className="AdminPanel-contentRight">
          <div className="AdminPanel-contentCell ButtonDefault-unloadToExcel">
            <ButtonDefault
              disabled={loadPending}
              size="small"
              onClick={() =>
                downloadFile({
                  ultimateLogout,
                  history,
                  source: `admin/order/list`,
                  fileName: `Список заказов.xls`,
                  loadSetPending,
                  sort: sortValue,
                  search: searchValue,
                })
              }
            >
              <div className="ButtonDefault-icon">
                <ExcelIcon />
              </div>
              Выгрузить в Excel
            </ButtonDefault>
          </div>
        </div>
      </div>
      <div className="AdminPanel-contentProductsTable">
        <TableComponent
          source="admin/order/list"
          columns={columns}
          rowClick={({ id }) => history.push(`/admin/orders/${id}`)}
          search={searchValue}
          ultimateLogout={ultimateLogout}
          externalChangeSort={setSortValue}
        />
      </div>
    </div>
  )
}

export default Orders
