import React, { useState, useEffect, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import makeCurrency, {
  getUnitData,
  getPrices,
  getCartPrices,
  checkProductInPrice,
} from "api/constants"
import { useNow } from "api/hooks"
import {
  getRepeatOrder,
  getCartItems,
  updateCart,
} from "Container/PriceList/helper"
import CartEmptyIcon from "Container/icons/cart-empty.svg"
import CartFullIcon from "Container/icons/cart-full.svg"
import { ReactComponent as EmptyImage } from "Container/icons/empty.svg"
import { ReactComponent as TruckIcon } from "Container/icons/truck.svg"
import { ReactComponent as InfoIcon } from "Container/icons/info.svg"
import { ReactComponent as CartRedIcon } from "Container/icons/cart_red.svg"
import { ReactComponent as CancelCircle } from "Container/icons/cancel_circle.svg"
import { ReactComponent as ClockIcon } from "Container/icons/clock.svg"
import { ReactComponent as HelpIcon } from "Container/icons/help.svg"
import { useCart } from "context"
import { CheckBoxDefault } from "Components/CheckBox"
import { ButtonDefault } from "Components/Button"
import Modal from "Components/Modal"
import { debounce } from "lodash"
import Input from "Components/Input"
import { Tooltip } from "antd"
import DeliveryInfo from "./DeliveryInfo"
import { CustomerDataNew } from "../CustomerData"
import PriceListTable from "./PriceListTable"
import { sendOrder } from "./OrderData"

const PriceList = ({ ultimateLogout, currentAddress, currentHall, isCart }) => {
  const history = useHistory()
  const [repeatId, setRepeatId] = useState(null)
  const [isRepeat, setIsRepeat] = useState(false)
  const location = useLocation()
  const {
    changeCart,
    setMinValue,
    setAmount,
    updateActiveCart,
    state,
    removeRepeatOrders,
    getRepeatOrders,
  } = useCart()

  const [priceData, setPriceData] = useState({
    data: [],
    active: null,
    pending: false,
  })

  const currentPrice = priceData.data.find(
    price => price.id === priceData.active,
  )?.name

  const [priceDataCart, setPriceDataCart] = useState({
    data: [],
    active: null,
    pending: false,
  })
  const [unitData, setUnitData] = useState({})
  const [cartItems, setCartItems] = useState({})
  const [checkedCartItems, setCheckedCartItems] = useState({})
  const checkedCartItemKeys = Object.values(checkedCartItems)
  // const checkData = checkedCartItemKeys.map
  const [isEmptyCart, setIsEmptyCart] = useState(true)
  const [comment, setComment] = useState(``)
  const [updatePrices, setUpdatePrices] = useState(false)
  const [customerData, setCustomerData] = useState({
    fullName: ``,
    phone: ``,
  })
  const [priceDataView, setPriceDataView] = useState({ data: [] })
  const [scrollHeight, setScrollHeight] = useState(545)
  const [isCheckedAll, setisCheckedAll] = useState(false)
  const [selectedGuiuds, setSelectedGuids] = useState([])
  const [changeCheckModal, setChangeCheckModal] = useState(false)
  const [orderPending, setOrderPending] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [search, setSearch] = useState(undefined)
  const [searchDebounced, setSearchDebounced] = useState(undefined)

  const repeatOrders = getRepeatOrders()

  const currentActive =
    priceData.data.find(item => item.id === priceData.active) || []
  const currentActiveMinSum = currentActive.minSum

  useEffect(() => getUnitData({ ultimateLogout, setUnitData, history }), [])

  useEffect(() => {
    updateActiveCart(priceData.active)
  }, [priceData.active])

  useEffect(() => {
    setPriceDataCart(prev => {
      const nextData = [...prev.data]
      const nextItemIdx = prev.data.findIndex(el => el.id === priceData.active)
      if (nextItemIdx !== -1 && nextData[nextItemIdx].totalCount) {
        nextData[nextItemIdx] = {
          ...nextData[nextItemIdx],
          amount: state.totalPrice,
        }
      }
      return { ...prev, data: nextData }
    })
  }, [state])

  const { refresh, timestamp } = useNow()

  const [checkPending, toggleCheckPending] = useState(false)

  const getCartPricesHandler = id => {
    setPriceDataCart({ ...priceDataCart, pending: true })
    getCartPrices({
      ultimateLogout,
      setUnitData,
      history,
      hall: currentHall,
    })
      .then(({ data: cartPrices = [] }) => {
        toggleCheckPending(false)
        const haveId = !!id
        const someConditionsShit =
          id > 0 ? id : cartPrices[0] ? cartPrices[0].id : false
        const requestId = haveId ? someConditionsShit : false
        setAmount(cartPrices)
        setPriceDataCart({
          ...priceDataCart,
          data: cartPrices,
          active: requestId,
          pending: false,
        })
      })
      .catch(() => {
        toggleCheckPending(false)
      })
  }

  useEffect(() => {
    const splitted = location.pathname.split("/")
    const id = Number(splitted[splitted.length - 1])
    const isRepeatNew = splitted[splitted.length - 2] === "repeat"
    if (isRepeatNew) {
      setIsRepeat(true)
      setRepeatId(id)
    } else {
      setIsRepeat(false)
      setRepeatId(null)
    }
    getCartPricesHandler(id)
    getPrices({
      ultimateLogout,
      setUnitData,
      history,
      hall: currentHall,
    }).then(({ data = [] }) => {
      setPriceData({
        ...priceData,
        data,
        active: id >= 0 ? id : data[0] ? data[0].id : false,
      })
    })
  }, [currentHall, updatePrices, timestamp])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (isRepeat && priceData.data.length && repeatOrders?.length) {
      getRepeatOrder({
        history,
        id: repeatId,
        setCartItems,
        ultimateLogout,
        priceData,
        setPriceData,
        priceDataView,
        setPriceDataView,
        hall: currentHall,
        // for check repeat
        toggleCheckPending,
        setChangeCheckModal,
        setCheckedCartItems,
        repeatGoods: repeatOrders,
      })
    }
  }, [isRepeat, priceData.data.length])

  const getCartItemsHandler = () =>
    getCartItems({
      user: priceData.active,
      currentHall,
      history,
      setCartItems,
      ultimateLogout,
      priceData,
      setPriceData,
      setPriceDataView,
      isCart,
    })

  useEffect(() => {
    if (priceData.active !== null && !isRepeat) {
      getCartItemsHandler()
    }
  }, [priceData.data.length, priceData.active])

  useEffect(() => {
    changeCart(cartItems)
  }, [cartItems])

  useEffect(() => {
    setMinValue(currentActiveMinSum)
  }, [priceData])

  const closeModal = useCallback(() => {
    setChangeCheckModal(false)
    removeRepeatOrders()
  }, [removeRepeatOrders])

  const goToPriceList = useCallback(() => {
    history.push(`/price-list/${currentActive.id}`)
    removeRepeatOrders()
  }, [history, currentActive, removeRepeatOrders])

  const createOrder = () => {
    setOrderPending(true)
    if (toggleCheckPending) {
      toggleCheckPending(true)
    }
    const cartGuids = Object.keys(cartItems).reduce((acc, item) => {
      if (item) {
        acc.push(item)
      }
      return acc
    }, [])
    checkProductInPrice({
      ultimateLogout,
      guids: cartGuids,
      priceListId: priceData.active,
      hall: currentHall,
    })
      .then(() => {
        setShowSuccessModal(false)
        setCustomerData({ fullName: "", phone: "" })
        setComment("")
        setCartItems({})
        updateCart({
          guid: [Object.keys(cartItems)],
          hall: currentHall,
          quantity: 0,
        })
        sendOrder({
          cartItems,
          ultimateLogout,
          changeOrderPending: setOrderPending,
          toggleModal: setShowSuccessModal,
          customerData,
          history,
          comment,
          removeRepeatOrders,
          isRepeat,
          priceId: priceData.active,
          getCartPricesHandler,
          toggleCheckPending,
        })
      })
      .catch(() => {
        setOrderPending(false)
      })
  }

  const debouncedSearch = useCallback(
    debounce(value => {
      setSearchDebounced(value)
    }, 300),
    [],
  )

  const onSearchChange = event => {
    setSearch(event.target.value)
    debouncedSearch(event.target.value)
  }

  const repeatObjItems = {}

  if (isRepeat) {
    const checkKeys = Object.keys(checkedCartItems)
    const cartItemsKeys = Object.keys(cartItems)

    const itemsWithoutBad = cartItemsKeys.filter(item => {
      if (!checkKeys.includes(item) || checkedCartItems[item]?.priceChanged) {
        repeatObjItems[item] = cartItems[item]
      }
      return !checkKeys.includes(item)
    })
  }

  const { state: contextState } = useCart()

  const itemsCheckRepeat = isRepeat ? repeatObjItems : cartItems

  const [priceDataState, setPriceDataState] = useState(priceData.data)

  // set initial priceDataState...because
  useEffect(() => {
    setPriceDataState(priceData.data)
  }, [priceData.data?.length])
  // clear list if its card and we clear all goods from this price list...because
  useEffect(() => {
    setPriceDataState(priceData.data)
    if (isCart && !contextState.totalPrice) {
      const clearCartList = priceData.data?.filter(
        item => item.id !== contextState.activeId,
      )
      setPriceDataState(clearCartList)
    }
  }, [contextState.totalPrice])

  return (
    <>
      <div className="PriceList">
        <div className="PriceList-header">
          <h1>{isCart ? `Корзина` : `Прайс товаров`}</h1>
        </div>
        <DeliveryInfo priceData={priceData} />
        <div>
          {priceData.active ? (
            <div>
              <div className="AccessPriceWrapperCart">
                <div className="AccessPriceWrapper AccessPriceWrapperPriceList">
                  {priceDataState.map(item => {
                    const isPriceActive = item.id === priceData.active
                    let isEmpty = true
                    const amountCurrent = priceDataCart.data.find(
                      el => el.id === item.id,
                    )?.amount
                    if (amountCurrent) {
                      isEmpty = Number(amountCurrent.replace(",", ".")) <= 0
                    }
                    if (isPriceActive) {
                      isEmpty = Number(state.totalPrice) <= 0
                    }
                    // show only full carts
                    if (isEmpty && isCart) {
                      return <div className="DisplayNone" />
                    }
                    return (
                      <div
                        key={`${item.id}-PriceListColumns`}
                        className={`AccessPriceItemWrapper ${
                          isPriceActive ? `AccessPriceItemActiveWrapper` : ``
                        }`}
                        onClick={() => {
                          if (!isPriceActive) {
                            setPriceData({ ...priceData, active: item.id })
                            if (isCart) {
                              history.push(`/cart/${item.id}`)
                              removeRepeatOrders()
                              setIsRepeat(false)
                            }
                          }
                        }}
                      >
                        <div className="AccessPriceItem">
                          <div>
                            <div className="AccessPriceItemLabel">
                              {item.name}
                            </div>
                            <div className="AccessPriceItemMinSumContainer">
                              <InfoIcon />
                              <div className="AccessPriceItemMinSumContainerText">{`Заказ от ${makeCurrency(
                                item.minSum,
                              )} ₽`}</div>
                            </div>
                          </div>
                          <div
                            className="AccessPriceItemIconWrapper"
                            key={item.id + item.name}
                          >
                            {isCart ? (
                              priceDataCart.pending || priceData.pending ? (
                                <div>Loading</div>
                              ) : isEmpty ? (
                                <img
                                  src={CartEmptyIcon}
                                  alt="Cart Empty Icon"
                                />
                              ) : (
                                <img src={CartFullIcon} alt="Cart Full Icon" />
                              )
                            ) : (
                              <TruckIcon />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {isCart && (
                  <div className="PriceList-sidebar">
                    <CustomerDataNew
                      customerData={customerData}
                      currentPrice={currentPrice}
                      setCustomerData={setCustomerData}
                      currentAddress={currentAddress}
                      currentHall={currentHall}
                      fixedPosition
                      setIsEmptyCart={setIsEmptyCart}
                      cartItems={cartItems}
                      setCartItems={setCartItems}
                      comment={comment}
                      setComment={setComment}
                      ultimateLogout={ultimateLogout}
                      setScrollHeight={setScrollHeight}
                      minSum={currentActiveMinSum}
                      onCreateOrder={createOrder}
                      showSuccessModal={showSuccessModal}
                      orderPending={orderPending}
                      setShowSuccessModal={setShowSuccessModal}
                    />
                  </div>
                )}
              </div>
              {!isCart && priceData.active && (
                <Input
                  value={search}
                  placeholder="Поиск товаров"
                  onChange={onSearchChange}
                  customClass="PriceListSearchInput"
                />
              )}
              <div
                className={`Tab-body ${isCart ? "Tab-body_cart" : ""} ${
                  priceData.active && Object.keys(cartItems).length <= 0
                    ? "Tab-body_empty"
                    : ""
                }`}
              >
                {isCart &&
                  priceData.active &&
                  Object.keys(cartItems).length > 0 && (
                    <div className="CartItemsHeaderContainer">
                      <div className="CartItemsHeaderElWrapper">
                        <CheckBoxDefault
                          checked={isCheckedAll}
                          onClick={() => {
                            setisCheckedAll(!isCheckedAll)
                            if (!isCheckedAll) {
                              setSelectedGuids(Object.keys(cartItems))
                            } else {
                              setSelectedGuids([])
                            }
                          }}
                        />
                        <div>Выбрать все</div>
                      </div>
                      <div className="CartItemsHeaderElWrapper CartItemsHeaderElWrapper_cart">
                        <CartRedIcon
                          onClick={() => {
                            updateCart({
                              guid: selectedGuiuds,
                              hall: currentHall,
                              quantity: 0,
                              getCartPricesHandler,
                            })

                            setPriceDataView(prev => {
                              const next = { ...prev }
                              for (const price of next.data) {
                                price.goods = price.goods.filter(
                                  good =>
                                    !selectedGuiuds.includes(good.productGuid),
                                )
                              }
                              return next
                            })
                            setCartItems(prev => {
                              const next = Object.keys(prev)
                                .filter(key => !selectedGuiuds.includes(key))
                                .reduce((newObj, key) => {
                                  newObj[key] = cartItems[key]
                                  return newObj
                                }, {})
                              return next
                            })
                            setSelectedGuids([])
                            setisCheckedAll(false)
                          }}
                        />
                        <div>Удалить выбранные</div>
                      </div>
                    </div>
                  )}
                {isCart &&
                  priceData.active &&
                  Object.keys(cartItems).length <= 0 && (
                    <div className="Tab-body_empty_container">
                      <div className="Tab-body_cart_empty_header">
                        Корзина ждёт, пока её наполнят
                      </div>
                      <ButtonDefault
                        onClick={() => history.push("/price-list")}
                        customClass="Tab-body_cart_empty_button"
                      >
                        В каталог
                      </ButtonDefault>
                    </div>
                  )}
                {!isCart && priceData.active && priceDataView.data.length <= 0 && (
                  <div className="Tab-body_empty_container">
                    <div className="Tab-body_price_empty_header">
                      Сейчас в этом прайсе нет товаров :(
                    </div>
                    <div className="Tab-body_price_empty_sub-header">
                      Возможно всё закончилось, попробуйте вернуться позже
                    </div>
                    <EmptyImage />
                  </div>
                )}
                <PriceListTable
                  refreshList={refresh}
                  user={priceData.active}
                  currentPrice={currentPrice}
                  unitData={unitData}
                  isRepeat={isRepeat}
                  cartItems={itemsCheckRepeat}
                  setCartItems={setCartItems}
                  ultimateLogout={ultimateLogout}
                  currentHall={currentHall}
                  priceDataView={priceDataView}
                  setPriceDataView={setPriceDataView}
                  isCart={isCart}
                  setUpdatePrices={setUpdatePrices}
                  updatePrices={updatePrices}
                  isEmptyCart={isEmptyCart}
                  minSum={currentActiveMinSum}
                  setSelectedGuids={setSelectedGuids}
                  selectedGuids={selectedGuiuds}
                  cartDataViewItems={priceDataCart.data}
                  search={searchDebounced}
                />
              </div>
            </div>
          ) : (
            <div>Нет доступных прайс листов.</div>
          )}
        </div>
        {checkPending && <Modal onlyChildren />}
        {isCart && changeCheckModal && (
          <Modal close={closeModal} bodyClassName="ChangeCheckModal_wrapper">
            <div className="ChangeCheckModal_container">
              <div className="ChangeCheckModal_title">Изменения по товарам</div>
              <div className="ChangeCheckModal_changed_items_container">
                {checkedCartItemKeys.map(checkedCartItemKeysItem => (
                  <div className="ChangeCheckModal_changed_item_container">
                    <div className="ChangeCheckModal_changed_item_title">
                      {checkedCartItemKeysItem.name}
                    </div>
                    {checkedCartItemKeysItem.itemAvailableSoon && (
                      <div className="ChangeCheckModal_changed_item_available-soon_container PriceTable-info-badge_container">
                        <ClockIcon />
                        <div className="ChangeCheckModal_changed_item_available-soon_title PriceTable-isAvailableSoon_text">
                          Скоро появится
                        </div>
                      </div>
                    )}
                    {checkedCartItemKeysItem.itemDeleted && (
                      <div className="ChangeCheckModal_changed_item_deleted_container PriceTable-info-badge_container">
                        <CancelCircle />
                        <div className="ChangeCheckModal_changed_item_deleted_container_title">
                          Не осталось
                        </div>
                      </div>
                    )}
                    {checkedCartItemKeysItem.priceChanged &&
                      !checkedCartItemKeysItem.itemDeleted &&
                      !checkedCartItemKeysItem.itemAvailableSoon && (
                        <div className="ChangeCheckModal_changed_item_price_container">
                          <div className="ChangeCheckModal_changed_item_price_old">
                            {`${makeCurrency(checkedCartItemKeysItem.price)} ₽`}
                          </div>
                          <div className="ChangeCheckModal_changed_item_price_new">
                            {`${makeCurrency(
                              checkedCartItemKeysItem.priceNew,
                            )} ₽`}
                          </div>
                          <Tooltip
                            title={checkedCartItemKeysItem.priceChangedTooltip}
                            overlayClassName="ChangeCheckModal_changed_item_price_new_Tooltip"
                            trigger={["click", "hover"]}
                            color="#fff"
                          >
                            <HelpIcon className="ChangeCheckModal_changed_item_price_new_TooltipIcon" />
                          </Tooltip>
                        </div>
                      )}
                  </div>
                ))}
              </div>
              <div className="ChangeCheckModal_buttons-container">
                <ButtonDefault
                  customClass="ChangeCheckModal_button_close_modal"
                  onClick={closeModal}
                >
                  Продолжить
                </ButtonDefault>
                <ButtonDefault onClick={goToPriceList}>
                  Заменить товары
                </ButtonDefault>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}

export default PriceList
