import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { InputComponent } from "Components/Input"
import TableComponent from "Components/Table"
import { ButtonDefault } from "Components/Button"
import moment from "moment"
import { ReactComponent as ExcelIcon } from "Container/icons/icon-excel.svg"
import { downloadFile } from "api/hooks"

const columns = [
  {
    title: `Номер клуба`,
    key: `hallNumber`,
    dataIndex: `hallNumber`,
    className: `TableWrapper-black2`,
  },
  {
    title: `Дата создания`,
    key: `date`,
    dataIndex: `date`,
    render: date => (date ? moment(date).format(`DD.MM.YYYY`) : null),
  },
  {
    title: `Контактное лицо`,
    key: `fullName`,
    dataIndex: `fullName`,
    className: `TableWrapper-black2`,
  },
  {
    title: `Телефон`,
    key: `phone`,
    dataIndex: `phone`,
    className: `TableWrapper-black2`,
  },
  {
    key: `id`,
    dataIndex: `id`,
    responsive: [false],
    render: () => <div />,
  },
]

const Leftovers = ({ ultimateLogout }) => {
  const history = useHistory()
  const [searchValue, setSearchValue] = useState({})
  const [sortValue, setSortValue] = useState({})
  const [loadPending, loadSetPending] = useState(false)
  return (
    <div>
      <div className="AdminPanel-contentTitle">
        <h1>Остатки</h1>
      </div>
      <div className="AdminPanel-contentHeader">
        <div className="AdminPanel-contentLeft">
          <div className="AdminPanel-contentCell InputWrapper-leftOversSearch">
            <InputComponent
              placeholder="Введите номер клуба"
              allowClear
              size="small"
              onKeyDown={({ target: { value }, key }) => {
                if (key === `enter` || key === `Enter`) {
                  setSearchValue({ search: value || undefined })
                }
              }}
            />
          </div>
        </div>
        <div className="AdminPanel-contentRight">
          <div className="AdminPanel-contentCell ButtonDefault-unloadToExcel">
            <ButtonDefault
              disabled={loadPending}
              size="small"
              onClick={() =>
                downloadFile({
                  ultimateLogout,
                  history,
                  source: `admin/leftover/list`,
                  fileName: `Список остатков.xls`,
                  loadSetPending,
                  search: searchValue,
                  sort: sortValue,
                })
              }
            >
              <div className="ButtonDefault-icon">
                <ExcelIcon />
              </div>
              Выгрузить в Excel
            </ButtonDefault>
          </div>
        </div>
      </div>
      <div className="AdminPanel-contentTable">
        <TableComponent
          source="admin/leftover/list"
          columns={columns}
          rowClick={({ id }) => history.push(`/admin/leftover/${id}`)}
          search={searchValue}
          ultimateLogout={ultimateLogout}
          externalChangeSort={setSortValue}
        />
      </div>
    </div>
  )
}

export default Leftovers
