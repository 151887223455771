import makeCurrency from "api/constants"
import React from "react"

const InsertGoods = ({ goods }) =>
  goods.map(item => (
    <div key={`${item.name}_${item.category}`} className="OrdersGoods-row">
      <div className="OrdersGoods-item OrdersGoods-itemTitle">
        <span>{item.name}</span>
      </div>
      <div className="OrdersGoods-item OrdersGoods-itemCategory">
        <span className="s-show">Категория:</span>
        <span className="OrdersGoodsItemCategory">{item.category}</span>
      </div>
      <div className="OrdersGoods-item OrdersGoods-itemPrice">
        <span className="s-show">Цена:</span>
        <span className="OrdersGoodsItemCategory">{`${makeCurrency(
          item.price,
        )} ₽`}</span>
      </div>
      <div className="OrdersGoods-item OrdersGoods-itemCount">
        <span className="s-show">Кол-во:</span>
        <span className="OrdersGoodsItemCategory">{item.count}</span>
      </div>
      <div className="OrdersGoods-item OrdersGoods-itemComment">
        <span className="s-show">Комментарий к заказу:</span>
        <span className="OrdersGoodsItemCategory">{item.comment}</span>
      </div>
    </div>
  ))

export default InsertGoods
