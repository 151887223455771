import React, { useState } from "react"
import moment from "moment"
import { InputComponent } from "Components/Input"
import { useNow } from "api/hooks"
import { RangePickerComponent } from "Components/DatePicker"
import { ReactComponent as CirclePlusIcon } from "Container/Admin/icons/plus-circle.svg"
import { ReactComponent as ClockIcon } from "Container/Admin/icons/clock.svg"
import TableComponent from "Components/Table"

import Button from "Components/Button"

import MessageModalComponent from "./MessageModal"

const MessageInfo = ({ ultimateLogout }) => {
  const [searchValue, setSearchValue] = useState({})
  const [modalState, setModalState] = useState({ isOpen: false, data: {} })
  const { timestamp, refresh } = useNow()
  const columns = [
    {
      title: `номер`,
      key: `messageId`,
      dataIndex: `messageId`,
      width: `70px`,
    },
    {
      title: `Дата и время отправки`,
      key: `scheduledDate`,
      dataIndex: `scheduledDate`,
      width: `200px`,
      sorter: () => null,
      // TODO: add dateStart to moment
      render: (dateStart, { isSend }) => (
        <div style={{ display: `flex`, flexDirection: `row` }}>
          <div>
            {dateStart ? moment(dateStart).format(`DD.MM.YYYY HH:mm`) : null}
          </div>
          {!isSend && (
            <div style={{ paddingTop: `1px`, paddingLeft: `4px` }}>
              <ClockIcon />
            </div>
          )}
        </div>
      ),
    },
    {
      title: `кол-во клубов`,
      key: `halls`,
      dataIndex: `halls`,
      width: `100px`,
      render: (halls = []) => (halls ? halls.length : null),
    },
    {
      title: `тема`,
      key: `title`,
      dataIndex: `title`,
      width: `300px`,
    },
    {
      title: `текст сообщения`,
      key: `content`,
      dataIndex: `content`,
      width: `300px`,
    },
  ]
  return (
    <div>
      {modalState.isOpen && (
        <MessageModalComponent
          data={modalState.data}
          close={() => setModalState({ data: {}, isOpen: false })}
          ultimateLogout={ultimateLogout}
          refresh={refresh}
        />
      )}
      <div className="AdminPanel-contentTitle">
        <h1>Сообщения</h1>
      </div>
      <div className="AdminPanel-contentHeader">
        <div className="AdminPanel-contentLeft">
          <div className="AdminPanel-contentCell AdminPanel-rangepicker-wrapper">
            <RangePickerComponent
              size="small"
              format="DD-MM-YYYY"
              value={[searchValue.dateStart, searchValue.dateEnd]}
              placeholder={[`Выберите`, `даты`]}
              onChange={value => {
                setSearchValue({
                  ...searchValue,
                  dateStart: value
                    ? value[0].startOf(`day`).valueOf()
                    : undefined,
                  dateEnd: value ? value[1].endOf(`day`).valueOf() : undefined,
                })
              }}
            />
          </div>
          <div className="AdminPanel-contentCell InputWrapper-ordersSearch">
            <InputComponent
              placeholder="Поиск по клубу"
              onKeyDown={({ target: { value }, key }) => {
                if (key === `enter` || key === `Enter`) {
                  setSearchValue({
                    ...searchValue,
                    hallNumber: value || undefined,
                  })
                }
              }}
              size="small"
            />
          </div>
        </div>
        <div className="AdminPanel-contentRight">
          <div className="AdminPanel-contentCell">
            <Button
              size="small"
              onClick={() => setModalState({ data: {}, isOpen: true })}
            >
              <div className="NewMessageButton">
                <CirclePlusIcon />
              </div>
              Добавить сообщение
            </Button>
          </div>
        </div>
      </div>
      <div className="AdminPanel-contentProductsTable">
        <TableComponent
          source="admin/notifications"
          columns={columns}
          rowClick={data => setModalState({ isOpen: true, data })}
          search={searchValue}
          ultimateLogout={ultimateLogout}
          refresh={timestamp}
        />
      </div>
    </div>
  )
}

export default MessageInfo
