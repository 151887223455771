import React, { useState, useEffect } from "react"
import { useCategory, downloadFile } from "api/hooks"
import { useHistory } from "react-router-dom"
import { Select } from "antd"
import { InputComponent } from "Components/Input"
import TableComponent from "Components/Table"
import { SwitchComponent } from "Components/Switch"
import { ButtonDefault } from "Components/Button"
import { ReactComponent as ExcelIcon } from "Container/icons/icon-excel.svg"
import SelectComponent from "Components/Select"
import makeCurrency, { getUnitData } from "api/constants"
import loadPrices from "Container/Admin/Access/AccessPrice/helper"
import updateProduct from "./helper"

const { Option } = Select

const SwitchLikeABitch = ({ show, guid, ultimateLogout, history }) => {
  const [switchValue, setSwitchValue] = useState(show)
  useEffect(() => setSwitchValue(show), [show])
  return (
    <SwitchComponent
      addItemsToCart={() => null}
      checked={switchValue}
      onChange={checked =>
        updateProduct({
          guid,
          field: `show`,
          value: checked,
          setSwitchValue,
          ultimateLogout,
          history,
        })
      }
    />
  )
}

const Products = ({ ultimateLogout }) => {
  const history = useHistory()
  const [searchValue, setSearchValue] = useState({})
  const { data: categoryData, pending: categoryPending } = useCategory({
    ultimateLogout,
    history,
  })
  const [sortValue, setSortValue] = useState({})
  const [loadPending, loadSetPending] = useState(false)
  const [unitData, setUnitData] = useState({})
  const isTovaroved = localStorage.getItem(`role`) === `ROLE_EXPERT`
  const [priceData, setPriceData] = useState({ pending: false, data: [] })

  useEffect(() => {
    if (!isTovaroved) getUnitData({ ultimateLogout, setUnitData })
    loadPrices({
      history,
      searchValue,
      ultimateLogout,
      priceList: priceData,
      setPriceList: setPriceData,
    })
  }, [])

  const columns = [
    {
      title: `Название`,
      key: `name`,
      dataIndex: `name`,
      className: `TableWrapper-black`,
      width: `30%`,
      sorter: () => null,
      render: (count, { promo }) => (
        <div
          className={
            promo
              ? `TableWrapper-itemName TableWrapper-itemName-action`
              : `TableWrapper-itemName`
          }
        >
          <span>{count}</span>
        </div>
      ),
    },
    {
      title: `Тип прайса`,
      key: `priceListName`,
      dataIndex: `priceListName`,
      width: `15%`,
      sorter: () => null,
    },
    {
      title: `Категория`,
      key: `category`,
      dataIndex: `category`,
      width: `15%`,
    },
    {
      title: `Артикул`,
      key: `article`,
      dataIndex: `article`,
      width: `10%`,
    },
    {
      title: `Цена`,
      key: `price`,
      dataIndex: `price`,
      width: `10%`,
      render: (price, { promo }) => (
        <div
          className={
            promo
              ? `TableWrapper-itemPrice TableWrapper-itemPrice-action`
              : `TableWrapper-itemPrice`
          }
        >
          <span>{makeCurrency(price)} ₽</span>
        </div>
      ),
    },
    {
      title: `Кол-во`,
      key: `unitCount`,
      dataIndex: `unitCount`,
      width: `10%`,
      render: (count, { unitId }) => {
        const unitName = unitData[unitId] ? ` ${unitData[unitId].name}` : ``
        return (
          <div>
            {count}
            {unitName}
          </div>
        )
      },
    },
    {
      title: `Отображение`,
      key: `show`,
      dataIndex: `show`,
      fixed: `right`,
      width: `10%`,
      render: (show, { guid }) => (
        <SwitchLikeABitch
          show={show}
          guid={guid}
          ultimateLogout={ultimateLogout}
          history={history}
        />
      ),
    },
    {
      key: `guid`,
      dataIndex: `guid`,
      responsive: [false],
      width: `10%`,
      render: () => <div />,
    },
  ]

  return (
    <div>
      <div className="AdminPanel-contentTitle">
        <h1>Товары</h1>
      </div>
      <div className="AdminPanel-contentHeader">
        <div className="AdminPanel-contentLeft">
          <div className="AdminPanel-contentCell InputWrapper-productsSearch">
            <InputComponent
              size="small"
              placeholder="Название, артикул"
              onKeyDown={({ target: { value }, key }) => {
                if (key === `enter` || key === `Enter`) {
                  setSearchValue({ ...searchValue, search: value || undefined })
                }
              }}
            />
          </div>
          <div className="AdminPanel-contentCell SelectComponent-status">
            <SelectComponent
              style={{ width: `184px` }}
              placeholder="Все категории"
              allowClear
              value={searchValue.category}
              onChange={value => {
                setSearchValue({ ...searchValue, category: value || undefined })
              }}
              disabled={categoryPending}
              loading={categoryPending}
              size="small"
            >
              {categoryData.map(item => (
                <Option value={item || null} key={`${item}`}>
                  {item}
                </Option>
              ))}
            </SelectComponent>
          </div>
          <div className="AdminPanel-contentCell SelectComponent-price">
            <SelectComponent
              style={{ width: `184px` }}
              placeholder="Все прайсы"
              allowClear
              value={searchValue.priceListId}
              onChange={value => {
                setSearchValue({
                  ...searchValue,
                  priceListId: value || undefined,
                })
              }}
              disabled={priceData.pending}
              loading={priceData.pending}
              size="small"
            >
              {(priceData.data || []).map(item => (
                <Option value={item.id || null} key={`price_list_${item}`}>
                  {item.name}
                </Option>
              ))}
            </SelectComponent>
          </div>
        </div>
        <div className="AdminPanel-contentRight">
          <div className="AdminPanel-contentCell ButtonDefault-unloadToExcel">
            <ButtonDefault
              disabled={loadPending}
              size="small"
              onClick={() =>
                downloadFile({
                  ultimateLogout,
                  history,
                  source: `admin/product/list`,
                  fileName: `Список товаров.xls`,
                  loadSetPending,
                  sort: sortValue,
                  search: searchValue,
                })
              }
            >
              <div className="ButtonDefault-icon">
                <ExcelIcon />
              </div>
              Выгрузить в Excel
            </ButtonDefault>
          </div>
        </div>
      </div>
      <div className="AdminPanel-contentTable">
        <TableComponent
          source="admin/product/list"
          columns={columns}
          search={searchValue}
          ultimateLogout={ultimateLogout}
          externalChangeSort={setSortValue}
        />
      </div>
    </div>
  )
}

export default Products
