import { useState, useEffect } from "react"
import Request from "api/request"
import { useHistory } from "react-router-dom"

const useTable = ({ source, extraBody, search, refresh, ultimateLogout }) => {
  const history = useHistory()
  const [tableData, setTableData] = useState({
    data: [],
    pending: false,
    total: 0,
    params: {
      sorter: undefined,
      page: 1,
      perPage: 50,
    },
  })
  const changeSort = sortedValue =>
    setTableData({
      ...tableData,
      params: {
        ...tableData.params,
        sorter: sortedValue,
      },
    })

  useEffect(() => {
    setTableData({ ...tableData, params: { ...tableData.params, page: 1 } })
  }, [search])

  useEffect(() => {
    setTableData({ ...tableData, pending: true })
    Request({
      ultimateLogout,
      url: source,
      method: `post`,
      history,
      requestBody: {
        limit: tableData.params.perPage,
        offset: (tableData.params.page - 1) * tableData.params.perPage,
        sorter: tableData.params.sorter,
        ...search,
        ...extraBody,
      },
    })
      .then(({ data, headers }) => {
        setTableData({
          ...tableData,
          data,
          pending: false,
          total: headers[`total-count`],
        })
      })
      .catch(() => {
        setTableData({ ...tableData, pending: false })
      })
  }, [tableData.params, refresh])

  return { tableData, setTableData, changeSort }
}

export default useTable
