import Request from "api/request"

const makeCurrency = price =>
  Number(price) ? new Intl.NumberFormat("ru-RU").format(price) : `0`

export const onlyUnique = (value, index, self) => self.indexOf(value) === index

export const currencyMultiplication = (currency, count) =>
  Number((Number(currency) * Number(count)).toFixed(2))

export const getUnitData = ({ ultimateLogout, setUnitData, history }) => {
  Request({
    ultimateLogout,
    method: `post`,
    history,
    url: `product/unit`,
  }).then(({ data = [] }) => {
    const unitObject = {}
    data.map(item => {
      unitObject[item.id] = item
      return null
    })
    setUnitData(unitObject)
  })
}

export const getPrices = ({ ultimateLogout, history, hall }) =>
  Request({
    ultimateLogout,
    method: `post`,
    history,
    url: `prices`,
    requestBody: { hall },
  })

export const getCartPrices = ({ ultimateLogout, history, hall }) =>
  Request({
    ultimateLogout,
    method: `post`,
    history,
    url: `cart/prices`,
    requestBody: { hall },
  })

export const checkProductInPrice = ({
  ultimateLogout,
  history,
  hall,
  priceListId,
  guids,
}) =>
  Request({
    ultimateLogout,
    method: `post`,
    history,
    url: `product/check-price`,
    requestBody: { hall, priceListId, guids },
  })

export const checkRole = () => {
  const isAdmin = localStorage.getItem(`role`) === `ROLE_ADMIN`
  const isTovaroved = localStorage.getItem(`role`) === `ROLE_EXPERT`
  const isManager = localStorage.getItem(`role`) === `ROLE_MANAGER`
  return { isAdmin, isManager, isTovaroved }
}

export const prepareDateToBack = dates => {
  if (!dates) {
    return undefined
  }
  const firstDateForFormat = `${dates?.from?.format(
    `YYYY-MM-DD`,
  )}T00:00:00.000+03:00`
  const secondDateForFormat = `${dates?.to?.format(
    `YYYY-MM-DD`,
  )}T23:59:59.000+03:00`

  return { from: firstDateForFormat, to: secondDateForFormat }
}

export default makeCurrency
