import React, { useEffect } from "react"
import CheckBoxComponent from "Components/CheckBox"
import { InputCountComponent, InputCountComponentOld } from "Components/Input"

const InsertGoods = ({ productItems, cartItems, setCartItems }) =>
  productItems.data.map(item => (
    <div className="GoodsList-row">
      <div className="GoodsList-item GoodsList-itemTitle">
        <div className="GoodsList-itemCheck">
          <CheckBoxComponent
            setCartItems={setCartItems}
            cartItems={cartItems}
            product={item}
          />
        </div>
        <span>{item.name}</span>
      </div>
      <div className="GoodsList-item GoodsList-itemHeadCode">
        <span className="s-show">Артикул:</span>
        <span>{item.article}</span>
      </div>
      <div className="GoodsList-item GoodsList-itemCount">
        <span className="s-show">Количество:</span>
        <InputCountComponentOld
          maxCount={200}
          setCartItems={setCartItems}
          cartItems={cartItems}
          product={item}
        />
      </div>
    </div>
  ))

const GoodsList = ({ productItems, setCartItems, cartItems }) => {
  useEffect(() => {
    let cart = {}
    productItems.data.map(item => {
      const itemByGuid = cartItems[item.guid] || {
        price: 0,
        count: 1,
      }
      cart = {
        ...cart,
        [item.guid]: itemByGuid,
      }
      return null
    })

    setCartItems(cart)
  }, [productItems])

  return (
    <div className="GoodsList">
      {productItems.data.length > 0 ? (
        <div>
          <div className="GoodsList-head">
            <div className="GoodsList-item GoodsList-itemTitle">
              <span>Название товара</span>
            </div>
            <div className="GoodsList-item GoodsList-itemVendorCode s-hide">
              <span>Артикул</span>
            </div>
            <div className="GoodsList-item GoodsList-itemCount s-hide">
              <span>Количество (шт.)</span>
            </div>
          </div>
          <div className="GoodsListLeftoverHeaderRectangle" />
          <InsertGoods
            productItems={productItems}
            setCartItems={setCartItems}
            cartItems={cartItems}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default GoodsList
