import React, { useEffect, useMemo, useState } from "react"
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom"
import { Layout, Row, Col, Popover } from "antd"
import Admin from "Container/Admin"
import { ReactComponent as LogoIcon } from "Container/icons/logo.svg"
import { ReactComponent as MailIcon } from "Container/icons/mail.svg"
import { ReactComponent as LeftoverIcon } from "Container/icons/pie-chart.svg"
import { ReactComponent as OrdersIcon } from "Container/icons/orders.svg"
import { ReactComponent as CabinetIcon } from "Container/icons/cabinet.svg"
import { ReactComponent as AdminIcon } from "Container/icons/unlock.svg"
import { ReactComponent as CartIcon } from "Container/icons/shopping-cart.svg"
import { ReactComponent as BuffetIcon } from "Container/icons/buffet.svg"
import useErrorBoundary from "use-error-boundary"
import NotExistPage, {
  ServerErrorPage,
  ErrorBoundaryPage,
} from "Components/ErrorPages"
import Orders from "Container/Orders"
import CreateLeftover from "Container/Leftover/CreateLeftover"
import HistoryLeftover from "Container/Leftover/HistoryLeftover"
import PriceList from "Container/PriceList"
import { logout } from "Container/Login/api"
import LoginPage from "Container/Login/index"
import FAQRoute from "Container/FAQRoute"
import FAQLicense from "Container/FAQRoute/FAQLicense"
import MessageRoute from "Container/Message"
import MessagePreviewRoute from "Container/MessagePreview"
import ContactsRoute from "Container/ContactsRoute"
import Request from "api/request"
import useWebSocket from "react-use-websocket"
import { ButtonDefault } from "Components/Button"
import { CartProvider, useCart } from "context"
import makeCurrency from "api/constants"
import HallSelect from "./hallSelect"

import {
  ERROR_PAGE,
  SERVER_ERROR_PAGE,
  LOGIN_ROUTE,
  FAQ_ROUTE,
  FAQ_LICENSE,
  LEFTOVER_HISTORY_ROUTE,
  LEFTOVER_CREATE_ROUTE,
  ADMIN_ROUTE,
  CART_ROUTE,
  PRICE_LIST_ROUTE,
  REPEAT_ID_ROUTE,
  ORDERS_ROUTE,
  MESSAGE_ROUTE,
  MESSAGE_ID_ROUTE,
  CONTACTS_ROUTE,
  isActive,
  checkRegion,
  redirectBuffet,
} from "./constants"
import NotificationBadges from "./NotificationBadges"

const { Header, Footer, Content } = Layout

const getUrlWithId = pathname => pathname.substr(0, pathname.lastIndexOf("/"))

const PriceListRoute = ({ ultimateLogout, firstPack, isCart }) => (
  <PriceList
    ultimateLogout={ultimateLogout}
    currentAddress={firstPack.data.address}
    currentHall={firstPack.data.hall}
    isCart={isCart}
  />
)

const PriceListRouteCart = ({ ultimateLogout, firstPack }) => (
  <PriceList
    ultimateLogout={ultimateLogout}
    currentAddress={firstPack.data.address}
    currentHall={firstPack.data.hall}
    isCart
  />
)

const OrdersRoute = ({ ultimateLogout, currentHall }) => (
  <Orders ultimateLogout={ultimateLogout} currentHall={currentHall} />
)

const CreateLeftoverRoute = ({ ultimateLogout }) => (
  <CreateLeftover ultimateLogout={ultimateLogout} />
)

const HistoryLeftoverRoute = ({ ultimateLogout, currentHall }) => (
  <HistoryLeftover ultimateLogout={ultimateLogout} currentHall={currentHall} />
)

const FooterComponent = ({ isAuthUser }) => (
  <Footer className="Footer">
    <div className="Container">
      <div className="Footer-row">
        <div className="Footer-copyright">
          <div className="Copyright">
            {`© ${new Date().getFullYear()} KeyteringPlus. Все права защищены.`}
          </div>
        </div>
        <div className="Footer-logo">
          <div className="Logo Logo-footer">
            <div className="Logo-inner">
              <LogoIcon />
            </div>
          </div>
        </div>
        <div className="Footer-contacts">
          <div className="Contacts">
            {isAuthUser && (
              <div className="Contacts-info">
                <Link to={FAQ_ROUTE}>Помощь по сервису</Link>
              </div>
            )}
            {isAuthUser && (
              <div className="Contacts-info">
                <Link to={CONTACTS_ROUTE}>Контакты</Link>
              </div>
            )}
            <div className="Contacts-info">
              <MailIcon />
              <a href="mailto:info@keytering.plus">info@keytering.plus</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Footer>
)

const FooterCart = ({ count, totalPrice, toMin, goToCart }) => {
  const isMinActive = toMin > 0
  return (
    <div className="FooterCartContainer">
      <ButtonDefault
        customClass="FooterCartContainerButton"
        disabled={isMinActive}
        onClick={() => {
          goToCart()
        }}
      >
        <div
          className={`FooterCartContainerButtonInner ${
            isMinActive ? "FooterCartContainerButtonInner_MinActive" : ""
          }`}
        >
          <CartIcon />
          <div className="FooterCartButtonMiddle">{`${count} шт. на сумму`}</div>
          <div className="FooterCartButtonLast">{`${makeCurrency(
            totalPrice,
          )} ₽`}</div>
        </div>
      </ButtonDefault>
      {isMinActive > 0 && (
        <div className="FooterCartText">{`До минимальной суммы заказа осталось: ${makeCurrency(
          toMin,
        )} ₽`}</div>
      )}
    </div>
  )
}

const BaseContainer = ({
  children,
  isAdmin,
  setAuth,
  isManager,
  pathname,
  history,
  ultimateLogout,
  ErrorBoundary,
  firstPack,
  setFirstPack,
  lastMessage,
}) => {
  const params = useParams()

  const [showBage, setShowBage] = useState(true)
  const { state } = useCart()

  const goToCart = () => {
    history.push(`/cart${state.activeId !== null ? `/${state.activeId}` : ""}`)
  }

  const showBuffet = useMemo(
    () => checkRegion(firstPack.data.hall, ultimateLogout),
    [firstPack.data.hall],
  )

  return (
    <Layout>
      <Header className="Header Header-inner">
        <div className="Container">
          <Row>
            <Col span={8}>
              <div
                className="Logo Logo-header"
                onClick={() => {
                  if (!isActive(PRICE_LIST_ROUTE, pathname)) {
                    history.push(PRICE_LIST_ROUTE)
                    if (params.id) history.go(0)
                  }
                }}
              >
                <LogoIcon />
              </div>
            </Col>
            <Col span={16}>
              <div className="Navbar">
                {!(isAdmin || isManager) && (
                  <div className="NavbarSelect">
                    <HallSelect
                      ultimateLogout={ultimateLogout}
                      history={history}
                      firstPack={firstPack}
                      setFirstPack={setFirstPack}
                    />
                  </div>
                )}
                {showBuffet && (
                  <div onClick={redirectBuffet} className="Navbar-item">
                    <BuffetIcon />
                    Фуршет
                  </div>
                )}
                {!!(isAdmin || isManager) && (
                  <Link
                    to={ADMIN_ROUTE}
                    className={`Navbar-item Navbar-item_admin ${
                      isActive(ADMIN_ROUTE, pathname) ? "active" : ""
                    }`}
                  >
                    <AdminIcon />
                    Админ. панель
                  </Link>
                )}
                {!isManager && (
                  <Popover
                    placement="bottom"
                    content={() => (
                      <div>
                        <div className="NavbarPopoverContentWrapper" />
                        <Link to={LEFTOVER_CREATE_ROUTE}>
                          <div className="ProfilePopoverOption ProfilePopoverLink">
                            Отчёт по остаткам
                          </div>
                        </Link>
                        <Link to={LEFTOVER_HISTORY_ROUTE}>
                          <div className="ProfilePopoverOption ProfilePopoverLink">
                            История остатков
                          </div>
                        </Link>
                        <div className="NavbarPopoverContentWrapper" />
                      </div>
                    )}
                    trigger="hover"
                  >
                    <div
                      className={`Navbar-item ${
                        isActive(LEFTOVER_CREATE_ROUTE, pathname) ||
                        isActive(LEFTOVER_HISTORY_ROUTE, pathname)
                          ? "active"
                          : ""
                      }`}
                    >
                      <LeftoverIcon />
                      Остатки
                    </div>
                  </Popover>
                )}
                {!isManager && (
                  <Link
                    to={ORDERS_ROUTE}
                    className={`Navbar-item ${
                      isActive(ORDERS_ROUTE, pathname) ? "active" : ""
                    }`}
                  >
                    <OrdersIcon />
                    Заказы
                  </Link>
                )}
                {!isManager && (
                  <Link
                    to={CART_ROUTE}
                    className={`Navbar-item IconFillPath ${
                      isActive(CART_ROUTE, pathname)
                        ? "active IconFillPath_active"
                        : ""
                    }`}
                  >
                    <CartIcon />
                    Корзина
                    {state.amount > 0 && (
                      <div className="Navbar-item_Cart-notifi" />
                    )}
                  </Link>
                )}
                <Popover
                  placement="bottom"
                  onVisibleChange={() => setShowBage(!showBage)}
                  content={() => (
                    <div>
                      <div className="NavbarPopoverContentWrapper" />
                      <Link
                        onClick={() => {
                          if (params.id) {
                            history.push(PRICE_LIST_ROUTE)
                            history.go(0)
                          }
                        }}
                        to={PRICE_LIST_ROUTE}
                      >
                        <div className="ProfilePopoverOption ProfilePopoverLink">
                          Прайс товаров
                        </div>
                      </Link>
                      <Link to={FAQ_ROUTE}>
                        <div className="ProfilePopoverOption ProfilePopoverLink">
                          Помощь по сервису
                        </div>
                      </Link>
                      <Link to={MESSAGE_ROUTE}>
                        <div className="ProfilePopoverOption ProfilePopoverLink">
                          Сообщения
                          <NotificationBadges lastMessage={lastMessage} show />
                        </div>
                      </Link>
                      <div
                        className="ProfilePopoverOption ProfilePopoverLogout"
                        onClick={() => logout({ history, setAuth })}
                      >
                        Выход
                      </div>
                      <div className="NavbarPopoverContentWrapper" />
                    </div>
                  )}
                  trigger="hover"
                >
                  <div
                    className={`Navbar-item ${
                      isActive(PRICE_LIST_ROUTE, pathname) ||
                      isActive(FAQ_ROUTE, pathname) ||
                      isActive(MESSAGE_ROUTE, pathname) ||
                      (params &&
                        params.id &&
                        isActive(MESSAGE_ROUTE, getUrlWithId(pathname)))
                        ? "active"
                        : ""
                    }`}
                  >
                    <NotificationBadges
                      lastMessage={lastMessage}
                      show={showBage}
                      inMenu
                    />
                    <CabinetIcon />
                    Кабинет
                  </div>
                </Popover>
              </div>
            </Col>
          </Row>
        </div>
      </Header>
      <Content className="Main">
        <ErrorBoundary
          render={() => <div className="Container">{children}</div>}
          renderError={() => (
            <div>
              <ErrorBoundaryPage />
            </div>
          )}
        />
      </Content>
      {pathname.includes("price-list") && state.count > 0 && (
        <FooterCart {...state} goToCart={goToCart} />
      )}
      <FooterComponent isAuthUser />
    </Layout>
  )
}
const MainContent = ({ setAuth }) => {
  const isAdmin = localStorage.getItem(`role`) === `ROLE_ADMIN`
  const isManager = localStorage.getItem(`role`) === `ROLE_MANAGER`
  const isTovaroved = localStorage.getItem(`role`) === `ROLE_EXPERT`
  const { pathname } = useLocation()
  const history = useHistory()
  const ultimateLogout = () => logout({ history, setAuth })
  const { ErrorBoundary } = useErrorBoundary()

  const [firstPack, setFirstPack] = useState({
    data: {
      hall: localStorage.getItem(`hall`) || null,
      halls: [],
      address: localStorage.getItem(`address`) || null,
    },
    pending: false,
  })

  const notAdminUrl = pathname.indexOf("admin") !== 1

  const { lastMessage } = useWebSocket(
    `${
      window.location.protocol === "https:"
        ? `wss://${window.location.hostname}/websocket`
        : `ws://${window.location.hostname}:9003`
    }?hall=${firstPack.data.hall}&token=${localStorage.getItem("token")}`,
    {},
    notAdminUrl,
  )

  useEffect(() => {
    if (!(isManager || isAdmin)) {
      setFirstPack({ ...firstPack, pending: true })
      Request({ url: `halls`, ultimateLogout, history })
        .then(({ data }) => {
          setFirstPack({
            ...firstPack,
            data: { ...firstPack.data, halls: data },
            pending: false,
          })
        })
        .catch(() => setFirstPack({ ...firstPack, pending: false }))
    }
  }, [])

  return (
    <Switch>
      <Switch>
        {!!(isAdmin || isManager || isTovaroved) && (
          <Route path={ADMIN_ROUTE}>
            <Admin setAuth={setAuth} ultimateLogout={ultimateLogout} />
          </Route>
        )}
        {!isTovaroved && (
          <Route path={ORDERS_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <OrdersRoute
                ultimateLogout={ultimateLogout}
                currentHall={firstPack.data.hall}
              />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route path={ORDERS_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <OrdersRoute
                ultimateLogout={ultimateLogout}
                currentHall={firstPack.data.hall}
              />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route path={PRICE_LIST_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <PriceListRoute
                ultimateLogout={ultimateLogout}
                firstPack={firstPack}
              />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route path={CART_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <PriceListRouteCart
                ultimateLogout={ultimateLogout}
                firstPack={firstPack}
              />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route path={REPEAT_ID_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <PriceListRoute
                ultimateLogout={ultimateLogout}
                firstPack={firstPack}
              />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route exact path={FAQ_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <FAQRoute />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route exact path={FAQ_LICENSE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <FAQLicense />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route exact path={MESSAGE_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <MessageRoute
                ultimateLogout={ultimateLogout}
                firstPack={firstPack}
                lastMessage={lastMessage}
              />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route path={MESSAGE_ID_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <MessagePreviewRoute
                ultimateLogout={ultimateLogout}
                currentHall={firstPack.data.hall}
              />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route path={LEFTOVER_CREATE_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <CreateLeftoverRoute ultimateLogout={ultimateLogout} />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route path={LEFTOVER_HISTORY_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <HistoryLeftoverRoute
                ultimateLogout={ultimateLogout}
                currentHall={firstPack.data.hall}
              />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route exact path={ERROR_PAGE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <NotExistPage />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route exact path={SERVER_ERROR_PAGE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <ServerErrorPage />
            </BaseContainer>
          </Route>
        )}
        {!isTovaroved && (
          <Route path={CONTACTS_ROUTE}>
            <BaseContainer
              isAdmin={isAdmin}
              setAuth={setAuth}
              isManager={isManager}
              pathname={pathname}
              history={history}
              ultimateLogout={ultimateLogout}
              ErrorBoundary={ErrorBoundary}
              firstPack={firstPack}
              setFirstPack={setFirstPack}
              lastMessage={lastMessage}
            >
              <ContactsRoute />
            </BaseContainer>
          </Route>
        )}

        <Redirect to={isTovaroved ? ADMIN_ROUTE : PRICE_LIST_ROUTE} />
      </Switch>
    </Switch>
  )
}

const BaseLayout = () => {
  const [isAuth, setAuth] = useState({
    token: localStorage.getItem(`token`),
    role: localStorage.getItem(`role`),
  })

  return (
    <Router>
      <Switch>
        {isAuth.token ? (
          <CartProvider>
            <MainContent setAuth={setAuth} isAuth={isAuth} />
          </CartProvider>
        ) : (
          <Layout>
            <Route path={LOGIN_ROUTE}>
              <LoginPage isAuth={isAuth} setAuth={setAuth} />
            </Route>
            <Redirect to={LOGIN_ROUTE} />
            <FooterComponent />
          </Layout>
        )}
      </Switch>
    </Router>
  )
}

export default BaseLayout
