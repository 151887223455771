import React from "react"
import { ReactComponent as SuccessIcon } from "Container/icons/success.svg"

const SuccessModal = ({ close }) => {
  setTimeout(() => close(), 1000)
  return (
    <div className="SuccessModalWrapper">
      <div className="SuccessWindowWrapper">
        <div>Успешно заказaно!</div>
        <SuccessIcon />
      </div>
    </div>
  )
}

export default SuccessModal
