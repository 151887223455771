import React from "react"
import { Switch } from "antd"

export const SwitchComponent = ({ label, ...rest }) => (
  <div className="SwitchWrapper">
    <Switch {...rest} />
    <span className="SwitchLabel">{label}</span>
  </div>
)

export default SwitchComponent
