import React from "react"
import { DatePicker } from "antd"
import moment from "moment"

const { RangePicker } = DatePicker

const dateField = value => (value ? moment(value) : null)
const rangeField = value =>
  value[0] ? [moment(value[0]), moment(value[1])] : null

const DatePickerComponent = ({ value, error = false, ...rest }) => (
  <div className={`DatePickerWrapper ${error ? `ErrorPickerWrapper` : ``}`}>
    <DatePicker value={dateField(value)} {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const RangePickerComponent = ({ value, error = false, ...rest }) => (
  <div
    className={`RangePickerWrapper ${error ? `ErrorRangePickerWrapper` : ``}`}
  >
    <RangePicker
      value={rangeField(value)}
      style={{ width: `100%` }}
      {...rest}
    />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export default DatePickerComponent
