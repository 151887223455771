import React from "react"

const NotificationBadges = ({ lastMessage, inMenu, show }) => {
  const haveBadge = lastMessage && lastMessage.data > 0
  const message = haveBadge ? lastMessage.data : null
  let classes = "NotificationLabel"
  let styles = {}

  if (inMenu) {
    classes += " BageMenu"
  } else {
    styles = { left: `89px`, top: `99px` }
  }

  if (haveBadge && show) {
    return (
      <div
        style={{
          ...styles,
          width: `${message && Number(message) > 99 ? `22px` : `17px`}`,
        }}
        className={classes}
      >
        <div>{message && Number(message) > 99 ? `99+` : message}</div>
      </div>
    )
  }
  return ""
}

export default NotificationBadges
