import Request from "api/request"
import { ACCESS_PRICE_ROUTE } from "Container/Admin/constatnts"
import { notification } from "antd"

const getPriceItem = ({
  ultimateLogout,
  history,
  id,
  setData,
  data: price,
}) => {
  Request({
    ultimateLogout,
    history,
    url: `admin/access/prices/${id}`,
  }).then(({ data }) => {
    const withHalls = data.type === 2 ? data.halls : null
    const withoutHalls = data.type === 3 ? data.halls : null
    setData({
      ...price,
      name: data.name,
      halls: { with: withHalls, without: withoutHalls },
      type: data.type,
    })
  })
}

export const sendPriceItem = ({ ultimateLogout, history, id, data }) => {
  const { type, halls } = data
  const hallsByType = type === 2 ? `with` : `without`
  Request({
    ultimateLogout,
    history,
    url: `admin/access/prices/${id}/update`,
    method: `post`,
    requestBody: { type, id, halls: halls[hallsByType] },
  })
    .then(() => history.push(ACCESS_PRICE_ROUTE))
    .catch(() =>
      notification.error({
        message: `Ошибка сохранения.`,
      }),
    )
}

export default getPriceItem
