import React from "react"
import betRequest from "api/request"
import { ReactComponent as PngIcon } from "Container/icons/pngFile.svg"
import { ReactComponent as JpgIcon } from "Container/icons/jpegFile.svg"
import { ReactComponent as DocFile } from "Container/icons/docFile.svg"
import { ReactComponent as PdfFile } from "Container/icons/pdfFile.svg"
import { ReactComponent as XlsFile } from "Container/icons/xlsFile.svg"

export const checkFormat = (name = ``) => {
  if (name.search(`.xlsx`) !== -1) return `xls`
  if (name.search(`.xls`) !== -1) return `xls`
  if (name.search(`.png`) !== -1) return `png`
  if (name.search(`.jpeg`) !== -1) return `jpg`
  if (name.search(`.jpg`) !== -1) return `jpg`
  if (name.search(`.docx`) !== -1) return `doc`
  if (name.search(`.doc`) !== -1) return `doc`
  if (name.search(`.pdf`) !== -1) return `pdf`
  return false
}

export const getFileIcon = name => {
  switch (name) {
    case `png`:
      return <PngIcon />
    case `jpg`:
      return <JpgIcon />
    case `doc`:
      return <DocFile />
    case `pdf`:
      return <PdfFile />
    case `xls`:
      return <XlsFile />
    default:
      return <JpgIcon />
  }
}

const ratingApi = ({
  value,
  orderId,
  disData,
  baseFiles = [],
  setRatingData,
  ratingData,
  toggleSuccess,
}) => {
  setRatingData({ ...ratingData, pending: true })
  const baseMap = baseFiles.map(item => item.base64)
  const dislikeData = disData ? { ...disData, files: baseMap } : {}
  betRequest({
    url: `order/rating`,
    method: `post`,
    requestBody: { id: orderId, rating: value, ...dislikeData },
  })
    .then(() => {
      setRatingData({ ...ratingData, pending: false, value })
      if (toggleSuccess && !value) toggleSuccess()
    })
    .catch(() => setRatingData({ ...ratingData, pending: false, value: null }))
}

export default ratingApi
