import Request from "api/request"

const getOrder = ({
  id,
  setOrder,
  setStatusComment,
  ultimateLogout,
  history,
}) => {
  Request({
    ultimateLogout,
    url: `admin/order`,
    method: `post`,
    history,
    requestBody: {
      id,
    },
  }).then(({ data: response }) => {
    setStatusComment(response.statusComment)
    setOrder(response)
  })
}

export const changeComment = ({
  id,
  value,
  toggleDisabledComment,
  ultimateLogout,
  history,
}) => {
  Request({
    ultimateLogout,
    url: `admin/order/update`,
    method: `put`,
    history,
    requestBody: {
      id,
      field: `statusComment`,
      value,
    },
  }).then(() => toggleDisabledComment(true))
}

export default getOrder
