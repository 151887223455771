import Request from "api/request"

const getLeftover = ({ id, setLeftover, ultimateLogout, history }) => {
  Request({
    ultimateLogout,
    url: `admin/leftover`,
    method: `post`,
    history,
    requestBody: {
      id,
    },
  }).then(({ data: response }) => {
    setLeftover(response)
  })
}

export default getLeftover
