import React from "react"
import Search from "Components/Search"

const SearchBar = ({
  setProductItems,
  productItems,
  ultimateLogout,
  exceptionsData,
  setExceptionsData,
}) => (
  <div className="SearchBar">
    <div className="SearchBar-header">
      <h3>Введите название или артикул товара</h3>
    </div>
    <div className="SearchBar-body">
      <Search
        setProductItems={setProductItems}
        productItems={productItems}
        placeholder="Искать товары"
        ultimateLogout={ultimateLogout}
        exceptionsData={exceptionsData}
        setExceptionsData={setExceptionsData}
      />
    </div>
  </div>
)

export default SearchBar
