import React from "react"
import { Layout } from "antd"
import {
  Redirect,
  Route,
  Switch,
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom"
import moment from "moment"
import { ReactComponent as MessageIcon } from "Container/icons/mail.svg"
import { ReactComponent as OrderIcon } from "./icons/package.svg"
import { ReactComponent as ProductIcon } from "./icons/shopping-bag.svg"
import { ReactComponent as LeftoverIcon } from "./icons/pie-chart.svg"
import { ReactComponent as GlobalIcon } from "./icons/global.svg"
import { ReactComponent as LogOutIcon } from "./icons/log-out.svg"
import { ReactComponent as LogoAdminIcon } from "./icons/logoAdmin.svg"
import { ReactComponent as AvatarIcon } from "./icons/avatar.svg"
import { ReactComponent as AccessIcon } from "./icons/key.svg"
import {
  PRODUCTS_ROUTE,
  ORDERS_ROUTE,
  ORDER_ID_ROUTE,
  LEFTOVERS_ROUTE,
  MESSAGE_ROUTE,
  LEFTOVER_ID_ROUTE,
  ACCESS_PRICE_ROUTE,
  ACCESS_PRICE_ID_ROUTE,
} from "./constatnts"
import Products from "./Products"
import Orders from "./Orders"
import OrderInfo from "./Orders/Item"
import Leftovers from "./Leftovers"
import LeftoversInfo from "./Leftovers/Item"
import AccessInfo from "./Access"
import MessageInfo from "./Message"
import AccessPriceItem from "./Access/AccessPrice/PriceItem"

const getUrlWithId = pathname => pathname.substr(0, pathname.lastIndexOf("/"))

const copyrightText = `© ${moment().year()} KeyteringPlus`

const AdminWrapper = ({ children, ultimateLogout }) => {
  const history = useHistory()
  const { id } = useParams()
  const { pathname } = useLocation()
  const isManager = localStorage.getItem(`role`) === `ROLE_MANAGER`
  const isAdmin = localStorage.getItem(`role`) === `ROLE_ADMIN`
  const isTovaroved = localStorage.getItem(`role`) === `ROLE_EXPERT`

  return (
    <Layout className="AdminPanel">
      <aside className="AdminPanel-sidebar">
        <div className="AdminPanel-sidebarBody">
          <div className="Logo Logo-admin">
            <LogoAdminIcon />
          </div>
          <div className="AdminPanel-user">
            <div className="AdminPanel-userIcon">
              <AvatarIcon />
            </div>
            <div className="AdminPanel-info">
              <div className="AdminPanel-infoName">
                {localStorage.getItem(`fullName`)}
              </div>
              <div className="AdminPanel-infoMail">
                {localStorage.getItem(`email`)}
              </div>
            </div>
          </div>
          <div className="AdminPanel-menu">
            {!isManager && (
              <Link
                className={`AdminPanel-row AdminPanel-menuItem ${
                  pathname === PRODUCTS_ROUTE ? `AdminPanel-activeItem` : ``
                }`}
                to={PRODUCTS_ROUTE}
              >
                <div className="AdminPanel-menuIcon">
                  <ProductIcon />
                </div>
                <div className="AdminPanel-menuLink">Товары</div>
              </Link>
            )}
            {!isManager && !isTovaroved && (
              <Link
                className={`AdminPanel-row AdminPanel-menuItem ${
                  pathname === ORDERS_ROUTE ||
                  (id && getUrlWithId(pathname) === ORDERS_ROUTE)
                    ? `AdminPanel-activeItem`
                    : ``
                }`}
                to={ORDERS_ROUTE}
              >
                <div className="AdminPanel-menuIcon">
                  <OrderIcon />
                </div>
                <div className="AdminPanel-menuLink">Заказы</div>
              </Link>
            )}
            {!isTovaroved && (
              <Link
                className={`AdminPanel-row AdminPanel-menuItem ${
                  pathname === LEFTOVERS_ROUTE ||
                  (id && getUrlWithId(pathname) === LEFTOVERS_ROUTE)
                    ? `AdminPanel-activeItem`
                    : ``
                }`}
                to={LEFTOVERS_ROUTE}
              >
                <div className="AdminPanel-menuIcon">
                  <LeftoverIcon />
                </div>
                <div className="AdminPanel-menuLink">Остатки</div>
              </Link>
            )}
            {isAdmin && (
              <Link
                className={`AdminPanel-row AdminPanel-menuItem ${
                  pathname === MESSAGE_ROUTE ||
                  (id && getUrlWithId(pathname) === MESSAGE_ROUTE)
                    ? `AdminPanel-activeItem`
                    : ``
                }`}
                to={MESSAGE_ROUTE}
              >
                <div className="AdminPanel-menuIcon">
                  <MessageIcon />
                </div>
                <div className="AdminPanel-menuLink">Сообщения</div>
              </Link>
            )}
            {isAdmin && (
              <Link
                className={`AdminPanel-row AdminPanel-menuItem ${
                  pathname === ACCESS_PRICE_ROUTE ||
                  pathname === ACCESS_PRICE_ID_ROUTE ||
                  (id && getUrlWithId(pathname) === ACCESS_PRICE_ROUTE) ||
                  (id && getUrlWithId(pathname) === ACCESS_PRICE_ID_ROUTE)
                    ? `AdminPanel-activeItem`
                    : ``
                }`}
                to={ACCESS_PRICE_ROUTE}
              >
                <div className="AdminPanel-menuIcon">
                  <AccessIcon />
                </div>
                <div className="AdminPanel-menuLink">Доступы</div>
              </Link>
            )}
          </div>
          {!isTovaroved && (
            <div
              className="AdminPanel-link AdminPanel-linkHighlig AdminPanel-row"
              onClick={() => history.push(`/`)}
            >
              <div className="AdminPanel-linkIcon">
                <GlobalIcon />
              </div>
              <div>Открыть сайт</div>
            </div>
          )}
          <div
            className="AdminPanel-link AdminPanel-row"
            onClick={ultimateLogout}
          >
            <div className="AdminPanel-linkIcon">
              <LogOutIcon />
            </div>
            <div>Выйти</div>
          </div>
        </div>
        <div className="AdminPanel-sidebarFooter">
          <div className="Copyright Copyright-admin">{copyrightText}</div>
        </div>
      </aside>
      <section className="AdminPanel-content">{children}</section>
    </Layout>
  )
}

const Admin = ({ ultimateLogout }) => {
  const isManager = localStorage.getItem(`role`) === `ROLE_MANAGER`
  const isTovaroved = localStorage.getItem(`role`) === `ROLE_EXPERT`
  return (
    <Switch>
      <Route exact path={PRODUCTS_ROUTE}>
        <AdminWrapper ultimateLogout={ultimateLogout}>
          <Products ultimateLogout={ultimateLogout} />
        </AdminWrapper>
      </Route>
      {!isTovaroved && (
        <Route exact path={ORDERS_ROUTE}>
          <AdminWrapper ultimateLogout={ultimateLogout}>
            <Orders ultimateLogout={ultimateLogout} />
          </AdminWrapper>
        </Route>
      )}
      {!isTovaroved && (
        <Route exact path={ORDER_ID_ROUTE}>
          <AdminWrapper ultimateLogout={ultimateLogout}>
            <OrderInfo ultimateLogout={ultimateLogout} />
          </AdminWrapper>
        </Route>
      )}
      {!isTovaroved && (
        <Route exact path={LEFTOVERS_ROUTE}>
          <AdminWrapper ultimateLogout={ultimateLogout}>
            <Leftovers ultimateLogout={ultimateLogout} />
          </AdminWrapper>
        </Route>
      )}
      {!isTovaroved && (
        <Route exact path={LEFTOVER_ID_ROUTE}>
          <AdminWrapper ultimateLogout={ultimateLogout}>
            <LeftoversInfo ultimateLogout={ultimateLogout} />
          </AdminWrapper>
        </Route>
      )}
      {!isTovaroved && (
        <Route exact path={ACCESS_PRICE_ROUTE}>
          <AdminWrapper ultimateLogout={ultimateLogout}>
            <AccessInfo ultimateLogout={ultimateLogout} />
          </AdminWrapper>
        </Route>
      )}
      {!isTovaroved && (
        <Route exact path={MESSAGE_ROUTE}>
          <AdminWrapper ultimateLogout={ultimateLogout}>
            <MessageInfo ultimateLogout={ultimateLogout} />
          </AdminWrapper>
        </Route>
      )}
      <Route exact path={ACCESS_PRICE_ID_ROUTE}>
        <AdminWrapper ultimateLogout={ultimateLogout}>
          <AccessPriceItem ultimateLogout={ultimateLogout} />
        </AdminWrapper>
      </Route>
      <Redirect to={isManager ? LEFTOVERS_ROUTE : PRODUCTS_ROUTE} />
    </Switch>
  )
}

export default Admin
