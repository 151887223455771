import React, { useEffect, useMemo, useState } from "react"
import { Input } from "antd"
import { ButtonDefault } from "Components/Button"
import InputMask from "react-input-mask"
import { updateCart } from "Container/PriceList/helper"
import makeCurrency from "api/constants"
import { useCart } from "context"

export const InputComponent = ({
  placeholder,
  customClass,
  error = false,
  ...rest
}) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``}`}>
    <Input placeholder={placeholder} {...rest} className={customClass || ``} />
    {!!error && <div className="ErrorText">someError</div>}
  </div>
)

export const InputWithMask = ({
  placeholder,
  customClass,
  mask,
  error = false,
  ...rest
}) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``} `}>
    <InputMask
      placeholder={placeholder}
      {...rest}
      clearMaskOnLostFocus={false}
      className={`InputMaskWrapper ${customClass || ``}`}
      mask={mask}
    />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const InputCountComponentDefault = ({
  maxCount,
  error = false,
  ...rest
}) => {
  const [count, setCount] = useState("")

  const onChange = e => {
    if (e.target.value <= maxCount) setCount(e.target.value)
  }

  const onClickMinus = () => {
    if (count > 0) setCount(+count - 1)
  }

  const onClickPlus = () => {
    if (count < maxCount) setCount(+count + 1)
  }

  return (
    <div
      className={`InputCountWrapper ${error ? `ErrorInputCountWrapper` : ``}`}
    >
      <ButtonDefault onClick={onClickMinus} className="InputCountButtonLeft">
        -
      </ButtonDefault>
      <Input
        value={count}
        onChange={onChange}
        className="InputCount"
        {...rest}
      />
      <ButtonDefault onClick={onClickPlus} className="InputCountButtonRight">
        +
      </ButtonDefault>
      {!!error && <div className="ErrorText">someError</div>}
    </div>
  )
}

export const InputArea = ({
  placeholder,
  addButton,
  error = false,
  ...rest
}) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``}`}>
    <Input.TextArea placeholder={placeholder} {...rest} />
    {!!addButton && <div className="ButtonInsideArea">{addButton}</div>}
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const InputCountComponent = ({
  refreshList,
  maxCount,
  cartItems,
  setCartItems,
  product,
  tobbaccoDisabled,
  isCart,
  withSum,
  isAvailableSoon,
  category,
  currentPriceName,
  cartCountPending,
  setCardCountPending,
  ...rest
}) => {
  const { updateAmount } = useCart()
  const currentHall = localStorage.getItem("hall") || null
  const id = product.productGuid || product.guid

  const multiplicity = useMemo(
    () =>
      !!product && product.multiplicity ? Number(product.multiplicity) : 1,
    [product],
  )

  const currentMaxCount = useMemo(
    () => (multiplicity === 30 ? 600 : maxCount),
    [multiplicity, maxCount],
  )

  const currentPrice = useMemo(
    () => Number(product.price) * multiplicity,
    [product, multiplicity],
  )

  const isPlusDisabled = useMemo(
    () => cartItems[id]?.count === currentMaxCount || tobbaccoDisabled,
    [cartItems, id, currentMaxCount, tobbaccoDisabled],
  )

  const isCartActive = useMemo(
    () => isCart || !!cartItems[id]?.count,
    [isCart, cartItems, id],
  )

  const updateCartAndAmount = (resultCount, zeroAllowed) => {
    if (setCardCountPending) {
      setCardCountPending(true)
    }
    setCartItems(prevCartItems => {
      const updatedCartItems = {
        ...prevCartItems,
        [id]: {
          ...prevCartItems[id],
          name: product.name,
          count: resultCount,
          price: currentPrice,
          available: product.available,
          isDeleted: product.isDeleted,
          multiplicity,
        },
      }
      if (resultCount <= 0 && !zeroAllowed) {
        delete updatedCartItems[id]
      }
      return updatedCartItems
    })
    updateCart({
      guid: id,
      hall: currentHall,
      quantity: resultCount,
      product,
      currentPriceName,
      resetCountPending: () => {
        if (setCardCountPending) {
          setCardCountPending(false)
        }
      },
    })
    if (refreshList) {
      refreshList()
    }
  }

  useEffect(() => {
    if (isAvailableSoon && isCart) {
      updateCartAndAmount(0, true)
    }
  }, [isAvailableSoon, isCart])

  const onClickMinus = () => {
    const count = +cartItems[id]?.count
    if (count > 0) {
      const resultCount = count - multiplicity
      updateCartAndAmount(resultCount > 0 ? resultCount : 0)
      updateAmount(currentPrice * -1)
    }
  }

  const onClickPlus = () => {
    const count = +cartItems[id]?.count || 0
    if (count + multiplicity <= currentMaxCount) {
      const resultCount = count + multiplicity
      updateCartAndAmount(resultCount)
      updateAmount(currentPrice)
    }
  }

  const isDisabled = useMemo(
    () => cartItems[id]?.count === 0 || tobbaccoDisabled,
    [cartItems, id, tobbaccoDisabled],
  )

  const totalSum = useMemo(
    () => ((cartItems[id]?.count || 0) / multiplicity) * currentPrice,
    [cartItems, id, multiplicity, currentPrice],
  )

  return (
    <div className="InputCountWrapper">
      {isCartActive ? (
        <>
          <ButtonDefault
            disabled={isDisabled || cartCountPending}
            onClick={onClickMinus}
            className="InputCountButtonLeft InputCountActiveBorder"
          >
            -
          </ButtonDefault>
          <div className="MultiplicityCountWrapper InputCountActiveBorder MultiplicityCountWrapperActiveBorder">
            <div>{cartItems[id]?.count || ""}</div>
            {withSum && (
              <div className="MultiplicityCountWrapper_sum">
                {`${makeCurrency(totalSum.toFixed(2))} ₽`}
              </div>
            )}
          </div>
          <ButtonDefault
            onClick={onClickPlus}
            disabled={isPlusDisabled || isAvailableSoon || cartCountPending}
            className="InputCountButtonRight InputCountActiveBorder MultiplicityCountWrapperActiveBorder"
          >
            +
          </ButtonDefault>
        </>
      ) : (
        <ButtonDefault
          onClick={onClickPlus}
          disabled={isAvailableSoon || isPlusDisabled || cartCountPending}
          className={`InputCountButtonEmpty ${
            isAvailableSoon || isPlusDisabled || cartCountPending
              ? "InputCountButtonEmpty_disabled"
              : ""
          }`}
        >
          В корзину
        </ButtonDefault>
      )}
    </div>
  )
}

export const InputCountComponentOld = ({
  maxCount,
  cartItems,
  setCartItems,
  product,
  tobbaccoDisabled,
  ...rest
}) => {
  const multiplicity =
    !!product && product.multiplicity ? Number(product.multiplicity) : 1
  const currentMaxCount = !!multiplicity && multiplicity === 30 ? 600 : maxCount

  const isPlusDisabled =
    (cartItems[product.guid] &&
      cartItems[product.guid].count === currentMaxCount) ||
    tobbaccoDisabled

  const onChange = e => {
    if (e.target.value <= currentMaxCount) {
      setCartItems({
        ...cartItems,
        [product.guid]: { ...cartItems[product.guid], count: e.target.value },
      })
    }
  }

  const onClickMinus = () => {
    if (+cartItems[product.guid].count > 0) {
      setCartItems({
        ...cartItems,
        [product.guid]: {
          ...cartItems[product.guid],
          count: +cartItems[product.guid].count - multiplicity,
        },
      })
    }
  }

  const onClickPlus = () => {
    const count = cartItems[product.guid].count
      ? Number(cartItems[product.guid].count)
      : 0

    if (count + multiplicity <= currentMaxCount) {
      setCartItems({
        ...cartItems,
        [product.guid]: {
          ...cartItems[product.guid],
          count: +cartItems[product.guid].count + multiplicity,
        },
      })
    }
  }

  const isDisabled =
    (cartItems[product.guid] && cartItems[product.guid].count === 0) ||
    tobbaccoDisabled

  return (
    <div className="InputCountWrapper InputCountWrapperOld">
      {cartItems[product.guid] !== undefined ? (
        <>
          <ButtonDefault
            disabled={isDisabled}
            onClick={onClickMinus}
            className="InputCountButtonLeft InputCountActiveBorder"
          >
            -
          </ButtonDefault>
          {multiplicity && multiplicity !== 1 ? (
            <div className="MultiplicityCountWrapper InputCountActiveBorder MultiplicityCountWrapperActiveBorder">
              {cartItems[product.guid].count < 1
                ? ""
                : cartItems[product.guid].count}
            </div>
          ) : (
            <Input
              disabled={isDisabled}
              value={
                cartItems[product.guid].count < 1
                  ? ""
                  : cartItems[product.guid].count
              }
              onChange={onChange}
              className="InputCount InputCountActiveBorder MultiplicityCountWrapperActiveBorder"
              {...rest}
            />
          )}
          <ButtonDefault
            onClick={onClickPlus}
            disabled={isPlusDisabled}
            className="InputCountButtonRight InputCountActiveBorder MultiplicityCountWrapperActiveBorder"
          >
            +
          </ButtonDefault>
        </>
      ) : (
        ""
      )}
    </div>
  )
}

export default InputComponent
