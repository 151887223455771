import React, { useState } from "react"
import { Tooltip } from "antd"
import Request from "api/request"
import { ReactComponent as CommentIcon } from "./comment.svg"

const StatusComponent = ({
  id,
  withComment,
  statusData = [],
  adminStatus,
  viewedComment,
  orderId,
}) => {
  const statuses = {}
  statusData.map(item => {
    statuses[item.id] = item
    return null
  })
  const [showView, updateView] = useState(
    viewedComment && viewedComment === `0`,
  )

  const updateStatus = () => {
    if (showView && orderId) {
      Request({ url: `order/viewed?id=${orderId}` })
        .then(() => {
          updateView(false)
        })
        .catch(() => null)
    }
  }
  return (
    <div>
      {statuses[id] ? (
        <div
          style={{
            backgroundColor: statuses[id].background,
          }}
          className="StatusComponent"
        >
          <div
            className={`StatusComponent-label ${
              adminStatus ? ` StatusComponent-admin` : `StatusComponent-user`
            }`}
          >
            {statuses[id].status}
          </div>
          {withComment ? (
            <div
              className={`StatusComponent-icon ${
                adminStatus
                  ? ` StatusComponent-icon-admin`
                  : `StatusComponent-icon-user`
              }`}
              style={{ backgroundColor: statuses[id].commentColor }}
            >
              <Tooltip
                placement="bottom"
                title={withComment}
                onVisibleChange={visible => {
                  if (visible) {
                    updateStatus()
                  }
                }}
                onClick={e => e.stopPropagation()}
                overlayClassName="StatusComponentTooltip"
                trigger="click"
                color="#fff"
              >
                <div>
                  <div className="CommentIconWrapper">
                    <CommentIcon />
                  </div>
                  {!!showView && <div className="NewStatusCommentLarge" />}
                  {!!showView && <div className="NewStatusCommentSmall" />}
                </div>
              </Tooltip>
            </div>
          ) : (
            <div />
          )}
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

export default StatusComponent
