import Request from "api/request"
import moment from "moment"
import { notification } from "antd"

export const removeMessage = ({
  id,
  ultimateLogout,
  history,
  close,
  refresh,
}) => {
  Request({
    url: `admin/notification/remove/${id}`,
    method: `DELETE`,
    ultimateLogout,
    history,
  })
    .then(() => {
      notification.success({
        message: `Сообщение №${id} успешно удалено.`,
      })
      close()
      refresh()
    })
    .catch(() =>
      notification.error({
        message: `Ошибка сохранения.`,
      }),
    )
}

const saveMessage = ({
  messageId,
  baseFiles = [],
  editFiles = [],
  data,
  ultimateLogout,
  history,
  close,
  refresh,
  setFormPending,
}) => {
  const baseMap = baseFiles.map(item => ({
    file: item.base64,
    name: item.name,
  }))
  const editMap = editFiles.filter(item => !!item.delete)
  setFormPending(true)
  Request({
    url: !messageId
      ? `admin/notification/create`
      : `admin/notification/update/${messageId}`,
    method: !messageId ? `POST` : `PUT`,
    ultimateLogout,
    history,
    requestBody: {
      ...data,
      scheduledDate: data.scheduledDate || moment().valueOf(),
      files: [...baseMap, ...editMap],
    },
  })
    .then(({ data: response }) => {
      setFormPending(false)
      const createText = data.scheduledDate
        ? `Ваше сообщение${
            response && response.id ? ` № ${response.id}` : ``
          } успешно запланированно на ${moment(data.scheduledDate).format(
            "DD.MM.YYYY в HH:mm",
          )}`
        : `Ваше сообщение${
            response && response.id ? ` № ${response.id}` : ``
          } отправлено`
      notification.success({
        message: !messageId
          ? createText
          : `Сообщение №${messageId} успешно отредактировано.`,
      })
      close()
      refresh()
    })
    .catch(error => {
      setFormPending(false)
      notification.error({
        message:
          error.response.data.message || `Не удолось отправить сообщение`,
      })
    })
}

export default saveMessage
