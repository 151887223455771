import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"

import { downloadFile } from "api/hooks"

import TableComponent from "Components/Table"
import { ButtonDefault } from "Components/Button"
import { ReactComponent as ArrowIcon } from "Container/icons/arrow-left.svg"

import moment from "moment"
import { ReactComponent as ExcelIcon } from "Container/icons/icon-excel.svg"
import getLeftover from "./helper"

const columns = [
  {
    title: "название товара",
    key: "name",
    dataIndex: "name",
    className: "TableWrapper-black",
  },
  {
    title: "Артикул",
    key: "article",
    dataIndex: "article",
  },
  {
    title: "Остаток (шт.)",
    key: "leftover",
    dataIndex: "leftover",
    className: "TableWrapper-black",
  },
]

const LeftoversInfo = ({ ultimateLogout }) => {
  const { id } = useParams()
  const history = useHistory()
  const [loadPending, loadSetPending] = useState(false)

  const [leftOverData, setLeftover] = useState([])

  useEffect(() => {
    getLeftover({ id, setLeftover, ultimateLogout, history })
  }, [])

  return (
    <div>
      <div className="AdminPanel-contentBack">
        <div
          className="BackWrapper"
          onClick={() => history.push(`/admin/leftover`)}
        >
          <div className="BackWrapper-icon">
            <ArrowIcon />
          </div>
          Назад
        </div>
      </div>
      <div className="AdminPanel-contentTitle">
        <h1>Клуб № {leftOverData.hallNumber}</h1>
        <div className="AdminPanel-contentInfo">
          Остаток на{" "}
          {leftOverData.date
            ? moment(leftOverData.date).format(`DD.MM.YYYY`)
            : `-`}
        </div>
      </div>
      <div className="AdminPanel-contentTable AdminPanel-contentTable_with-sideBar">
        <div className="AdminPanel-contentWrapper">
          <TableComponent
            source="admin/leftover/products"
            columns={columns}
            extraBody={{ id }}
            ultimateLogout={ultimateLogout}
          />
        </div>
        <div className="AdminPanel-contentSidebar">
          <div className="BlockInfoWrapper">
            <div className="BlockInfoWrapper-head">
              <h2>Информация</h2>
            </div>
            <div className="BlockInfoWrapper-body">
              <div className="BlockInfoWrapper-row">
                <div className="BlockInfoWrapper-label">Имя Фамилия</div>
                <div>{leftOverData.fullName}</div>
              </div>
              <div className="BlockInfoWrapper-row">
                <div className="BlockInfoWrapper-label">Телефон</div>
                <div>{leftOverData.phone}</div>
              </div>
              <div className="BlockInfoWrapper-row">
                <div className="BlockInfoWrapper-label">№ клуба</div>
                <div>{leftOverData.hallNumber}</div>
              </div>
              <div className="BlockInfoWrapper-row">
                <div className="BlockInfoWrapper-label">Адрес клуба</div>
                <div>{leftOverData.address}</div>
              </div>
            </div>
          </div>
          <div className="ButtonDefault-unloadToExcel">
            <ButtonDefault
              size="small"
              disabled={loadPending}
              onClick={() =>
                downloadFile({
                  ultimateLogout,
                  source: `admin/leftover`,
                  requestBody: { id },
                  fileName: `Остаток №${id} по клубу №${
                    leftOverData.hallNumber
                  }. ${
                    leftOverData.date
                      ? moment(leftOverData.date).format("DD.MM.YYYY")
                      : ""
                  }.xls`,
                  loadSetPending,
                  history,
                })
              }
            >
              <div className="ButtonDefault-icon">
                <ExcelIcon />
              </div>
              Выгрузить в Excel
            </ButtonDefault>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftoversInfo
