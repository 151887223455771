import {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react"
import Request from "api/request"

export const downloadFile = ({
  ultimateLogout,
  extraSource,
  source,
  requestBody,
  fileName,
  loadSetPending,
  search,
  sort,
  history,
  hall,
}) => {
  loadSetPending(true)
  Request({
    url: extraSource || `${source}/excel`,
    ultimateLogout,
    history,
    requestBody: { ...requestBody, ...search, hall, sorter: sort || undefined },
    method: `post`,
  })
    .then(({ data: { link } }) => {
      const loadLink = document.createElement(`a`)
      loadLink.setAttribute(`href`, link)
      loadLink.setAttribute(`download`, `${fileName || `Отчет`}`)
      loadLink.style.display = `none`
      document.body.appendChild(loadLink)
      loadLink.click()
      document.body.removeChild(loadLink)
      loadSetPending(false)
    })
    .catch(() => loadSetPending(false))
}

export const useNow = () => {
  const [timestamp, setTimestamp] = useState(Date.now())

  const refresh = useCallback(() => setTimestamp(Date.now()), [])

  return { timestamp, refresh }
}

export const useStatuses = ({ ultimateLogout, history }) => {
  const [statusData, setStatusData] = useState({
    data: [],
    pending: false,
  })

  useEffect(() => {
    setStatusData({ ...statusData, pending: true })
    Request({ ultimateLogout, history, url: `admin/order/statuses` })
      .then(({ data }) => setStatusData({ data, pending: false }))
      .catch(() => setStatusData({ ...statusData, pending: false }))
  }, [])

  return statusData
}

export const useCategory = ({ ultimateLogout, history }) => {
  const [categoryData, setCategoryData] = useState({
    data: [],
    pending: false,
  })

  useEffect(() => {
    setCategoryData({ ...categoryData, pending: true })
    Request({ ultimateLogout, history, url: `admin/product/categories` })
      .then(({ data }) => setCategoryData({ data, pending: false }))
      .catch(() => setCategoryData({ ...categoryData, pending: false }))
  }, [])

  return categoryData
}

export const useScroll = () => {
  // scroll crutch fot small devices
  const isLargeWidthDevice = document.body.clientWidth > 991
  const isSmallHeightDevice = document.body.clientHeight < 845
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }
  useEffect(() => {
    if (isLargeWidthDevice && isSmallHeightDevice) {
      window.addEventListener("scroll", handleScroll, { passive: true })
    }

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const changeStyle =
    isSmallHeightDevice &&
    document.body.scrollHeight !== document.body.clientHeight &&
    document.body.scrollHeight - document.body.clientHeight - scrollPosition <=
      80
  return { isLargeWidthDevice, changeStyle, isSmallHeightDevice }
}

export const useWindowHeightSize = () => {
  const [size, setSize] = useState({ height: 0, scroll: 0 })
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({ ...size, height: window.innerHeight })
    }
    const updateScroll = () => {
      setSize({
        ...size,
        scroll: document.documentElement.scrollTop,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", updateSize)
    window.addEventListener("scroll", updateScroll)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return size
}

export const useHover = () => {
  const [value, setValue] = useState(false)
  const ref = useRef(null)
  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)
  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener("mouseover", handleMouseOver)
        node.addEventListener("mouseout", handleMouseOut)
        return () => {
          node.removeEventListener("mouseover", handleMouseOver)
          node.removeEventListener("mouseout", handleMouseOut)
        }
      }
    },
    [ref.current], // Recall only if ref changes
  )
  return [ref, value]
}

export default useNow
