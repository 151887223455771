import React, { useState, useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"

import { Col, Row } from "antd"
import moment from "moment"
import "moment/locale/ru"

import { downloadFile } from "api/hooks"
import Request from "api/request"

import "types/main"
import { useCart } from "context/index"

import { ReactComponent as StrokeIcon } from "Container/icons/stroke.svg"
import { ReactComponent as ExcelIcon } from "Container/icons/icon-excel.svg"

import Button, { ButtonDefault } from "Components/Button"
import PaginationComponent from "Components/Pagination"
import StatusComponent from "Components/Status"

import InsertGoods from "./InsertGoods"
import RatingComponent from "./RatingComponent"
import OrdersFilter from "./Filters"
import {
  getDefaultFilter,
  defaultPagination,
  getFileName,
  prepareDataToBack,
} from "./Filters/constants"

import getOrdersConstants, { statuses } from "./constants"

/**
 * @callback setShowIndexCallback
 * @param {number} // order id for toggle
 */

/**
 * @typedef InsertOrdersProps
 * @property {OrderItem} order
 * @property {Array<StatusItem>} statusData
 * @property {boolean} showViewed
 * @property {number | null} showIndex // open order id
 * @property {setShowIndexCallback} setShowIndex // toggle open order id
 */

/**
 * @typedef OrdersState
 * @property {boolean} pending
 * @property {number} count
 * @property {Array<OrderItem>} data
 */

/**
 * @param { InsertOrdersProps } props
 * @return {JSX.Element}
 * @constructor
 */

const InsertOrders = ({
  order,
  showViewed,
  showIndex,
  setShowIndex,
  statusData,
}) => {
  const history = useHistory()

  const haveShowMore = order.goods && order.goods.length > 5
  const [showGoods, setShowGoods] = useState(order.goods.slice(0, 5))
  moment.locale("ru")

  const { setRepeatOrders } = useCart()

  useEffect(() => {
    if (order.id === showIndex) {
      setShowGoods(order.goods)
    } else {
      setShowGoods(order.goods.slice(0, 5))
    }
  }, [showIndex])

  const toggleShow = () =>
    setShowIndex(order.id === showIndex ? null : order.id)

  const constants = useMemo(() => getOrdersConstants({ order }), [order])

  const isDelivered = order.status_id === statuses.delivered

  return (
    <div key={order.length} className="OrdersTable">
      <div className="OrdersTable-head">
        <div className="OrdersTable-headGoods">
          <div className="OrdersTable-item OrdersTable-itemId">
            <div className="OrdersTable-itemNum">{constants.title}</div>
            <div>{constants.date}</div>
          </div>
          <div className="OrdersTable-item OrdersTable-itemIdCategory s-hide">
            {constants.category}
          </div>
          <div className="OrdersTable-item OrdersTable-itemPrice s-hide">
            {constants.price}
          </div>
          <div className="OrdersTable-item OrdersTable-itemCount s-hide">
            {constants.count}
          </div>
        </div>
        <div className="OrdersTable-item OrdersTable-itemComment s-hide" />
        <div className="OrdersTable-item OrdersTable-itemSum">
          <div className="OrdersTable-itemNum">{constants.sum_value}</div>
          {constants.sum}
        </div>
      </div>
      <div className="OrdersTable-body">
        <div className="OrdersGoods">
          <InsertGoods goods={showGoods} />
        </div>
        <div className="OrdersTable-comment">
          {order.hallNumber && (
            <div>
              <div className="OrdersTable-comment-label">{constants.hall}</div>
              <div className="OrdersTable-comment-info">{order.hallNumber}</div>
            </div>
          )}
          {order.fullName && (
            <div>
              <div className="OrdersTable-comment-label">{constants.name}</div>
              <div className="OrdersTable-comment-info">{order.fullName}</div>
            </div>
          )}
          {order.phone && (
            <div>
              <div className="OrdersTable-comment-label">{constants.phone}</div>
              <div className="OrdersTable-comment-info">{order.phone}</div>
            </div>
          )}
          {order.comment && (
            <div>
              <div className="OrdersTable-comment-label">
                {constants.comment}
              </div>
              <div className="OrdersTable-comment-info">{order.comment}</div>
            </div>
          )}
        </div>
        <div className="OrdersTable-info">
          <div className="OrdersTable-badge">
            <StatusComponent
              id={order.status_id}
              withComment={order.statusComment}
              viewedComment={showViewed ? order.viewed : false}
              statusData={statusData}
              orderId={order.id}
            />
          </div>
          {isDelivered && (
            <div>
              <RatingComponent order={order} />
            </div>
          )}
          <Button
            data-id={order.id}
            size="small"
            onClick={() => {
              history.push(constants.repeat_url)
              setRepeatOrders(order.goods)
              window.ym(88266243, "reachGoal", "reorder")
            }}
          >
            {constants.repeat_button}
          </Button>
        </div>
      </div>
      {haveShowMore && (
        <div className="ShowToggle" onClick={toggleShow}>
          {showIndex === order.id ? (
            <div className="DisplayRow">
              <div>Свернуть</div>
              <div className="StrokeIcon BackStroke">
                <StrokeIcon />
              </div>
            </div>
          ) : (
            <div className="DisplayRow">
              <div>{constants.all_goods}</div>
              <div className="StrokeIcon">
                <StrokeIcon />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const Orders = ({ ultimateLogout }) => {
  const history = useHistory()
  const showViewed =
    localStorage.getItem(`role`) !== `ROLE_ADMIN` ||
    localStorage.getItem(`role`) !== `ROLE_MANAGER`
  const [orders, setOrders] = useState(
    /** @type {OrdersState} */ {
      pending: false,
      data: [],
      total: 0,
    },
  )
  const { data: ordersData, pending, total } = orders
  const [showIndex, setShowIndex] = useState(null)

  const defaultFilter = useMemo(() => getDefaultFilter(), [])

  const [pagination, setPagination] = useState(defaultPagination)

  const [filter, setFilter] = useState(defaultFilter)

  const requestFilter = prepareDataToBack(filter)

  useEffect(() => {
    setOrders({ ...orders, pending: true })
    Request({
      ultimateLogout,
      method: `post`,
      url: `order/list`,
      history,
      requestBody: {
        filter: requestFilter,
        pagination,
      },
    })
      .then(({ data, headers }) => {
        setOrders({
          ...orders,
          data,
          pending: false,
          total: headers[`total-count`],
        })
      })
      .catch(() => {
        setOrders({ ...orders, pending: false })
      })
  }, [pagination, filter])

  const [statusData, setStatusData] = useState([])

  const [loadPending, loadSetPending] = useState(false)
  useEffect(() => {
    Request({ url: `order/statuses`, history }).then(({ data }) =>
      setStatusData(data || []),
    )
  }, [])

  return (
    <Row gutter={24}>
      <Col span={24}>
        <div className="Orders">
          <div className="Orders-left">
            <h1>История заказов</h1>
          </div>
          <div className="Orders-header">
            <div className="Orders-left">
              <div>
                <OrdersFilter
                  pending={pending}
                  filter={filter}
                  setFilter={setFilter}
                  resetPagination={() => setPagination(defaultPagination)}
                  pagination={pagination}
                  statusData={statusData}
                />
              </div>
            </div>
            <div className="Orders-right ButtonDefault-unloadToExcel">
              <ButtonDefault
                disabled={loadPending}
                size="small"
                onClick={() =>
                  downloadFile({
                    ultimateLogout,
                    history,
                    source: `order`,
                    requestBody: {
                      filter: requestFilter,
                    },
                    fileName: getFileName(filter.halls),
                    loadSetPending,
                  })
                }
              >
                <div className="ButtonDefault-icon">
                  <ExcelIcon />
                </div>
                Выгрузить в Excel
              </ButtonDefault>
            </div>
          </div>
          <div className="Orders-insertOrders">
            {ordersData.map(item => (
              <InsertOrders
                order={item}
                showViewed={showViewed}
                key={`${item.length}_${item.id}`}
                showIndex={showIndex}
                setShowIndex={setShowIndex}
                statusData={statusData}
                history={history}
              />
            ))}
          </div>
        </div>
      </Col>
      <div className="PaginationWrapper CentreXPosition">
        <PaginationComponent
          perPage={pagination.limit}
          page={pagination.offset / pagination.limit + 1}
          pending={pending}
          changePage={value =>
            setPagination({
              ...pagination,
              offset: (value - 1) * pagination.limit,
            })
          }
          total={total}
        />
      </div>
    </Row>
  )
}

export default Orders
