import React, { useEffect, useState } from "react"
import { InputComponent } from "Components/Input"
import { useHistory } from "react-router-dom"
import { ButtonDefault } from "Components/Button"
import SuccessModal from "Components/SuccessModal"
import Request from "api/request"
import { ReactComponent as InfoIcon } from "Container/icons/info.svg"
import makeCurrency, { currencyMultiplication } from "api/constants"
import { useScroll } from "api/hooks"
import { notification } from "antd"

export const sendOrder = ({
  cartItems,
  ultimateLogout,
  changeOrderPending,
  toggleModal,
  customerData,
  comment,
  history,
  removeRepeatOrders,
  isRepeat,
  priceId,
  getCartPricesHandler,
}) => {
  const cartItemsReq = []
  const metricItems = []
  changeOrderPending(true)

  Object.keys(cartItems).forEach(item => {
    if (cartItems[item].count > 0) {
      metricItems.push(cartItems[item])
      cartItemsReq.push({
        count: cartItems[item].count,
        productID: item,
        price:
          Number(cartItems[item].price) / Number(cartItems[item].multiplicity),
      })
    }
  })

  const requestData = {
    hall: localStorage.getItem(`hall`),
    address: localStorage.getItem(`address`),
    fullName: customerData.fullName,
    // TODO: try catch magic error
    phone:
      customerData &&
      customerData.phone &&
      typeof customerData.phone === `string`
        ? customerData.phone
            .replaceAll(`-`, ``)
            .replace(`(`, ``)
            .replace(`)`, ``)
            .replace(`+`, ``)
        : ``,
    comment,
    items: cartItemsReq,
  }

  Request({
    ultimateLogout,
    method: `post`,
    url: `order/create`,
    requestBody: requestData,
    history,
  })
    .then(({ data }) => {
      changeOrderPending(false)
      if (data[0].status === "error") {
        notification.error({ message: data[0].message })
      } else {
        const metricProducts = metricItems?.map(item => ({
          id: item.id,
          price: Number(item.price) / Number(item.multiplicity),
          name: item.name,
          quantity: Number(item.count),
          brand: priceId,
        }))
        const ecommerce = {
          ecommerce: {
            currencyCode: "RUB",
            purchase: {
              actionField: {
                id: data[0]?.id,
              },
              products: metricProducts,
            },
          },
        }
        if (isRepeat) {
          window.ym(88266243, "reachGoal", "reorderCreate")
          ecommerce.reachGoal = "reorderCreate"
        }
        window.keyteringOrders?.push(ecommerce)
        toggleModal(true)
        removeRepeatOrders()
      }
    })
    .then(() => {
      if (getCartPricesHandler) {
        getCartPricesHandler()
      }
    })
    .catch(() => changeOrderPending(false))
}

const OrderData = ({
  comment,
  setComment,
  cartItems,
  customerData,
  ultimateLogout,
  minSum,
  setScrollHeight,
  setIsEmptyCart,
  isCart,
}) => {
  const MIN_TOTAL = minSum

  const history = useHistory()

  const [showSuccessModal, toggleModal] = useState(false)
  const [orderPending, changeOrderPending] = useState(false)

  const changeComment = e => {
    setComment(e.target.value)
  }

  const [orderInfo, setOrderInfo] = useState({
    count: 0,
    countAll: 0,
    total: 0,
  })

  useEffect(() => {
    let countAll = 0
    let total = 0
    let count = 0

    Object.keys(cartItems).forEach(item => {
      if (cartItems[item].count) {
        countAll += +cartItems[item].count
        total += currencyMultiplication(
          cartItems[item].price,
          cartItems[item].count,
        )
        count += 1
      }
    })

    setIsEmptyCart(!!countAll)

    if (total !== 0) setScrollHeight(738)
    else setScrollHeight(545)

    setOrderInfo({
      ...orderInfo,
      count,
      countAll,
      total,
    })
  }, [cartItems])

  const disabledSendOrder =
    orderPending ||
    !customerData.phone ||
    !customerData.fullName ||
    (customerData.phone && customerData.phone.slice(-1) === `_`) ||
    orderInfo.total < MIN_TOTAL

  const { isLargeWidthDevice } = useScroll()

  return (
    <div>
      {orderInfo.total === 0 ? (
        <div className="OrderData RelativePosition">
          <div className="InfoIconWrapper">
            <div style={{ paddingLeft: `18px`, paddingTop: `8px` }}>
              <InfoIcon />
            </div>
          </div>
          <div className="OrderData-row">
            <span className="OrderData-info-label">
              Выберите товары и их количество, чтобы перейти к оформлению заказа
            </span>
          </div>
          <ButtonDefault
            disabled
            block
            customClass={isLargeWidthDevice ? `SmallComponentStyle` : null}
            type="button"
          >
            Оформить заказ
          </ButtonDefault>
        </div>
      ) : (
        <div className="OrderData">
          <div className="OrderData-row">
            <h2 className="OrderData-title-h2">Ваш заказ</h2>
          </div>
          <div className="OrderData-row">
            <span className="OrderData-label">Всего товаров</span>
            <span className="OrderData-value"> {orderInfo.count}</span>
          </div>
          <div className="OrderData-row">
            <span className="OrderData-label">Общий тираж</span>
            <span className="OrderData-value"> {orderInfo.countAll}</span>
          </div>
          <div className="OrderData-rowValue">
            <h2 className="OrderData-title-h2">
              <span>Итого</span>
              <span>{makeCurrency(orderInfo.total)} ₽</span>
            </h2>
          </div>
          <div className="OrderData-rowValue">
            <InputComponent
              value={comment}
              onChange={changeComment}
              className="OrderData-input"
              placeholder="Комментарий к заказу"
              type="textarea"
              customClass={isLargeWidthDevice ? `SmallComponentStyle` : null}
            />
            {orderInfo.total < MIN_TOTAL ? (
              <p className="ErrorOrder-data">
                До минимального заказа{` `}
                {(MIN_TOTAL - orderInfo.total).toFixed(2)} ₽
              </p>
            ) : (
              ``
            )}
          </div>
          <ButtonDefault
            disabled={disabledSendOrder}
            loading={orderPending}
            onClick={() => {
              history.push("/cart")
              sendOrder({
                cartItems,
                ultimateLogout,
                changeOrderPending,
                toggleModal,
                customerData,
                history,
                comment,
              })
            }}
            customClass={isLargeWidthDevice ? `SmallComponentStyle` : null}
            block
            type="button"
          >
            Оформить заказ
          </ButtonDefault>
          {showSuccessModal && <SuccessModal close={() => history.go(0)} />}
        </div>
      )}
    </div>
  )
}

export default OrderData
