import React from "react"
import { Radio } from "antd"

const RadioComponent = ({ disabled, children, ...rest }) => (
  <div className="RadioWrapper">
    <Radio disabled={disabled} {...rest}>
      {children}
    </Radio>
  </div>
)

export default RadioComponent
