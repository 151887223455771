import React from "react"
import { Select, TreeSelect } from "antd"
import { ReactComponent as ArrowIcon } from "Container/icons/arrow-down.svg"

const SelectComponent = ({ children, error = false, ...rest }) => (
  <div className={`SelectWrapper ${error ? `ErrorSelectWrapper` : ``}`}>
    <Select
      defaultOpen
      className="SelectComponent"
      {...rest}
      suffixIcon={<ArrowIcon />}
    >
      {children}
    </Select>
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const TreeSelectComponent = ({ error = false, ...rest }) => (
  <div className={error ? `ErrorSelectWrapper` : ``}>
    <TreeSelect
      {...rest}
      treeCheckable
      style={{
        width: "100%",
      }}
    />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export default SelectComponent
