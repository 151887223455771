import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { RangePickerComponent } from "Components/DatePicker"
import { Col, Row } from "antd"
import PaginationComponent from "Components/Pagination"
import InsertLeftover from "./InsertLeftover"
import getLeftoverHistory from "./helper"

const HistoryLeftover = ({ ultimateLogout, currentHall }) => {
  const history = useHistory()
  const [historyData, setHistoryData] = useState({
    data: [],
    pending: false,
    total: 0,
    page: 1,
    perPage: 5,
  })
  // const { data = [], pending, total, page, perPage } = historyData
  const [filterDate, setFilterDate] = useState({
    dateStart: null,
    dateEnd: null,
  })
  const [showIndex, setShowIndex] = useState(null)
  useEffect(
    () =>
      getLeftoverHistory({
        ultimateLogout,
        history,
        historyData,
        setData: setHistoryData,
        hall: currentHall,
        filter: filterDate,
        params: {
          limit: historyData.perPage,
          offset: (historyData.page - 1) * historyData.perPage,
        },
      }),
    [filterDate, historyData.page, historyData.perPage, currentHall],
  )

  return (
    <Row gutter={24}>
      <Col span={24}>
        <div className="Orders">
          <div className="Orders-header">
            <h1>История остатков</h1>
          </div>
          <div style={{ width: `250px` }}>
            <RangePickerComponent
              size="small"
              value={[filterDate.dateStart, filterDate.dateEnd]}
              placeholder={[`Выберите`, `даты`]}
              onChange={value => {
                setFilterDate({
                  ...filterDate,
                  dateStart: value
                    ? value[0].startOf(`day`).valueOf()
                    : undefined,
                  dateEnd: value ? value[1].endOf(`day`).valueOf() : undefined,
                })
              }}
            />
          </div>
          <div className="Orders-insertOrders">
            {historyData.data.map(item => (
              <InsertLeftover
                leftover={item}
                key={`${item.length}_${item.id}`}
                showIndex={showIndex}
                setShowIndex={setShowIndex}
                history={history}
              />
            ))}
          </div>
        </div>
      </Col>
      <div className="PaginationWrapper CentreXPosition">
        <PaginationComponent
          perPage={historyData.perPage}
          page={historyData.page}
          pending={historyData.pending}
          changePage={value => setHistoryData({ ...historyData, page: value })}
          total={historyData.total}
        />
      </div>
    </Row>
  )
}

export default HistoryLeftover
