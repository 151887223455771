import React from "react"
import SelectComponent from "Components/Select"
import { Select } from "antd"

const { Option } = Select

const HallSelect = ({ firstPack, setFirstPack }) => (
  <div>
    <SelectComponent
      style={{ width: `124px` }}
      placeholder="Все категории"
      value={firstPack.data.hall}
      onChange={value => {
        const currentAddress =
          firstPack.data.halls.find(item => item.hall === value) || []
        setFirstPack({
          ...firstPack,
          data: {
            ...firstPack.data,
            hall: value,
            address: currentAddress.address,
          },
        })
        localStorage.setItem(`hall`, value)
        localStorage.setItem(`address`, currentAddress)
      }}
      loading={firstPack.pending}
      disabled={firstPack.pending || !firstPack.data.halls.length}
      size="small"
    >
      {firstPack.data.halls.map(item => (
        <Option value={item.hall || null} key={`${item.adress}_${item.hall}`}>
          {item.hall}
        </Option>
      ))}
    </SelectComponent>
  </div>
)

export default HallSelect
