import Request from "api/request"

const loadPrices = ({
  history,
  ultimateLogout,
  searchValue: { name, hallNumber },
  priceList,
  setPriceList,
}) => {
  setPriceList({ ...priceList, pending: true })
  Request({
    url: `admin/access/prices`,
    history,
    ultimateLogout,
    method: `post`,
    requestBody: { name, hallNumber },
  })
    .then(({ data }) => setPriceList({ data, pending: false }))
    .catch(() => setPriceList({ ...priceList, pending: false }))
}

export default loadPrices
