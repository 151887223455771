import React from "react"
import { Pagination } from "antd"

const PaginationComponent = ({ page, changePage, total, perPage, pending }) => (
  <div className="Pagination">
    <Pagination
      style={{ color: `black !important` }}
      defaultCurrent={page}
      current={page}
      total={total}
      pageSize={perPage}
      hideOnSinglePage
      showSizeChanger={false}
      onChange={value => changePage(value)}
      disabled={pending}
    />
  </div>
)

export default PaginationComponent
