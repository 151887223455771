import Request from "api/request"

const getMessage = ({ history, ultimateLogout, id, setData, hallNumber }) => {
  Request({
    history,
    method: `POST`,
    ultimateLogout,
    url: `notification/${id}`,
    requestBody: { hallNumber },
  }).then(({ data }) => setData(data))
}

export const downloadMessageFiles = ({
  history,
  ultimateLogout,
  id,
  hallNumber,
  loadSetPending,
}) => {
  loadSetPending(true)
  Request({
    history,
    method: `POST`,
    ultimateLogout,
    url: `notification/${id}/all`,
    requestBody: { hallNumber },
    responseType: "blob",
    // extraHeaders: {
    //   responseType: "blob",
    // },
  })
    .then(response => {
      loadSetPending(false)

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))

      const link = document.createElement("a")

      link.href = downloadUrl

      link.setAttribute("download", "file.zip") // any other extension

      document.body.appendChild(link)

      link.click()

      link.remove()
    })
    .catch(() => loadSetPending(false))
}

export default getMessage
