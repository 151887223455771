import React from "react"
import { ReactComponent as CloseIcon } from "Container/icons/close.svg"
import GlobalEscapeHandle from "Components/GlobalEscapeHandle"

import { Spin } from "antd"

import Icon, { StepBackwardOutlined, LoadingOutlined } from "@ant-design/icons"
import { ReactComponent as SpinIcon } from "./spin.svg"

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Modal = ({
  close,
  title,
  status,
  statusMessage,
  width,
  children,
  withoutClose,
  bodyClassName,
  onlyChildren,
}) => {
  const modalHeader = status ? statusMessage : title
  return onlyChildren ? (
    <div className="ModalWrapper">
      <div className="ModalWrapperSpinWrapper">
        <Spin indicator={loadingIcon} size="large" />
      </div>
    </div>
  ) : (
    <div className="ModalWrapper">
      <div className={`ModalBody ${bodyClassName || ""}`} style={{ width }}>
        {!withoutClose ? (
          <div className="CloseWrapper">
            <div />
            <div className="CloseIcon" onClick={close}>
              <CloseIcon />
            </div>
          </div>
        ) : (
          false
        )}
        <div className="ModalContentWrapper">
          {(title || statusMessage) && (
            <div className="ModalHeader">{modalHeader}</div>
          )}
          {children}
        </div>
      </div>
      <GlobalEscapeHandle onEscapePress={close} />
    </div>
  )
}

export default Modal
