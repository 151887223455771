import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { ReactComponent as ErrorImage } from "Container/icons/404.svg"
import { ReactComponent as ServerErrorImage } from "Container/icons/500.svg"

import { ReactComponent as ErrorBoundaryImage } from "Container/icons/errorBoundary.svg"
import { PRICE_LIST_ROUTE } from "Container/BaseLayout/constants"
import { ButtonPrimary } from "Components/Button"

export const ServerErrorPage = ({ pure504 }) => {
  const history = useHistory()
  const showPage = localStorage.getItem(`500`) === `show`
  let show504 = localStorage.getItem(`504`) === `show`
  if (pure504) {
    show504 = false
  }
  useEffect(() => {
    if (!showPage && !show504 && !pure504) {
      history.push(PRICE_LIST_ROUTE)
    }
  })
  return (
    <>
      {(showPage || show504 || pure504) && (
        <div className="ErrorPageWrapper">
          <div className="ErrorContentWrapper">
            <div className="ErrorPageTitle">
              {show504 ? `Что-то пошло не так...` : `Технические работы`}
            </div>
            <div className="ErrorPageInfo">
              {show504
                ? `Попробуйте оформить заказ позже`
                : `Скоро всё заработает, обязательно возвращайтесь!`}
            </div>
            <div className="ErrorPageImageWrapper">
              <ServerErrorImage />
            </div>
            {!pure504 && (
              <div>
                <ButtonPrimary onClick={() => history.push(PRICE_LIST_ROUTE)}>
                  Вернуться на главную
                </ButtonPrimary>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const NotExistPage = () => {
  const history = useHistory()
  const showPage = localStorage.getItem(`404`) === `show`
  useEffect(() => {
    if (!showPage) {
      history.push(PRICE_LIST_ROUTE)
    }
  })
  return (
    <div className="ErrorPageWrapper">
      <div className="ErrorContentWrapper">
        <div className="ErrorPageTitle">Страница не найдена</div>
        <div className="ErrorPageInfo">
          Не переживайте — попробуйте начать сначала.
        </div>
        <div className="ErrorPageImageWrapper">
          <ErrorImage />
        </div>
        <div>
          <ButtonPrimary onClick={() => history.push(PRICE_LIST_ROUTE)}>
            Вернуться на главную
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

export default NotExistPage

export const ErrorBoundaryPage = () => {
  const history = useHistory()
  return (
    <div className="ErrorPageWrapper">
      <div className="ErrorContentWrapper">
        <div className="ErrorPageTitle">Что-то пошло не так...</div>
        <div className="ErrorPageInfo">
          К сожалению, произошла неожиданная ошибка.
        </div>
        <div className="ErrorPageImageWrapper">
          <ErrorBoundaryImage />
        </div>
        <div>
          <ButtonPrimary onClick={() => history.push(PRICE_LIST_ROUTE)}>
            Вернуться на главную
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}
