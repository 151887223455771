import React, { useEffect, useState } from "react"
import { InputArea, InputComponent, InputWithMask } from "Components/Input"
import { ButtonDefault } from "Components/Button"
import { useScroll } from "api/hooks"
import { ReactComponent as InfoRedIcon } from "Container/icons/info_red.svg"
import { useCart } from "context"
import makeCurrency, { currencyMultiplication } from "api/constants"
import SuccessModal from "Components/SuccessModal"
import { updateCart } from "../PriceList/helper"

const CustomerData = ({
  customerData,
  setCustomerData,
  sendLeftover,
  emptyLeftover,
  fixedPosition,
  currentAddress,
  currentHall,
}) => {
  const disabledSend =
    emptyLeftover ||
    !customerData.phone ||
    !customerData.fullName ||
    (customerData.phone && customerData.phone.slice(-1) === `_`)

  const { isLargeWidthDevice, changeStyle } = useScroll()
  const inputShowPlaceholderWrapperClass = customerData.fullName
    ? `OrderDataInputOn`
    : ``
  const inputShowDescriptionClass = customerData.fullName
    ? `InputNamePlaceholderInfoOn`
    : `InputPlaceholderInfoOff`

  return (
    <div
      className={`CustomerData ${fixedPosition ? `FixedCustomerData` : ``}`}
      style={changeStyle ? { top: `80px` } : null}
    >
      <div className="CustomerData-row">
        <span className="CustomerData-label">Номер клуба</span>
        <span className="CustomerData-value">{currentHall}</span>
      </div>
      <div className="CustomerData-row">
        <span className="CustomerData-label">Адрес клуба</span>
        <span className="CustomerData-value">{currentAddress}</span>
      </div>
      <div className="CustomerData-row">
        <h3>Заполните ваши данные</h3>
      </div>
      <div className={`OrderData-rowValue ${inputShowPlaceholderWrapperClass}`}>
        <InputComponent
          className="CustomerData-input"
          placeholder="Фамилия Имя Отчество"
          onChange={({ target: { value } }) =>
            setCustomerData({ ...customerData, fullName: value })
          }
          value={customerData.fullName}
          type="text"
          customClass={isLargeWidthDevice ? `SmallComponentStyle` : null}
        />
        <div className={`InputPlaceholderInfo ${inputShowDescriptionClass}`}>
          Фамилия Имя Отчество
        </div>
      </div>
      <div className="OrderData-rowValue">
        <InputWithMask
          name="number"
          mask="+7(999)999-99-99"
          placeholder="Телефон"
          value={customerData.phone}
          customClass={isLargeWidthDevice ? `SmallComponentStyle` : null}
          onChange={({ target: { value } }) =>
            setCustomerData({ ...customerData, phone: value })
          }
        />
        <div
          className={`InputPlaceholderInfo ${
            customerData.phone
              ? `InputPhonePlaceholderInfoOn`
              : `InputPlaceholderInfoOff`
          }`}
        >
          Телефон
        </div>
      </div>
      {sendLeftover !== undefined ? (
        <ButtonDefault
          onClick={sendLeftover}
          block
          disabled={disabledSend}
          type="button"
        >
          Отправить
        </ButtonDefault>
      ) : (
        ``
      )}
    </div>
  )
}

export const CustomerDataNew = ({
  customerData,
  currentPrice,
  setCustomerData,
  emptyLeftover,
  fixedPosition,
  currentAddress,
  currentHall,
  setComment,
  comment,
  cartItems,
  minSum,
  setIsEmptyCart,
  setScrollHeight,
  ultimateLogout,
  setCartItems,
  onCreateOrder,
  showSuccessModal,
  orderPending,
  setShowSuccessModal,
}) => {
  const { state } = useCart()

  const MIN_TOTAL = minSum

  const [orderInfo, setOrderInfo] = useState({
    count: 0,
    countAll: 0,
    total: 0,
  })

  useEffect(() => {
    let countAll = 0
    let total = 0
    let count = 0

    Object.keys(cartItems).forEach(item => {
      if (cartItems[item].count) {
        countAll += +cartItems[item].count
        total += currencyMultiplication(
          cartItems[item].price,
          cartItems[item].count,
        )
        count += 1
      }
    })

    setIsEmptyCart(!!countAll)
    setScrollHeight(total !== 0 ? 738 : 545)

    setOrderInfo({
      ...orderInfo,
      count,
      countAll,
      total,
    })
  }, [cartItems])

  const disabledSend =
    emptyLeftover ||
    !customerData.phone ||
    !customerData.fullName ||
    (customerData.phone && customerData.phone.slice(-1) === `_`) ||
    state.totalPrice <= 0 ||
    orderInfo.total < MIN_TOTAL ||
    state.toMin > 0

  const changeComment = e => {
    setComment(e.target.value)
  }

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false)
    setCustomerData({ fullName: "", phone: "" })
    setComment("")
    setCartItems({})
    updateCart({
      guid: [Object.keys(cartItems)],
      hall: currentHall,
      quantity: 0,
    })
  }

  const { changeStyle } = useScroll()
  const { isLargeWidthDevice } = useScroll()

  return (
    <div
      className={`CustomerData CustomerDataCart ${
        fixedPosition ? `FixedCustomerData` : ``
      }`}
      style={changeStyle ? { top: `80px` } : null}
    >
      <div className="CustomerDataCartTitle">Оформление заказа</div>
      <div className="CustomerData-row">
        <span className="CustomerData-label">Номер клуба</span>
        <span className="dots" />
        <span className="CustomerData-value">{currentHall}</span>
      </div>
      <div className="CustomerData-row">
        <span className="CustomerData-label">Адрес клуба</span>
        <span className="dots" />
        <span className="CustomerData-value">{currentAddress}</span>
      </div>
      <div className="CustomerData-row">
        <div className="CustomerData-row_info">Ваши данные</div>
      </div>
      <div className="OrderData-rowValue">
        <InputComponent
          className="CustomerData-input"
          placeholder="Фамилия Имя Отчество"
          onChange={({ target: { value } }) =>
            setCustomerData({ ...customerData, fullName: value })
          }
          value={customerData.fullName}
          type="text"
          customClass={isLargeWidthDevice ? `SmallComponentStyle` : null}
        />
        <div
          className={`InputPlaceholderInfo ${
            customerData.fullName
              ? `InputNamePlaceholderInfoOn`
              : `InputPlaceholderInfoOff`
          }`}
        >
          Фамилия Имя Отчество
        </div>
      </div>
      <div className="OrderData-rowValue">
        <InputWithMask
          name="number"
          mask="+7(999)999-99-99"
          placeholder="Номер телефона"
          value={customerData.phone}
          customClass={isLargeWidthDevice ? `SmallComponentStyle` : null}
          onChange={({ target: { value } }) =>
            setCustomerData({ ...customerData, phone: value })
          }
        />
        <div
          className={`InputPlaceholderInfo ${
            customerData.phone
              ? `InputPhonePlaceholderInfoOn`
              : `InputPlaceholderInfoOff`
          }`}
        >
          Телефон
        </div>
      </div>
      <div className="OrderData-rowValue">
        <InputArea
          className="CustomerData-input CustomerData-input_side"
          placeholder="Комментарий к заказу"
          onChange={changeComment}
          value={comment}
          type="text"
          customClass={isLargeWidthDevice ? `SmallComponentStyle` : null}
        />
        <div
          className={`InputPlaceholderInfo ${
            comment ? `InputNamePlaceholderInfoOn` : `InputPlaceholderInfoOff`
          }`}
        >
          Комментарий к заказу
        </div>
      </div>
      <div className="CustomerData-row">
        <span className="CustomerData-label">Всего товаров</span>
        <span className="dots" />
        <span className="CustomerData-value">{`${state.count || 0} шт.`}</span>
      </div>
      <div className="CustomerData-row">
        <span className="CustomerData-label">Общий тираж</span>
        <span className="dots" />
        <span className="CustomerData-value">{`${state.count || 0} шт.`}</span>
      </div>
      <div className="CustomerData-row">
        <span className="CustomerData-label CustomerData-label_total">
          Итого
        </span>
        <span className="dots" />
        <span className="CustomerData-value CustomerData-value_total">{`${makeCurrency(
          state.totalPrice,
        )} ₽`}</span>
      </div>
      {state.toMin > 0 && (
        <div className="CustomerData-warning">
          <InfoRedIcon />
          <div>{`До минимальной суммы заказа осталось ${makeCurrency(
            state.toMin,
          )} ₽`}</div>
        </div>
      )}
      <div className="CustomerData-amount">{`Общая сумма во всех корзинах: ${makeCurrency(
        state.amount,
      )} ₽`}</div>
      <ButtonDefault
        disabled={disabledSend || orderPending}
        onClick={onCreateOrder}
        className="CustomerData-button-order"
        loading={orderPending}
      >
        Оформить заказ
      </ButtonDefault>
      {showSuccessModal && <SuccessModal close={handleSuccessModalClose} />}
    </div>
  )
}

export default CustomerData
