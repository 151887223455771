import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import moment from "moment"
import uniq from "lodash/uniq"
import GlobalEscapeHandle from "Components/GlobalEscapeHandle"
import { ReactComponent as CloseIcon } from "Container/icons/close.svg"
import { ReactComponent as MailIcon } from "Container/Admin/icons/send_later.svg"
import { ReactComponent as AttachIcon } from "Container/Admin/icons/attach.svg"
import { ReactComponent as TrashIcon } from "Container/Admin/icons/trash.svg"
import { ReactComponent as DownloadIcon } from "Container/icons/download.svg"
import { InputArea } from "Components/Input"
import { ButtonCancel, ButtonDefault, ButtonIcon } from "Components/Button"
import saveMessage, {
  removeMessage,
} from "Container/Admin/Message/MessageModal/helper"
import {
  getFileIcon,
  checkFormat,
} from "Container/Orders/RatingComponent/helper"
import DatePickerComponent from "Components/DatePicker"
import SelectComponent from "Components/Select"
import { Upload, notification } from "antd"
import { ReactComponent as BucketIcon } from "Container/icons/bucket.svg"

const range = (start, end) => {
  const result = []
  for (let i = start; i < end; i += 1) {
    result.push(i)
  }
  return result
}

const MessageModalComponent = ({ close, data, ultimateLogout, refresh }) => {
  const history = useHistory()
  const haveId = data.messageId
  const isEdit = haveId && !data.isSend
  const isPreview = !isEdit && haveId
  const [messageState, setMessageState] = useState({
    halls: data.halls,
    title: data.title || null,
    content: data.content || null,
    files: data.files || [],
    scheduledDate: data.scheduledDate || null,
  })
  const [baseFiles, setBaseFiles] = useState([])
  const [editFiles, setEditFiles] = useState(isEdit ? data.files : [])
  const filesWithoutDelete = messageState.files.filter(item => !item.delete)
  const haveFiles = !!(
    messageState.files &&
    filesWithoutDelete &&
    filesWithoutDelete.length
  )
  const [formPending, setFormPending] = useState(false)
  const sentDisabled =
    !messageState.halls ||
    !messageState.title ||
    !messageState.content ||
    formPending
  const [pickerState, toggleDatePicker] = useState(false)

  const disabledDate = current =>
    moment(current).valueOf() < moment().startOf(`day`).valueOf()

  const disabledTime = current => {
    const disableTime =
      current &&
      current.day() === moment().day() &&
      current.month() === moment().month()
    const disableMinutes =
      current &&
      current.hour() === moment().hour() &&
      current.day() === moment().day() &&
      current.month() === moment().month()
    return {
      disabledHours: () => range(0, disableTime ? moment().hour() : 0),
      disabledMinutes: () =>
        range(0, disableTime && disableMinutes ? moment().minute() : 0),
    }
  }

  const [deleteModal, setDeleteModal] = useState({
    display: `inherit`,
    open: false,
  })

  return (
    <div className="MessageModalOverlay">
      <div
        className="MessageModalComponentWrapper"
        style={{ display: deleteModal.display }}
      >
        <div className="MessageModalHeader">
          <div className="MessageModalTitle">
            {/* eslint-disable-next-line no-nested-ternary */}
            {!haveId
              ? `Новое сообщение`
              : isEdit
              ? `Редактировать сообщение`
              : `Сообщение ${data.messageId}`}
          </div>
          <div className="MessageModalCloseWrapper">
            <div />
            <div className="CloseIcon" onClick={close}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="MessageModalContent">
          <div className="MessageModalContentBlock">
            <div className="MessageModalContentBlockTitle">кому:</div>
            <div className="MessageModalContentBlockData">
              <div className="MessageModalContentInputWrapper">
                <SelectComponent
                  name="to"
                  allowClear
                  className="SelectClubsWrapper"
                  mode="tags"
                  style={{ width: `100%` }}
                  value={messageState.halls || []}
                  onChange={value => {
                    const valueWithoutNaN = value
                      ? value.filter(item => !!Number(item))
                      : []
                    if (
                      !messageState.halls ||
                      messageState.halls.length < value.length
                    ) {
                      const lastEnter = value
                        .pop()
                        .split(`,`)
                        .map(Number)
                        .filter(item => !!Number(item))
                      const newValue = uniq([
                        ...(messageState.halls || []),
                        ...lastEnter,
                      ])
                      setMessageState({ ...messageState, halls: newValue })
                    } else {
                      setMessageState({
                        ...messageState,
                        halls: valueWithoutNaN,
                      })
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="MessageModalRectangleWithPadding" />
          <div className="MessageModalContentBlock">
            <div className="MessageModalContentBlockTitle">тема:</div>
            <div className="MessageModalContentBlockData">
              {(!haveId || isEdit) && (
                <div className="MessageModalContentInputWrapper">
                  <InputArea
                    value={messageState.title}
                    className="MessageModalInputTheme"
                    onChange={({ target: { value } }) =>
                      setMessageState({ ...messageState, title: value })
                    }
                    bordered={false}
                    maxLength={200}
                    autoSize
                  />
                </div>
              )}
              {haveId && !isEdit && <div>{data.title}</div>}
            </div>
          </div>
          <div className="MessageModalRectangleWithPadding" />
          <div className="MessageModalContentBlock">
            {(!haveId || isEdit) && (
              <div className="MessageModalContentInputWrapper">
                <InputArea
                  bordered={false}
                  value={messageState.content}
                  onChange={({ target: { value } }) =>
                    setMessageState({ ...messageState, content: value })
                  }
                  rows={5}
                  maxLength={1000}
                  autoSize={{ minRows: 5, maxRows: 10 }}
                  placeholder="ТЕКСТ СООБЩЕНИЯ"
                  className="MessageModalTextInputArea HiddenOverflowContent"
                />
              </div>
            )}
            {haveId && !isEdit && <div>{data.content}</div>}
          </div>
          {haveFiles && <div className="MessageModalRectangleWithPadding" />}
        </div>
        <div className="UploadFileListWrapper MessageUploadFileListWrapper">
          {messageState.files.map(
            file =>
              !file.delete && (
                <div
                  className={`UploadItemRender UploadItemRenderMessageModal ${
                    isPreview ? `UploadItemWithDownloadRender` : ``
                  }`}
                >
                  <div className="UploadItemTitle">
                    <div
                      className="UploadItemIcon"
                      style={{ marginTop: `2px` }}
                    >
                      {getFileIcon(file.type)}
                    </div>
                    <div
                      className={`UploadItemLabel ${
                        isPreview && `UploadDownloadLink`
                      }`}
                    >
                      {isPreview ? (
                        <a href={file.link} download={`${file.fileName}`}>
                          {file.fileName}
                        </a>
                      ) : (
                        file.name || file.fileName
                      )}
                    </div>
                  </div>
                  {!isPreview ? (
                    <div
                      className="UploadItemDelete"
                      style={{ paddingLeft: `24px`, marginTop: `6px` }}
                      onClick={() => {
                        let filterReasonsList = []
                        if (file.uid) {
                          const filterReasonsList = baseFiles.filter(
                            item => item.uid !== file.uid,
                          )
                          setBaseFiles(filterReasonsList)
                          setMessageState({
                            ...messageState,
                            files: [...filterReasonsList, ...editFiles],
                          })
                        }
                        if (file.id) {
                          filterReasonsList = editFiles.map(item =>
                            item.id === file.id
                              ? { id: file.id, delete: true }
                              : item,
                          )
                          setEditFiles(filterReasonsList)
                          setMessageState({
                            ...messageState,
                            files: [...filterReasonsList, ...baseFiles],
                          })
                        }
                      }}
                    >
                      <BucketIcon />
                    </div>
                  ) : (
                    <div
                      style={{ paddingLeft: `16px` }}
                      className="UploadItemDownload"
                    >
                      <DownloadIcon />
                    </div>
                  )}
                </div>
              ),
          )}
        </div>
        {(!haveId || isEdit) && (
          <div className="MessageModalFooter">
            <div style={{ paddingLeft: `40px` }}>
              <ButtonDefault
                className="MessageModalFooterSendButton"
                disabled={sentDisabled}
                onClick={() =>
                  saveMessage({
                    ultimateLogout,
                    data: messageState,
                    history,
                    close,
                    baseFiles,
                    editFiles,
                    messageId: data.messageId,
                    refresh,
                    setFormPending,
                  })
                }
              >
                Отправить{" "}
                <span className="MessageModalFooterSendButtonDate">
                  {messageState.scheduledDate
                    ? moment(messageState.scheduledDate).format(
                        `DD.MM.YYYY в HH:mm`,
                      )
                    : ``}
                </span>
              </ButtonDefault>
            </div>
            <div style={{ paddingLeft: `16px` }}>
              <div className="MessageModalDate">
                <div className="MessageModalTooltipWrapper MessageModalTooltipDate">
                  Запланировать отправку
                </div>
                <ButtonIcon onClick={() => toggleDatePicker(!pickerState)}>
                  <MailIcon />
                </ButtonIcon>
              </div>
              <DatePickerComponent
                value={messageState.scheduledDate}
                style={{
                  position: `absolute`,
                  visibility: `hidden`,
                  width: `0`,
                  marginTop: `-388px`,
                }}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                dropdownClassName="MessageSendAfterPickerWrapper"
                open={pickerState}
                allowClear
                showTime={{ format: "HH:mm" }}
                onOk={value => {
                  setMessageState({
                    ...messageState,
                    scheduledDate: value ? value.valueOf() : null,
                  })
                  toggleDatePicker(!pickerState)
                }}
                renderExtraFooter={() => (
                  <div className="DatePickerExtraFooter">
                    <div className="DatePickerExtraFooterButtons">
                      <ButtonCancel onClick={() => toggleDatePicker(false)}>
                        Отмена
                      </ButtonCancel>
                    </div>
                    <div
                      style={{ paddingLeft: `16px` }}
                      className="DatePickerExtraFooterButtons"
                    >
                      <ButtonCancel
                        onClick={() => {
                          setMessageState({
                            ...messageState,
                            scheduledDate: null,
                          })
                          toggleDatePicker(false)
                        }}
                      >
                        Сбросить
                      </ButtonCancel>
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="UploaderBlock" style={{ paddingLeft: `16px` }}>
              <Upload
                onChange={({ file }) => {
                  const format = checkFormat(file.name)
                  const size = file && file.size && file.size <= 50000000
                  if (format && size) {
                    const typeFile = { ...file, type: format }
                    setMessageState({
                      ...messageState,
                      files: [...messageState.files, typeFile],
                    })
                    const reader = new FileReader()
                    reader.onload = e => {
                      // eslint-disable-next-line no-param-reassign
                      typeFile.base64 = e.target.result
                    }
                    reader.readAsDataURL(file.originFileObj)
                    setBaseFiles([...baseFiles, typeFile])
                  } else if (!format) {
                    notification.error({
                      message: `Некорректный формат файла.`,
                    })
                  } else if (!size) {
                    notification.error({
                      message: `Некорректный размер файла.`,
                    })
                  }
                }}
                customRequest={() => null}
                itemRender={() => null}
              >
                <div className="MessageModalUpload">
                  <div className="MessageModalTooltipWrapper MessageModalTooltipUpload">
                    Прикрепить файл
                  </div>
                  <ButtonIcon>
                    <AttachIcon />
                  </ButtonIcon>
                </div>
              </Upload>
            </div>
            {haveId && (
              <div
                style={{ paddingLeft: `16px` }}
                className="MessageModalDelete"
              >
                <div className="MessageModalTooltipWrapper MessageModalTooltipDelete">
                  Удалить сообщение
                </div>
                <div>
                  <ButtonIcon
                    onClick={() =>
                      setDeleteModal({ display: `none`, open: true })
                    }
                  >
                    <TrashIcon />
                  </ButtonIcon>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {deleteModal.open && (
        <div className="MessageDeleteModalWrapper">
          <div className="MessageDeleteModalTitle">
            Вы действительно хотите удалить сообщение?
          </div>
          <div className="MessageDeleteModalButtonWrapper">
            <ButtonDefault
              style={{ height: `40px` }}
              onClick={() =>
                removeMessage({
                  id: haveId,
                  history,
                  close,
                  baseFiles,
                  messageId: data.messageId,
                  refresh,
                })
              }
            >
              Удалить
            </ButtonDefault>
            <ButtonDefault
              style={{ height: `40px` }}
              onClick={() =>
                setDeleteModal({ display: `inherit`, open: false })
              }
            >
              Отмена
            </ButtonDefault>
          </div>
        </div>
      )}
      <GlobalEscapeHandle onEscapePress={close} />
    </div>
  )
}
export default MessageModalComponent
