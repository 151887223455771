import React, { createContext, useContext, useReducer } from "react"

const CartContext = createContext()

const CART_CHANGE = "CART_CHANGE"
const CART_SET_MIN = "CART_SET_MIN"
const CART_SET_AMOUNT = "CART_SET_AMOUNT"
const CART_UPDATE_AMOUNT = "CART_UPDATE_AMOUNT"
const CART_UPDATE_ACTIVE_ID = "CART_UPDATE_ACTIVE_ID"

const cartReducer = (state, action) => {
  switch (action.type) {
    case CART_CHANGE:
      return {
        ...state,
        ...action.state,
        toMin: (state.min !== null
          ? state.min - action.state.totalPrice
          : null
        )?.toFixed(2),
      }
    case CART_SET_MIN:
      return { ...state, min: action.min }
    case CART_SET_AMOUNT:
      return { ...state, amount: action.amount }
    case CART_UPDATE_AMOUNT:
      return {
        ...state,
        amount: Math.abs(Number(state.amount) + action.amount),
      }
    case CART_UPDATE_ACTIVE_ID:
      return {
        ...state,
        activeId: action.activeId,
      }
    default:
      return state
  }
}

const initialState = {
  count: 0,
  totalPrice: 0,
  toMin: null,
  min: null,
  amount: 0,
  activeId: null,
  repeatOrders: null,
}

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState)

  const changeCart = state => {
    const result = Object.values(state).reduce(
      (acc, item) => {
        if (item.available === "1" && item.isDeleted === "0") {
          const count = Number(item.count)
          const price =
            (Number(item.price) / Number(item.multiplicity || 1)) * count
          acc.totalPrice += price
          acc.count += count
        }
        return acc
      },
      { count: 0, totalPrice: 0 },
    )
    dispatch({ type: CART_CHANGE, state: result })
  }

  const setMinValue = min => {
    dispatch({ type: CART_SET_MIN, min })
  }

  const setAmount = priceData => {
    const amount = priceData.reduce((acc, el) => {
      acc += Number(el.amount.replace(",", "."))
      return acc
    }, 0)
    dispatch({ type: CART_SET_AMOUNT, amount: amount?.toFixed(2) })
  }

  const updateAmount = data => {
    dispatch({ type: CART_UPDATE_AMOUNT, amount: data })
  }

  const updateActiveCart = id => {
    dispatch({ type: CART_UPDATE_ACTIVE_ID, activeId: id })
  }

  const setRepeatOrders = orders => {
    localStorage.setItem("repeatOrders", JSON.stringify(orders))
  }

  const removeRepeatOrders = () => {
    localStorage.removeItem("repeatOrders")
  }

  const getRepeatOrders = () => JSON.parse(localStorage.getItem("repeatOrders"))

  const contextValue = {
    state,
    changeCart,
    setMinValue,
    setAmount,
    updateAmount,
    updateActiveCart,
    setRepeatOrders,
    removeRepeatOrders,
    getRepeatOrders,
  }

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}

export const useCart = () => useContext(CartContext)
