import React, { useState, useEffect } from "react"
import { ReactComponent as PriceIcon } from "Container/Admin/icons/price.svg"

import loadPrices from "./helper"

const AccessPrice = ({ history, searchValue, ultimateLogout }) => {
  const [priceList, setPriceList] = useState({
    data: [],
    pending: false,
  })
  useEffect(() => {
    loadPrices({
      history,
      searchValue,
      ultimateLogout,
      priceList,
      setPriceList,
    })
  }, [searchValue])
  return (
    <div className="AccessPriceWrapper">
      {priceList.data.map(item => (
        <div
          className="AccessPriceItemWrapper"
          onClick={() => history.push(`/admin/access/price/${item.id}`)}
        >
          <div className="AccessPriceItem">
            <div className="AccessPriceItemIconWrapper">
              <PriceIcon />
            </div>
            <div className="AccessPriceItemLabel">{item.name}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default AccessPrice
