import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Request from "api/request"
import { ReactComponent as ArrowDown } from "Container/icons/arrow-down.svg"
import { ReactComponent as ArrowUp } from "Container/icons/arrow-up.svg"
import GoodsRow from "./GoodsRow"
import TobbaccoInfo from "../TobbaccoInfo"

const TobbaccoCategory = "Табак"

const PriceListTable = ({
  refreshList,
  cartItems,
  setCartItems,
  user,
  currentPrice,
  isRepeat,
  ultimateLogout,
  unitData,
  currentHall,
  priceDataView,
  setPriceDataView,
  isCart,
  minSum,
  setSelectedGuids,
  selectedGuids,
  search,
}) => {
  const history = useHistory()
  const [tobbaccoDisabled, setTobbaccoDisabled] = useState(false)
  const [activeCategory, setActiveCategory] = useState(0)

  useEffect(() => {
    if (!isRepeat && !isCart && user !== null) {
      Request({
        ultimateLogout,
        url: `product/list`,
        history,
        requestBody: { id: user, hall: currentHall, search },
        method: `post`,
      }).then(({ data }) => {
        setPriceDataView(data)
      })
    }
  }, [user, isCart, search])

  useEffect(() => {
    let total = 0

    Object.keys(cartItems).forEach(item => {
      if (
        cartItems[item].count &&
        cartItems[item].category !== TobbaccoCategory
      ) {
        total += cartItems[item].price * cartItems[item].count
      }
    })

    setTobbaccoDisabled(minSum > total)
  }, [cartItems])

  const handleCategoryToggle = keyCategory => {
    setActiveCategory(prevActiveCategory =>
      prevActiveCategory === keyCategory ? null : keyCategory,
    )
  }
  const [cartCountPending, setCardCountPending] = useState(false)
  const isScreenMobile = window.screen.width < 992
  return (
    <>
      {priceDataView?.data?.map((priceDataViewItem, keyCategory) => {
        const checkProductAvailable = product =>
          product.isDeleted === "0" && product.available === "0"
        const sortGoodsByAvailable = priceDataViewItem.goods?.sort(
          (current, prev) =>
            checkProductAvailable(current) - checkProductAvailable(prev),
        )
        return (
          <div
            key={`${priceDataViewItem.categoryGuid}-${priceDataViewItem.article}`}
            className={`PriceTable ${isCart ? "PriceTable_cart" : ""}`}
          >
            {isCart ? (
              <div className="PriceTable-bodyInner">
                {sortGoodsByAvailable.map((goodsItem, idx) => {
                  if (cartItems[goodsItem.productGuid]?.count > 0) {
                    return (
                      <GoodsRow
                        refreshList={refreshList}
                        category={priceDataViewItem.category}
                        currentPrice={currentPrice}
                        isCart={isCart}
                        hall={currentHall}
                        cartItems={cartItems}
                        setCartItems={setCartItems}
                        product={goodsItem}
                        unitData={unitData}
                        selectedGuids={selectedGuids}
                        setSelectedGuids={setSelectedGuids}
                        cartCountPending={cartCountPending}
                        setCardCountPending={setCardCountPending}
                        tobbaccoDisabled={
                          priceDataViewItem.category === TobbaccoCategory &&
                          tobbaccoDisabled
                        }
                      />
                    )
                  }
                  return null
                })}
              </div>
            ) : (
              <>
                <div
                  className="PriceTable-head PriceTableCategoryHead"
                  onClick={() => handleCategoryToggle(keyCategory)}
                >
                  <div
                    style={{ minWidth: `200px` }}
                    className={`PriceTable-item PriceTable-itemCategory ${
                      isScreenMobile ? `PriceTable-itemCategoryMobile` : ``
                    }`}
                  >
                    <span
                      style={{ color: "#27293A", minWidth: "152px" }}
                      className="PriceTableItemCategoryTitle"
                    >
                      {priceDataViewItem.category}
                    </span>
                    {isScreenMobile && (
                      <div className="PriceTable-itemCategoryMobile-ArrowWrapper">
                        {activeCategory === keyCategory ? (
                          <ArrowDown />
                        ) : (
                          <ArrowUp />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="PriceTable-item" />
                  <div className="PriceTable-item PriceTable-itemSum s-hide PriceTableItemTitle">
                    {activeCategory === keyCategory ? (
                      <ArrowDown />
                    ) : (
                      <ArrowUp />
                    )}
                  </div>
                </div>
                {activeCategory === keyCategory && (
                  <div className="PriceTable-body">
                    {priceDataViewItem.category === TobbaccoCategory && (
                      <TobbaccoInfo minSum={minSum} />
                    )}
                    <div className="PriceTable-bodyInner">
                      {priceDataViewItem.goods.map((goodsItem, idx) => (
                        <GoodsRow
                          category={priceDataViewItem.category}
                          currentPrice={currentPrice}
                          isCart={isCart}
                          hall={currentHall}
                          cartItems={cartItems}
                          setCartItems={setCartItems}
                          product={goodsItem}
                          unitData={unitData}
                          selectedGuids={selectedGuids}
                          setSelectedGuids={setSelectedGuids}
                          tobbaccoDisabled={
                            priceDataViewItem.category === TobbaccoCategory &&
                            tobbaccoDisabled
                          }
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )
      })}
    </>
  )
}

export default PriceListTable
