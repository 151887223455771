import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"

const FAQLicense = () => {
  const history = useHistory()
  const { local } = useParams()
  let licenseConfig = {
    title: `Документ не найден`,
    licenseImg: [``, ``],
  }
  switch (local) {
    case `krasnodar`: {
      licenseConfig = {
        title: `Лицезия для Краснодарского края`,
        licenseImg: [`KrasnodarLicenseOne`, `KrasnodarLicenseTwo`],
      }
      break
    }
    case `rostov`: {
      licenseConfig = {
        title: `Лицензия для Ростовской области`,
        licenseImg: [`RostovLicenseOne`, `RostovLicenseTwo`],
      }
      break
    }
    case `tula`: {
      licenseConfig = {
        title: `Лицензия Тульской области`,
        licenseImg: [`TulaLicenseOne`, `TulaLicenseTwo`],
      }
      break
    }
    default: {
      break
    }
  }

  return (
    <div className="FAQLicenseWrapper">
      <div className="FlexRow">
        <div className="FAQLicenseBack" onClick={() => history.push(`/faq`)}>
          <ArrowLeftOutlined />
        </div>
        <h1>{licenseConfig.title}</h1>
      </div>
      <div className="FlexRow FAQLicenseImages">
        <div className={`FAQLicenseBlock ${licenseConfig.licenseImg[0]}`}>
          <div className="FAQLicenseBlockWaterMark">
            ©KeyteringPlus. <br />
            Все права защищены.
          </div>
        </div>
        <div className={`FAQLicenseBlock ${licenseConfig.licenseImg[1]}`}>
          <div className="FAQLicenseBlockWaterMark">
            ©KeyteringPlus. <br />
            Все права защищены.
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQLicense
