import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Col, Row, Layout } from "antd"
import { ButtonDefault, ButtonPrimary } from "Components/Button"
import Input from "Components/Input"
import { SwitchComponent } from "Components/Switch"
import SliderPage from "Components/Slider"
import img from "Container/icons/bg-first-screen.jpg"
import { ReactComponent as LogoModal } from "Container/icons/logo-modal.svg"
import { ReactComponent as LogoIcon } from "../icons/logo.svg"
import { ReactComponent as CloseIcon } from "../icons/close.svg"
import { login } from "./api"

const { Header, Content } = Layout

const LoginPage = ({ isAuth, setAuth }) => {
  const [loginData, setLoginData] = useState({
    username: sessionStorage.getItem(`username`) || ``,
    password: sessionStorage.getItem(`password`) || ``,
    errors: false,
    pending: false,
  })
  const [rememberMeValue, toggleRemember] = useState(
    !!sessionStorage.getItem(`username`),
  )
  const history = useHistory()
  const loginAction = () => {
    setLoginData({ ...loginData, pending: true })
    login({
      setAuth,
      loginData,
      setLoginData,
      isRemember: rememberMeValue,
    })
  }

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (isAuth && isAuth.token) {
      history.push(`request`)
    }
  }, [isAuth])

  const showLoginForm = () => {
    setShowModal(true)
  }

  const closeLoginForm = () => {
    setShowModal(false)
  }

  return (
    <Content className="LoginPage">
      <section className="LoginPage-firstScreen">
        <Header className="Header Header-main">
          <div className="Container">
            <Row>
              <Col span={12}>
                <div className="Logo Logo-header">
                  <LogoIcon />
                </div>
              </Col>
              <Col span={12}>
                <ButtonPrimary size="large" onClick={showLoginForm}>
                  Войти
                </ButtonPrimary>
              </Col>
            </Row>
          </div>
        </Header>

        <div className="LoginPage-screen">
          <div className="Container">
            <div className="LoginPage-offer">
              <h1 className="LoginPage-title">
                Кейтеринг Плюс -<br /> услуги выездного
                <br /> бара по всей
                <br /> России!
              </h1>
              <ButtonDefault size="large" onClick={showLoginForm}>
                Оставить заявку
              </ButtonDefault>
            </div>
            <div className="LoginPage-image">
              <div className="LoginPage-imageWrap">
                <img alt="KeyteringPlus" src={img} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="LoginPage-slider">
        <div className="Container">
          <SliderPage />
        </div>
      </section>

      <section className="LoginPage-content">
        <div className="Container">
          <div className="ServiceInfo">
            <div className="ServiceInfo-item">
              <div className="ServiceInfo-header">
                <h2 className="ServiceInfo-title Title-h1">
                  5 лет успешной работы
                </h2>
              </div>
              <p className="ServiceInfo-text">
                На протяжении более чем пяти лет компания «Кейтеринг Плюс»
                оказывает услуги выездного сервиса в Москве и в регионах России.
                Сервисная компания «Кейтеринг Плюс» никак не ограничивает своих
                заказчиков в выборе локаций для мероприятий. У нас Вы можете
                заказать ресторан выездного обслуживания – кейтеринг.
              </p>
            </div>
            <div className="ServiceInfo-item">
              <div className="ServiceInfo-header">
                <h2 className="ServiceInfo-title Title-h1">
                  Кейтеринг
                  <br /> на любое событие
                </h2>
              </div>
              <p className="ServiceInfo-text">
                Мы настоящие профессионалы в организации крупных событий и столь
                же мастерски подходим к созданию небольших камерных мероприятий
                на 20-50 персон. Мы готовы предоставить ресторанное обслуживание
                на деловые и корпоративные мероприятия, праздники частных лиц,
                выпускные вечера, социальные мероприятия и другие события.
              </p>
            </div>
            <div className="ServiceInfo-item">
              <div className="ServiceInfo-header">
                <h2 className="ServiceInfo-title Title-h1">
                  Система
                  <br /> «всё включено»
                </h2>
              </div>
              <p className="ServiceInfo-text">
                В наши услуги входит полная организация и обслуживание питания,
                в том числе аренда оборудования, доставка, оформление торжества,
                подача блюд, сервировка и уборка. Любое тематическое меню, любое
                количество гостей, любой бюджет – для нас нет никаких
                ограничений. Мы поможем организовать питание в любом формате:
                банкет, гала-ужин, фуршет, кофе-брейк или коктейль в
                соответствии с вашими пожеланиями.
              </p>
              <p className="ServiceInfo-text">Будем рады сотрудничеству!</p>
            </div>
          </div>
        </div>
      </section>
      <div
        // onClick={closeLoginForm}
        className={`ModalLogin ${showModal ? "show" : ""}`}
      >
        {showModal && (
          <div className="ModalLogin-dialog">
            <div className="ModalLogin-close">
              <CloseIcon onClick={closeLoginForm} />
            </div>
            <div className="ModalLogin-icon">
              <LogoModal />
            </div>
            <div className="ModalLogin-body">
              <h3 className="ModalLogin-title">Войдите, чтобы продолжить</h3>
              <div className="ModalLogin-group">
                <div className="ModalLogin-row">
                  <Input
                    customClass="InputWithInfo"
                    value={loginData.username}
                    placeholder="Введите логин"
                    style={{
                      border: loginData.errors ? `1px solid #FF7454` : ``,
                    }}
                    onChange={({ target: { value } }) =>
                      setLoginData({
                        ...loginData,
                        username: value,
                        errors: false,
                      })
                    }
                    onPressEnter={loginAction}
                  />
                  <div
                    className={`InputPlaceholderInfo ${
                      loginData.username
                        ? `InputLoginPlaceholderInfoOn`
                        : `InputPlaceholderInfoOff`
                    }`}
                  >
                    Введите логин
                  </div>
                </div>
                <div className="ModalLogin-row">
                  <Input
                    type="password"
                    value={loginData.password}
                    style={{
                      border: loginData.errors ? `1px solid #FF7454` : ``,
                    }}
                    placeholder="Введите пароль"
                    onChange={({ target: { value } }) =>
                      setLoginData({
                        ...loginData,
                        password: value,
                        errors: false,
                      })
                    }
                    onPressEnter={loginAction}
                  />
                  <div
                    className={`InputPlaceholderInfo ${
                      loginData.password
                        ? `InputPasswordPlaceholderInfoOn`
                        : `InputPlaceholderInfoOff`
                    }`}
                  >
                    Введите пароль
                  </div>
                </div>
                {loginData.errors && (
                  <p className="ErrorMessage">{loginData.errors}</p>
                )}
                <div className="ModalLogin-row">
                  <SwitchComponent
                    checked={rememberMeValue}
                    label="Запомнить меня"
                    onChange={value => toggleRemember(value)}
                  />
                </div>
              </div>
              <div className="ModalLogin-button">
                <ButtonDefault
                  loading={loginData.pending}
                  onClick={loginAction}
                  block
                >
                  Войти
                </ButtonDefault>
              </div>
            </div>
          </div>
        )}
      </div>
    </Content>
  )
}

export default LoginPage
