import React from "react"
import { ReactComponent as Info } from "Container/icons/info-tobbacco.svg"

const TobbaccoInfo = ({ minSum }) => {
  const TobbaccoAlert = `Соберите товаров из других категорий на сумму ${minSum} ₽, тогда сможете
  добавить сигареты к заказу`

  return (
    <div className="TobbaccoInfoBlock">
      <Info className="InfoIcon" />
      <div className="TobbaccoInfoContent">
        <span>{TobbaccoAlert}</span>
      </div>
    </div>
  )
}

export default TobbaccoInfo
