import { prepareDateToBack } from "api/constants"

export const defaultPagination = {
  limit: 5,
  offset: 0,
}

export const getDefaultFilter = () => ({
  dates: undefined,
  halls: [localStorage.getItem(`hall`)],
  status_id: undefined,
})

export const filterConstants = {
  placeholder: {
    halls: `Все клубы`,
    dates: [`Выберите`, `даты`],
    status: `Все статусы`,
  },
  allHallsOption: {
    id: undefined,
    value: `all`,
    label: `Все статусы`,
  },
}

export const getFileName = halls =>
  `Заказы ${
    halls ? `клубов - ${halls.join(`, `)}` : `всех доступных клубов`
  }.xls`

export const prepareDataToBack = filter => {
  const isEmptyFilter = !filter.dates && !filter.halls && !filter.status_id
  if (isEmptyFilter) {
    return undefined
  }
  const dates = prepareDateToBack(filter.dates)
  const hallsNumber = filter.halls ? filter.halls.map(Number) : undefined
  const statusIdNumber = filter.status_id ? Number(filter.status_id) : undefined
  return { ...filter, halls: hallsNumber, status_id: statusIdNumber, dates }
}

export const statusBadgeColor = status => {
  switch (status) {
    case `1`:
      return `#4339F2`
    case `2`:
      return `#FF3A29`
    case `3`:
      return `#02A0FC`
    case `4`:
      return `#FFB200`
    case `5`:
      return `#34B53A`
    case `6`:
      return `#34B53A`
    case `7`:
      return `#34B53A`
    default:
      return `#34B53A`
  }
}
