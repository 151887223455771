export const LOGIN_ROUTE = `/login`
export const ADMIN_ROUTE = `/admin`
export const PRICE_LIST_ROUTE = `/price-list`
export const CART_ROUTE = `/cart`
export const REPEAT_ID_ROUTE = `/repeat/:id`
export const LEFTOVER_CREATE_ROUTE = `/leftover/create`
export const LEFTOVER_HISTORY_ROUTE = `/leftover/history`
export const ORDERS_ROUTE = `/orders`
export const FAQ_ROUTE = `/faq`
export const MESSAGE_ROUTE = `/message`
export const MESSAGE_ID_ROUTE = `/message/:id`
export const CONTACTS_ROUTE = `/contacts`
export const FAQ_LICENSE = `/faq/license/:local`
export const SERVER_ERROR_PAGE = `/500`
export const ERROR_PAGE = `/404`

export const isActive = (current, locationPath) => current === locationPath

/**
 * hall region id, 5 && 792 - Moscow and Moscow regions
 * @param {string} hall
 * @param history
 * @return {boolean}
 */
export const checkRegion = (hall, ultimateLogout) => {
  try {
    const currentHall = hall || localStorage.getItem(`hall`)
    const addressesByHalls = JSON.parse(
      localStorage.getItem(`addressesByHalls`) || `{}`,
    )
    if (currentHall && addressesByHalls) {
      const hallData = addressesByHalls[currentHall]
      return hallData?.region_id === `5` || hallData?.region_id === `792`
    }
    return false
  } catch {
    if (ultimateLogout) {
      ultimateLogout()
    }
    return false
  }
}
export const redirectBuffet = () => {
  window.location = "https://catering-club.ru"
}
