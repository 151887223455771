import React from "react"
import { Table } from "antd"
import PaginationComponent from "Components/Pagination"
import useTable from "./useTable"

const TableComponent = ({
  source,
  columns,
  scrollX,
  rowClick,
  search,
  extraBody,
  refresh,
  ultimateLogout,
  externalChangeSort,
  ...rest
}) => {
  const { tableData, setTableData, changeSort } = useTable({
    source,
    extraBody,
    search,
    refresh,
    ultimateLogout,
  })

  const { data, params, pending, total } = tableData

  const changeAllSort = order => {
    if (externalChangeSort) {
      externalChangeSort(order)
    }
    changeSort(order)
  }

  return (
    <div
      className={`TableWrapper ${rowClick ? `TableWrapperWithRowClick` : ``} ${
        rowClick ? `TableWithClickWrapper` : ``
      }`}
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        tableLayout="fixed"
        scroll={{ x: scrollX, y: `calc(100vh - 390px)` }}
        loading={pending}
        onRow={record => ({
          onClick: () => (rowClick ? rowClick(record) : {}),
        })}
        onChange={(pagination, filters, { columnKey, order }) => {
          if (order === `ascend`)
            changeAllSort({ field: columnKey, order: `asc` })
          if (order === `descend`)
            changeAllSort({ field: columnKey, order: `desc` })
          if (!order) changeAllSort(order)
        }}
        showSorterTooltip={false}
        {...rest}
      />
      <div className="PaginationWrapper">
        <PaginationComponent
          perPage={params.perPage}
          page={params.page}
          changePage={value =>
            setTableData({ ...tableData, params: { ...params, page: value } })
          }
          total={total}
        />
      </div>
    </div>
  )
}

export default TableComponent
