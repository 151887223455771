import Request from "api/request"
import makeCurrency, { checkProductInPrice } from "api/constants"

import { notification } from "antd"

export const updateCart = props => {
  const {
    guid,
    hall,
    quantity,
    passedMethod,
    product,
    currentPriceName,
    resetCountPending,
    getCartPricesHandler,
  } = props

  const isDelete = quantity === 0
  const method = passedMethod || (isDelete ? "delete" : "post")
  const body = { hall }

  if (isDelete) {
    if (Array.isArray(guid) && Array.isArray(guid[0])) {
      // eslint-disable-next-line prefer-destructuring
      body.productGuids = guid[0]
    } else if (Array.isArray(guid)) {
      body.productGuids = guid
    } else {
      body.productGuids = [guid]
    }
  } else {
    body.productGuid = guid
    body.quantity = quantity
  }
  const haveGuids = body.productGuids && !!body.productGuids.length
  if (!!haveGuids || !isDelete) {
    Request({
      url: "cart",
      method,
      requestBody: body,
    })
      .then(() => {
        if (!!currentPriceName && !!product) {
          const products = [
            {
              id: product.guid || product.productGuid,
              price: product.price,
              name: product.title,
              quantity,
              brand: currentPriceName,
            },
          ]
          window.keyteringOrders?.push({
            ecommerce: {
              currencyCode: "RUB",
              add: {
                products,
              },
            },
          })
        }
      })
      .then(() => {
        if (resetCountPending) {
          resetCountPending(false)
        }
      })
      // CRUTCH for refresh prices in card after...after anything we want
      .then(() => {
        if (getCartPricesHandler) {
          getCartPricesHandler()
        }
      })
  }
}

export const getCartItems = ({
  setCartItems,
  ultimateLogout,
  history,
  user,
  currentHall,
  setPriceDataView,
  isCart,
  isRepeat,
  priceDataView,
  setChangeCheckModal,
  setCheckedCartItems,
  toggleCheckPending,
  repeatGoods,
}) => {
  let cart = {}
  Request({
    ultimateLogout,
    url: `cart/list`,
    history,
    requestBody: { hall: currentHall, priceListId: user },
    method: `post`,
  }).then(({ data }) => {
    if (user !== null) {
      const guidsList = []
      data.data.forEach(dataItem =>
        dataItem.goods.forEach(goodsItem => {
          const price = Number(goodsItem.price)
          const multiplicity = Number(goodsItem.multiplicity)
          const newPrice = price * multiplicity
          guidsList.push(goodsItem.productGuid)
          cart = {
            ...cart,
            [goodsItem.productGuid]: {
              name: goodsItem.title,
              id: goodsItem.productGuid,
              price: newPrice.toFixed(2),
              count: goodsItem.quantity,
              available: goodsItem.available,
              isDeleted: goodsItem.isDeleted,
              multiplicity: goodsItem.multiplicity,
            },
          }
        }),
      )
      setCartItems(cart)
      if (isCart) {
        setPriceDataView(data)
      }

      if (
        isRepeat &&
        !!priceDataView &&
        !!setChangeCheckModal &&
        !!toggleCheckPending
      ) {
        checkProductInPrice({
          ultimateLogout,
          guids: guidsList,
          priceListId: user,
          hall: currentHall,
        }).then(({ data }) => {
          toggleCheckPending(false)
          if (data.data.length) {
            const newCheckList = data.data?.map(item => {
              const currentCheck = cart[item.guid]
              const currentCheckRepeat = repeatGoods?.find(
                el => item.guid === el.guid,
              )
              let currentCheckRepeatPrice = -1
              if (currentCheckRepeat) {
                currentCheckRepeatPrice = Number(currentCheckRepeat.price)
              }
              const currentCheckPrice =
                currentCheck.price && currentCheck.multiplicity
                  ? Number(currentCheck.price) /
                    Number(currentCheck.multiplicity)
                  : currentCheck.price
              const currentCheckPriceWithDot = `${currentCheckPrice}`.includes(
                `.`,
              )
                ? currentCheckPrice
                : `${currentCheckPrice}.00`
              const currentCheckRepeatPriceWithDot =
                `${currentCheckRepeatPrice}`.includes(`.`)
                  ? currentCheckRepeatPrice
                  : `${currentCheckRepeatPrice}.00`
              let itemPrice = `${item.price}`.includes(`.`)
                ? item.price
                : `${item.price}.00`
              const itemDeleted = currentCheck.isDeleted === `1`
              const itemAvailableSoon =
                currentCheck.isDeleted === "0" && currentCheck.available === "0"
              let priceChanged =
                `${currentCheckPriceWithDot}` !== `${itemPrice}`
              if (
                currentCheckRepeatPrice > -1 &&
                `${currentCheckRepeatPriceWithDot}` !== `${itemPrice}`
              ) {
                priceChanged = true
                itemPrice = currentCheckRepeatPriceWithDot
              }
              const priceNew = priceChanged
                ? Number(currentCheckPriceWithDot)
                : undefined
              if (itemAvailableSoon || itemDeleted || priceChanged) {
                const priceDiff = Number(itemPrice) - Number(priceNew)
                return {
                  ...item,
                  multiplicity: currentCheck.multiplicity,
                  price: itemPrice,
                  itemDeleted,
                  itemAvailableSoon,
                  name: currentCheck.name,
                  priceChanged,
                  priceNew,
                  priceChangedTooltip: `Стало ${
                    priceDiff > 0 ? "дешевле" : "дороже"
                  } на ${makeCurrency(Math.abs(priceDiff))} ₽`,
                }
              }
              return undefined
            })
            const finalCheckObj = {}
            const finalCheckList = newCheckList?.filter(item => {
              if (item) {
                finalCheckObj[item.guid] = item
              }
              return !!item
            })
            if (finalCheckList?.length) {
              setCheckedCartItems(finalCheckObj)
              const goodItems = Object.keys(cart)
                .filter(
                  cartKey =>
                    !finalCheckObj[cartKey]?.itemDeleted &&
                    !finalCheckObj[cartKey]?.itemAvailableSoon,
                )
                .reduce((newObj, key) => {
                  newObj[key] = cart[key]
                  return newObj
                }, {})
              setCartItems(goodItems)
              setChangeCheckModal(true)
            }
          }
        })
      }
    }
  })
}

export const getRepeatOrder = ({
  id,
  setCartItems,
  ultimateLogout,
  history,
  priceData,
  setPriceData,
  setPriceDataView,
  priceDataView,
  hall,
  // for check
  toggleCheckPending,
  setChangeCheckModal,
  setCheckedCartItems,
  repeatGoods,
}) => {
  Request({
    ultimateLogout,
    method: `post`,
    url: "order/repeat",
    history,
    requestBody: { hall, orderId: id },
  }).then(({ data }) => {
    const priceListId = data?.priceListId ? Number(data?.priceListId) : 0
    toggleCheckPending(true)
    setPriceData({ ...priceData, active: priceListId })
    getCartItems({
      setCartItems,
      ultimateLogout,
      history,
      user: priceListId,
      currentHall: hall,
      setPriceDataView,
      isCart: true,
      isRepeat: true,
      priceDataView,
      setChangeCheckModal,
      setCheckedCartItems,
      toggleCheckPending,
      repeatGoods,
    })
  })
}

export const setAllFavorites = ({
  hall,
  keyCategory,
  setPriceDataView,
  priceDataView,
}) => {
  const updatedPriceData = priceDataView.data
  const requestData = []

  priceDataView.data[keyCategory].goods.forEach((item, key) => {
    updatedPriceData[keyCategory].goods[key] = {
      ...item,
      favorite: true,
    }

    requestData.push(item.guid)
  })
  updatedPriceData[keyCategory].categoryFavorite = true
  setPriceDataView({ data: updatedPriceData })

  Request({
    url: `favorites`,
    method: `post`,
    requestBody: { guids: requestData, hall },
  })
    .then(() =>
      notification.success({
        message: `Товары добавлены в избранное`,
      }),
    )
    .catch(() =>
      notification.error({
        message: `Не удалось добавить в избранное`,
      }),
    )
}
