import Request from "api/request"

const getLeftoverHistory = ({
  history,
  ultimateLogout,
  hall,
  filter,
  historyData,
  setData,
  params,
}) => {
  setData({ ...historyData, pending: true })
  Request({
    history,
    method: `post`,
    url: `leftovers`,
    ultimateLogout,
    requestBody: { hall, ...filter },
    params,
  })
    .then(({ data, headers }) =>
      setData({
        ...historyData,
        data,
        pending: false,
        total: headers[`total-count`],
      }),
    )
    .catch(() => setData({ ...historyData, pending: false }))
}

export default getLeftoverHistory
