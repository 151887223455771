import React, { useEffect, useState } from "react"
import moment from "moment"
import { ReactComponent as StrokeIcon } from "Container/icons/stroke.svg"
import InsertGoods from "./InsertGoods"

const InsertLeftover = ({ leftover, showIndex, setShowIndex }) => {
  const goods = Array.isArray(leftover.goods) ? leftover.goods : []
  const haveShowMore = goods && goods.length > 5
  const [showGoods, setShowGoods] = useState(goods.slice(0, 5))
  moment.locale("ru")

  useEffect(() => {
    if (leftover.id === showIndex) {
      setShowGoods(goods)
    } else {
      setShowGoods(goods.slice(0, 5))
    }
  }, [showIndex])

  const toggleShow = () =>
    setShowIndex(leftover.id === showIndex ? null : leftover.id)

  return (
    <div key={leftover.length} className="LeftoverTable">
      <div className="LeftoverTable-head">
        <div className="LeftoverTable-headGoods">
          <div className="LeftoverTable-item LeftoverTable-itemId">
            <div className="LeftoverTable-headerTitle">
              Отчет № {leftover.id}
            </div>
            <div className="LeftoverTable-headerLabel">
              от {moment(leftover.date).format(`DD MMMM HH:mm`)}
            </div>
          </div>
          <div className="LeftoverTable-itemHeader LeftoverTable-itemCount s-hide">
            Категория
          </div>
          <div className="LeftoverTable-itemHeader LeftoverTable-itemCount s-hide">
            Кол-во
          </div>
        </div>
      </div>
      <div className="LeftoverTable-body">
        <div className="LeftoverGoods">
          <InsertGoods goods={showGoods} />
        </div>
        <div className="LeftoverTable-item">
          <div className="LeftoverTable-label">Контактное лицо:</div>
          <div className="LeftoverTable-contact">{leftover.fullName}</div>
          <div className="LeftoverTable-label" style={{ paddingTop: `16px` }}>
            Телефон:
          </div>
          <div className="LeftoverTable-contact">{leftover.phone}</div>
        </div>
      </div>
      {haveShowMore && (
        <div className="ShowToggle" onClick={toggleShow}>
          {showIndex === leftover.id ? (
            <div className="DisplayRow">
              <div>Свернуть</div>
              <div className="StrokeIcon BackStroke">
                <StrokeIcon />
              </div>
            </div>
          ) : (
            <div className="DisplayRow">
              <div>Все товары</div>
              <div className="StrokeIcon">
                <StrokeIcon />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default InsertLeftover
