import axios from "axios"
import omit from "lodash/omit"
import { LOGIN_ROUTE } from "Container/BaseLayout/constants"

export const login = ({ setAuth, loginData, setLoginData, isRemember }) => {
  const requestBody = omit(loginData, [`errors`, `pending`])
  axios({
    method: `post`,
    url: `${process.env.REACT_APP_AUTH_URL}/login`,
    data: requestBody,
    withCredentials: true,
    headers: {
      "Content-Type": `application/json`,
    },
  })
    .then(({ data }) => {
      const addressesByHalls = {}
      const halls = (data.halls || [])?.map(item => {
        addressesByHalls[item.hall] = item
        return item.hall
      })
      localStorage.setItem(`token`, data.token) // data.token
      localStorage.setItem(`fullName`, data.fullName) // data.token
      localStorage.setItem(`email`, data.email) // data.token
      localStorage.setItem(`role`, data.role) // data.role
      localStorage.setItem(`hall`, data.hall) // data.hall
      localStorage.setItem(`halls`, halls) // data.halls - all available user halls
      localStorage.setItem(`address`, data.address) // data.hall_address
      localStorage.setItem(`addressesByHalls`, JSON.stringify(addressesByHalls))
      if (isRemember) {
        sessionStorage.setItem(`username`, loginData.username)
        sessionStorage.setItem(`password`, loginData.password)
      } else {
        sessionStorage.clear()
      }

      setAuth({
        token: data.token,
        role: data.role,
      })
      setLoginData({ ...loginData, pending: false, errors: false })
    })
    .catch(
      ({
        response: {
          data: { description },
        },
      }) => {
        setLoginData({ ...loginData, errors: description, pending: false })
      },
    )
}

export const logout = ({ history, setAuth }) => {
  const token = localStorage.getItem(`token`)
  axios({
    method: `post`,
    url: `${process.env.REACT_APP_AUTH_URL}/logout`,
    headers: {
      "Content-Type": `application/json`,
      token,
    },
  })
    .then(() => {
      localStorage.clear()
      setAuth({ token: null })
      history.push(LOGIN_ROUTE)
    })
    .catch(() => {
      localStorage.clear()
      setAuth({ token: null })
      history.push(LOGIN_ROUTE)
    })
}
