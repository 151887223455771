import React from "react"
import { Button } from "antd"

export const ButtonPrimary = ({ children, disabled, ...rest }) => (
  <div className="ButtonPrimaryWrapper">
    <Button
      type="primary"
      disabled={disabled}
      className={disabled ? `ButtonPrimaryDisabled` : `ButtonPrimary`}
      {...rest}
    >
      {children}
    </Button>
  </div>
)

export const ButtonDefault = ({
  children,
  orderId,
  disabled,
  customClass,
  ...rest
}) => (
  <div className="ButtonDefaultWrapper">
    <Button
      data-id={orderId}
      className={
        disabled
          ? `ButtonDefaultDisabled ${customClass}`
          : `ButtonDefault ${customClass || ``}`
      }
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  </div>
)

export const ButtonArrow = ({ children, ...rest }) => (
  <div className="ButtonArrowWrapper">
    <Button {...rest}>{children}</Button>
  </div>
)

export const ButtonIcon = ({ children, ...rest }) => (
  <div className="ButtonIconWrapper">
    <Button {...rest}>
      <div className="ButtonIconChildrenWrapper">{children}</div>
    </Button>
  </div>
)

export const ButtonCancel = ({ children, ...rest }) => (
  <div className="ButtonCancelWrapper">
    <Button {...rest}>{children}</Button>
  </div>
)

export default ButtonDefault
