import React from "react"
import Slider from "react-slick"
import S1 from "Container/icons/slider/sl-1.jpg"
import S2 from "Container/icons/slider/sl-2.jpg"
import S3 from "Container/icons/slider/sl-3.jpg"
import S4 from "Container/icons/slider/sl-4.jpg"
import S5 from "Container/icons/slider/sl-5.jpg"
import S6 from "Container/icons/slider/sl-6.jpg"
import S7 from "Container/icons/slider/sl-7.jpg"
import S8 from "Container/icons/slider/sl-8.jpg"
import S9 from "Container/icons/slider/sl-9.jpg"
import S10 from "Container/icons/slider/sl-10.jpg"
import S11 from "Container/icons/slider/sl-11.jpg"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SliderPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className="Slider">
      <Slider {...settings}>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S1} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S2} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S3} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S4} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S5} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S6} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S7} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S8} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S9} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S10} />
        </div>
        <div className="Slider-item">
          <img alt="KeyteringPlus" src={S11} />
        </div>
      </Slider>
    </div>
  )
}

export default SliderPage
