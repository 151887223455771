import React from "react"
import * as Sentry from "@sentry/browser"
import { ConfigProvider } from "antd"
import ruRu from "antd/es/locale/ru_RU"
import BaseLayout from "./Container/BaseLayout"
import "./App.less"

if (
  process.env.NODE_ENV === "production" &&
  window.location.host === "keytering.plus"
) {
  Sentry.init({
    dsn: "https://9b1d14fb242e5eb34a16ae9f4f839e30@sentry.offline.shelopes.com/4",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

require(`dotenv`).config()

const App = () => (
  <ConfigProvider locale={ruRu}>
    <BaseLayout />
  </ConfigProvider>
)

export default App
