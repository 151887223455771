import axios from "axios"
import { ERROR_PAGE, SERVER_ERROR_PAGE } from "Container/BaseLayout/constants"
import { notification } from "antd"
import * as Sentry from "@sentry/browser"

const Request = ({
  method,
  url,
  requestBody,
  params,
  ultimateLogout,
  contentType,
  history,
  extraHeaders,
  responseType,
}) => {
  const errorTime = setTimeout(() => {
    if (history) {
      localStorage.setItem(`504`, `show`)
      history.push(SERVER_ERROR_PAGE)
    }
  }, 18000)
  const token = localStorage.getItem(`token`)
  const hall = localStorage.getItem(`hall`)
  return axios({
    method,
    withCredentials: true,
    url: `${process.env.REACT_APP_AUTH_URL}/${url}`,
    data: requestBody,
    responseType,
    params,
    headers: {
      "Content-Type": contentType || `application/json`,
      ...extraHeaders,
      token,
    },
  })
    .then(response => {
      clearTimeout(errorTime)
      return response
    })
    .catch(error => {
      Sentry.captureException(error, { tags: { hall } })
      if (history && error.response) {
        clearTimeout(errorTime)
        if (error.response.status === 400) {
          if (
            error.response.data &&
            Array.isArray(error.response.data.errors)
          ) {
            error.response.data.errors.forEach(element => {
              notification.error({
                message: element,
              })
            })
          }
        }
        if (error.response.status === 404) {
          localStorage.setItem(`404`, `show`)
          history.push(ERROR_PAGE)
        }
        if (error.response.status === 403) {
          ultimateLogout()
        }
        if (error.response.status === 500 || error.response.status === 502) {
          localStorage.setItem(`500`, `show`)
          history.push(SERVER_ERROR_PAGE)
        }
        if (error.response.status === 504) {
          localStorage.setItem(`504`, `show`)
          history.push(SERVER_ERROR_PAGE)
        }
      }
      throw error
    })
}

export default Request
