import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { InputComponent } from "Components/Input"
import { ButtonDefault } from "Components/Button"
import { downloadFile } from "api/hooks"
import { ReactComponent as ExcelIcon } from "Container/icons/icon-excel.svg"
import AccessPrice from "./AccessPrice"

const AccessInfo = ({ ultimateLogout }) => {
  const history = useHistory()
  // Download pending state
  const [loadPending, loadSetPending] = useState(false)
  // search state
  const [searchValue, setSearchValue] = useState({
    name: null,
    hallNumber: null,
  })
  const [searchInput, setSearchInput] = useState({
    name: null,
    hallNumber: null,
  })

  return (
    <div>
      <div className="AdminPanel-contentTitle">
        <h1>Доступы</h1>
      </div>
      <div className="AdminPanel-contentHeader">
        <div className="AdminPanel-contentLeft">
          <div
            className="AdminPanel-contentCell InputWrapper-productsSearch"
            style={{ width: `235px` }}
          >
            <InputComponent
              size="small"
              placeholder="Введите название прайса"
              allowClear
              value={searchInput.name}
              onChange={({ target: { value } }) => {
                if (value) {
                  setSearchInput({ ...searchInput, name: value })
                } else {
                  setSearchInput({ ...searchInput, name: value })
                  setSearchValue({
                    ...searchValue,
                    name: value || undefined,
                  })
                }
              }}
              onKeyDown={({ target: { value }, key }) => {
                if (key === `enter` || key === `Enter`) {
                  setSearchValue({
                    ...searchValue,
                    name: value || undefined,
                  })
                }
              }}
            />
          </div>
          <div
            className="AdminPanel-contentCell InputWrapper-productsSearch"
            style={{ width: `200px` }}
          >
            <InputComponent
              size="small"
              placeholder="Введите номер клуба"
              allowClear
              value={searchInput.hallNumber}
              onChange={({ target: { value } }) => {
                if (value) {
                  setSearchInput({ ...searchInput, hallNumber: value })
                } else {
                  setSearchInput({ ...searchInput, hallNumber: value })
                  setSearchValue({
                    ...searchValue,
                    hallNumber: value || undefined,
                  })
                }
              }}
              onKeyDown={({ target: { value }, key }) => {
                if (key === `enter` || key === `Enter`) {
                  setSearchValue({
                    ...searchValue,
                    hallNumber: value || undefined,
                  })
                }
              }}
            />
          </div>
        </div>
        <div
          className="AdminPanel-contentRight"
          style={{ display: `flex`, flexDirection: `row` }}
        >
          <div className="AdminPanel-contentCell ButtonDefault-unloadToExcel">
            <ButtonDefault
              disabled={loadPending}
              size="small"
              onClick={() =>
                downloadFile({
                  ultimateLogout,
                  history,
                  source: `admin/access/halls`,
                  fileName: `Доступы клубов.xls`,
                  loadSetPending,
                  search: searchValue,
                })
              }
            >
              <div className="ButtonDefault-icon">
                <ExcelIcon />
              </div>
              Выгрузить в Excel
            </ButtonDefault>
          </div>
        </div>
      </div>
      <AccessPrice
        ultimateLogout={ultimateLogout}
        searchValue={searchValue}
        history={history}
      />
    </div>
  )
}

export default AccessInfo
