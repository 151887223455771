import React, { useState } from "react"
import Modal from "Components/Modal"
import { CheckBoxDefault } from "Components/CheckBox"
import { InputArea } from "Components/Input"
import { ReactComponent as SuccessRatingIcon } from "Container/icons/successRating.svg"
import { ReactComponent as AttachIcon } from "Container/icons/attach.svg"
import { ReactComponent as BucketIcon } from "Container/icons/bucket.svg"
import Button from "Components/Button"
import { Upload, notification } from "antd"
import ratingApi, { getFileIcon, checkFormat } from "../helper"

const DislikeModalComponent = ({
  orderId,
  close,
  setCurrentRating,
  currentRating,
}) => {
  const [reasonsList, setReason] = useState({
    damaged: false,
    partial: false,
    other: false,
    comment: null,
    files: [],
  })

  const [baseFiles, setBaseFiles] = useState([])

  const [successSendRating, setSuccessRating] = useState(false)

  const sendDisabled =
    (!reasonsList.damaged && !reasonsList.partial && !reasonsList.other) ||
    !reasonsList.comment

  return (
    <Modal
      close={close}
      title={successSendRating ? `Спасибо за отзыв` : `Что вам не понравилось?`}
      width="480px"
    >
      {successSendRating ? (
        <div className="SuccessRatingWrapper">
          <SuccessRatingIcon />
        </div>
      ) : (
        <div>
          <div className="ReasonsWrapper">
            <CheckBoxDefault
              checked={reasonsList.damaged}
              onClick={() =>
                setReason({ ...reasonsList, damaged: !reasonsList.damaged })
              }
              label="Товар повреждён"
            />
            <CheckBoxDefault
              checked={reasonsList.partial}
              onClick={() =>
                setReason({ ...reasonsList, partial: !reasonsList.partial })
              }
              label="Пересорт, недовоз товара"
            />
            <CheckBoxDefault
              checked={reasonsList.other}
              onClick={() =>
                setReason({
                  ...reasonsList,
                  other: !reasonsList.other,
                })
              }
              label="Другое"
            />
          </div>
          <div style={{ padding: `0 16px` }}>
            <InputArea
              placeholder="Комментарий"
              value={reasonsList.comment}
              rows={2}
              onChange={({ target: { value } }) =>
                setReason({ ...reasonsList, comment: value })
              }
            />
          </div>
          <div className="UploaderBlock">
            <Upload
              onChange={({ file }) => {
                const format = checkFormat(file.name)
                if (format) {
                  const typeFile = { ...file, type: format }
                  setReason({
                    ...reasonsList,
                    files: [...reasonsList.files, typeFile],
                  })
                  const reader = new FileReader()
                  reader.onload = e => {
                    // eslint-disable-next-line no-param-reassign
                    typeFile.base64 = e.target.result
                  }
                  reader.readAsDataURL(file.originFileObj)
                  setBaseFiles([...baseFiles, typeFile])
                } else {
                  notification.error({
                    message: `Некоректный формат файла.`,
                  })
                }
              }}
              customRequest={() => null}
              itemRender={() => null}
            >
              <div className="UploaderWrapper">
                <AttachIcon />
                <div className="UploaderText">Прикрепить файл</div>
              </div>
            </Upload>
          </div>
          <div className="UploadFileListWrapper">
            {reasonsList.files.map(file => (
              <div className="UploadItemRender">
                <div className="UploadItemTitle">
                  <div className="UploadItemIcon">{getFileIcon(file.type)}</div>
                  <div className="UploadItemLabel">{file.name}</div>
                </div>
                <div
                  className="UploadItemDelete"
                  onClick={() => {
                    const filterReasonsList = reasonsList.files.filter(
                      item => item.uid !== file.uid,
                    )
                    const filterBasedList = baseFiles.filter(
                      item => item.uid !== file.uid,
                    )
                    setReason({ ...reasonsList, files: filterReasonsList })
                    setBaseFiles(filterBasedList)
                  }}
                >
                  <BucketIcon />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="DislikeButtonWrapper">
        <Button
          style={{ width: `calc(100% - 32px)`, height: `44px` }}
          disabled={sendDisabled}
          onClick={() =>
            successSendRating
              ? close()
              : ratingApi({
                  orderId,
                  value: false,
                  setRatingData: setCurrentRating,
                  ratingData: currentRating,
                  disData: { ...reasonsList, files: baseFiles },
                  baseFiles,
                  toggleSuccess: () => setSuccessRating(true),
                })
          }
        >
          {successSendRating ? `К заказам` : `Отправить`}
        </Button>
      </div>
    </Modal>
  )
}
export default DislikeModalComponent
