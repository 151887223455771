import React, { useState, useEffect } from "react"
import { InputComponent } from "Components/Input"
import { RangePickerComponent } from "Components/DatePicker"
import { useHistory } from "react-router-dom"
import { ButtonDefault } from "Components/Button"
import { downloadFile } from "api/hooks"
import { ReactComponent as ExcelIcon } from "Container/icons/icon-excel.svg"
import { ReactComponent as SearchIcon } from "Container/icons/search.svg"
import PaginationComponent from "Components/Pagination"
import { ReactComponent as AttachIcon } from "./stroke.svg"
import getMessagesList, { getCurrentDate } from "./helper"

const MessageRoute = ({ ultimateLogout, firstPack, lastMessage }) => {
  const [searchValue, setSearchValue] = useState({
    text: undefined,
    dateStart: undefined,
    dateFinish: undefined,
  })
  const history = useHistory()
  const [loadPending, loadSetPending] = useState(false)
  const [messageData, setData] = useState({
    data: [],
    pending: false,
    page: 1,
    total: 0,
  })
  useEffect(() => {
    if (messageData.page !== 1) {
      setData({
        ...messageData,
        page: 1,
      })
    } else {
      getMessagesList({
        history,
        ultimateLogout,
        messageData,
        setData,
        searchValue,
        hall: firstPack.data.hall,
      })
    }
  }, [searchValue.text, searchValue.dateStart, searchValue.dateFinish])
  useEffect(() => {
    getMessagesList({
      history,
      ultimateLogout,
      messageData,
      setData,
      searchValue,
      hall: firstPack.data.hall,
    })
  }, [messageData.page, lastMessage])
  return (
    <div className="WidthAll">
      <div className="AdminPanel-contentTitle">
        <h1>Сообщения</h1>
      </div>
      <div className="MessageContentHeader">
        <div className="MessageContentLeft">
          <div
            className="MessageContentCell"
            style={{ paddingRight: `16px`, width: `240px` }}
          >
            <InputComponent
              placeholder="Поиск"
              onChange={({ target: { value } }) => {
                setSearchValue({
                  ...searchValue,
                  text: value || undefined,
                })
              }}
              suffix={
                <div style={{ padding: `10px 0 5px 0` }}>
                  <SearchIcon />
                </div>
              }
              size="small"
            />
          </div>
          <div className="MessageContentCell">
            <RangePickerComponent
              size="small"
              value={[searchValue.dateStart, searchValue.dateFinish]}
              placeholder={[`Выберите`, `даты`]}
              onChange={value => {
                setSearchValue({
                  ...searchValue,
                  dateStart: value
                    ? value[0].startOf(`day`).valueOf()
                    : undefined,
                  dateFinish: value
                    ? value[1].endOf(`day`).valueOf()
                    : undefined,
                })
              }}
            />
          </div>
        </div>
        <div className="MessageContentRight">
          <div className="MessageContentCell ButtonDefault-unloadToExcel">
            <ButtonDefault
              disabled={loadPending}
              size="small"
              onClick={() =>
                downloadFile({
                  ultimateLogout,
                  history,
                  source: `notifications`,
                  fileName: `Список сообщений.xls`,
                  loadSetPending,
                  search: searchValue,
                  hall: firstPack.data.hall,
                })
              }
            >
              <div className="ButtonDefault-icon">
                <ExcelIcon />
              </div>
              Выгрузить в Excel
            </ButtonDefault>
          </div>
        </div>
      </div>
      <div className="MessageContentWrapper">
        {messageData.data.map(
          ({ content, isRead, messageId, files, scheduledDate, title }) => (
            <div
              className="MessageItemWrapper"
              onClick={() => history.push(`/message/${messageId}`)}
            >
              <div className="FlexRow">
                <div className="MessageItemIsReadWrapper GridPlaceCenter">
                  {!isRead ? <div className="MessageItemIsRead" /> : <div />}
                </div>
                <div
                  className={`MessageItemTitleWrapper GridPlaceCenter ${
                    !isRead ? `MessageItemTitleUnread` : ``
                  }`}
                >
                  {title}
                </div>
                <div className="MessageItemFileWrapper GridPlaceCenter">
                  {files && files.length ? <AttachIcon /> : <div />}
                </div>
                <div className="MessageItemDateWrapper GridPlaceCenter">
                  {getCurrentDate(scheduledDate)}
                </div>
              </div>
              <div className="MessageItemContentWrapper">{content}</div>
            </div>
          ),
        )}
        {!!messageData.total && messageData.total > 20 && (
          <div className="MessageContentPaginationWrapper">
            <PaginationComponent
              page={messageData.page}
              total={messageData.total}
              pending={messageData.pending}
              perPage={20}
              changePage={value => setData({ ...messageData, page: value })}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default MessageRoute
