import Request from "api/request"

const updateProduct = ({
  guid,
  field,
  value,
  setSwitchValue,
  ultimateLogout,
  history,
}) => {
  setSwitchValue(value)
  Request({
    ultimateLogout,
    url: `admin/product/update`,
    method: `put`,
    history,
    requestBody: {
      guid,
      field,
      value,
    },
  })
    .then(() => null)
    .catch(() => setSwitchValue(!value))
}

export default updateProduct
