import React, { useMemo } from "react"

import { checkRole } from "api/constants"

import SelectComponent, { TreeSelectComponent } from "Components/Select"
import { RangePickerComponent } from "Components/DatePicker"
import { Select, TreeSelect } from "antd"
import { filterConstants, statusBadgeColor } from "./constants"

const { Option } = Select

const OrdersFilter = ({
  pending,
  filter,
  setFilter,
  resetPagination,
  statusData,
}) => {
  const allHallsArr = (localStorage.getItem(`halls`) || ``).split(`,`)
  const halls = allHallsArr.map(item => ({
    title: item,
    value: item,
    key: item,
  }))
  const { isAdmin } = useMemo(() => checkRole(), [])
  return (
    <div className="OrdersFilterWrapper">
      <RangePickerComponent
        disabled={pending}
        value={[filter.dates?.from, filter.dates?.to]}
        placeholder={filterConstants.placeholder.dates}
        onChange={value => {
          setFilter({
            ...filter,
            dates: value ? { from: value[0], to: value[1] } : undefined,
          })
          resetPagination()
        }}
      />
      <div className="OrdersFilterStatusWrapper">
        <SelectComponent
          allowClear={!!filter.status_id}
          disabled={pending}
          value={filter.status_id}
          onChange={value => {
            const isAll = value === filterConstants.allHallsOption.value
            setFilter({
              ...filter,
              status_id: isAll ? undefined : value || undefined,
            })
            resetPagination()
          }}
          placeholder={filterConstants.placeholder.status}
        >
          {[filterConstants.allHallsOption, ...statusData].map(item => {
            const badge = statusBadgeColor(item.id)
            const isAll = !item.id
            return (
              <Option
                value={item?.id || filterConstants.allHallsOption.value}
                className="OrdersFilterStatusOption"
              >
                {isAll ? (
                  <div className="OrdersFilterStatusAll">{item.label}</div>
                ) : (
                  <div className="OrdersFilterStatus">
                    <div
                      style={{ backgroundColor: badge }}
                      className="OrdersFilterStatusBadge"
                    />
                    <span>{item?.status}</span>
                  </div>
                )}
              </Option>
            )
          })}
        </SelectComponent>
      </div>
      {isAdmin ? (
        <div />
      ) : (
        <TreeSelectComponent
          disabled={pending}
          className="OrdersFilterHallTreeSelect"
          showCheckedStrategy={TreeSelect.SHOW_ALL}
          treeData={halls}
          value={filter.halls}
          placeholder={filterConstants.placeholder.halls}
          onChange={value => {
            const newValue = value?.length ? value : undefined
            setFilter({ ...filter, halls: newValue })
            resetPagination()
          }}
        />
      )}
    </div>
  )
}
export default OrdersFilter
