import React from "react"

const DeliveryInfo = ({ priceData }) => {
  const convertNewLinesToBr = str =>
    str ? str.replace(/(?:\r\n|\r|\n)/g, "<br />") : ""

  const createMarkup = str => ({ __html: str })

  let text = ""

  if (priceData.data && priceData.data.constructor === Array) {
    priceData.data.forEach(item => {
      if (item.id === priceData.active) {
        text = convertNewLinesToBr(item.delivery)
      }
    })
  }

  if (text) {
    return (
      <div className="DeliveryInfoSidebar">
        <div dangerouslySetInnerHTML={createMarkup(text)} />
      </div>
    )
  }

  return ""
}

export default DeliveryInfo
