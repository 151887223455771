import "types/main"
import makeCurrency from "api/constants"

import moment from "moment"

export const statuses = {
  new: `1`,
  decline: `2`,
  agree: `3`,
  transferred: `4`,
  delivered: `5`,
  shipped: `6`,
  error: `7`,
}

/**
 *
 * @param {Array<OrderItem>} order
 */

const getOrdersConstants = ({ order }) => ({
  title: `Заказ № ${order.id}`,
  date: `от ${moment(order.date).format(`DD MMMM HH:mm`)}`,
  category: `Категория`,
  price: `Цена`,
  count: `Кол-во`,
  sum_value: `${makeCurrency(order.sum)} ₽`,
  sum: `Общая сумма`,
  hall: `Клуб:`,
  name: `Контактное лицо:`,
  phone: `Телефон:`,
  comment: `Комментарий к заказу:`,
  repeat_button: `Повторить заказ`,
  repeat_url: `/cart/repeat/${order.id}`,
  all_goods: `Все товары`,
})

export default getOrdersConstants
