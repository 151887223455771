import React from "react"

const InsertGoods = ({ goods }) =>
  goods.map(item => (
    <div key={`${item.name}_${item.category}`} className="LeftoverGoods-row">
      <div className="LeftoverGoods-item LeftoverGoods-itemTitle">
        <span className="LeftoverGoods-itemName">{item.name}</span>
      </div>
      <div className="LeftoverGoods-item LeftoverGoods-itemCount">
        <span className="s-show">Категория</span>
        <span className="LeftoverGoods-itemCategory">{item.category}</span>
      </div>
      <div className="LeftoverGoods-item LeftoverGoods-itemCount">
        <span className="s-show">Кол-во:</span>
        <span className="LeftoverGoods-itemCount">{item.count}</span>
      </div>
    </div>
  ))

export default InsertGoods
