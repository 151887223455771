import React from "react"
import Request from "api/request"
import moment from "moment"

export const getCurrentDate = date => {
  const momentDate = moment(date)
  const isToday = moment().startOf(`day`).valueOf() < date

  return isToday ? (
    <div className="MessageItemDateMonthWrapper MessageItemDateMonthTimeWrapper">
      {momentDate.format(`HH:mm`)}
    </div>
  ) : (
    <div className="FlexRow">
      <div>{momentDate.format(`DD`)}</div>
      <div className="MessageItemDateMonthWrapper">
        {(momentDate.format(`MMM`) || ``).slice(0, 3)}
      </div>
    </div>
  )
}

const getMessagesList = ({
  messageData,
  setData,
  history,
  ultimateLogout,
  searchValue,
  hall,
}) => {
  setData({ ...messageData, pending: true })
  Request({
    method: `POST`,
    url: `notifications`,
    history,
    ultimateLogout,
    requestBody: {
      ...searchValue,
      hallNumber: hall,
      limit: 20,
      offset: (messageData.page - 1) * 20,
    },
  }).then(({ data, headers }) => {
    setData({
      ...messageData,
      data,
      pending: false,
      total: headers[`total-count`] || 20,
    })
  })
}

export default getMessagesList
