import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { ReactComponent as LikeActiveIcon } from "Container/icons/likeActive.svg"
import { ReactComponent as DislikeActiveIcon } from "Container/icons/dislikeActive.svg"

// import { changeComment } from "./helper"

import { downloadFile, useStatuses } from "api/hooks"

import StatusComponent from "Components/Status"
// import { InputArea } from "Components/Input"
import TableComponent from "Components/Table"
import { ButtonDefault } from "Components/Button"

import moment from "moment"
import { ReactComponent as ArrowIcon } from "Container/icons/arrow-left.svg"
import { ReactComponent as ExcelIcon } from "Container/icons/icon-excel.svg"

import makeCurrency, { getUnitData } from "api/constants"
import getOrder from "./helper"

const OrderInfo = ({ ultimateLogout }) => {
  const { id } = useParams()
  const history = useHistory()

  const [loadPending, loadSetPending] = useState(false)

  const [unitData, setUnitData] = useState({})

  const [data, setOrder] = useState({})
  const [currentStatusComment, setStatusComment] = useState(null)
  const { data: statusData } = useStatuses({ ultimateLogout, history })

  useEffect(() => {
    if (id)
      getOrder({
        id,
        setOrder,
        setStatusComment,
        ultimateLogout,
        currentStatusComment,
        history,
      })
    getUnitData({ ultimateLogout, setUnitData })
  }, [id])

  const columns = [
    {
      title: `название товара`,
      key: `name`,
      dataIndex: `name`,
    },
    {
      title: `Тип прайса`,
      key: `priceList`,
      dataIndex: `priceList`,
    },
    {
      title: `Категория`,
      key: `category`,
      dataIndex: `category`,
    },
    {
      title: `Артикул`,
      key: `article`,
      dataIndex: `article`,
    },
    {
      title: `Цена`,
      key: `price`,
      dataIndex: `price`,
      render: price => <div>{makeCurrency(price)} ₽</div>,
    },
    {
      title: `Кол-во`,
      key: `unitCount`,
      dataIndex: `unitCount`,
      render: (unitCount, { unitId }) => {
        const unitName = unitData[unitId] ? ` ${unitData[unitId].name}` : ``
        return (
          <div>
            {unitCount}
            {unitName}
          </div>
        )
      },
    },
  ]

  // TODO: status comment logic
  // const [disabledComment, toggleDisabledComment] = useState(true)
  return (
    <div>
      <div className="AdminPanel-contentBack">
        <div
          className="BackWrapper"
          onClick={() => history.push(`/admin/orders`)}
        >
          <div className="BackWrapper-icon">
            <ArrowIcon />
          </div>
          Назад
        </div>
      </div>
      <div className="AdminPanel-contentTitle AdminPanel-contentTitle_with">
        <h1>Заказ № {id}</h1>
        <div className="ButtonDefault-unloadToExcel">
          <ButtonDefault
            size="small"
            disabled={loadPending}
            onClick={() =>
              downloadFile({
                ultimateLogout,
                source: `admin/order`,
                requestBody: { id },
                fileName: `Заказ № ${id} (клуб № ${data.hallNumber}).xls`,
                loadSetPending,
                history,
              })
            }
          >
            <div className="ButtonDefault-icon">
              <ExcelIcon />
            </div>
            Выгрузить в Excel
          </ButtonDefault>
        </div>
      </div>
      <div className="AdminPanel-contentInfoOrder">
        <div className="InfoOrderWrapper">
          <div className="InfoOrderWrapper-row">
            <div className="InfoOrderWrapper-label">№ клуба</div>
            <div className="InfoOrderWrapper-value">{data.hallNumber}</div>
          </div>
          <div className="InfoOrderWrapper-row">
            <div className="InfoOrderWrapper-label">Адрес клуба</div>
            <div className="InfoOrderWrapper-value">{data.address}</div>
          </div>
          <div className="InfoOrderWrapper-row">
            <div className="InfoOrderWrapper-label">Статус</div>
            <div className="InfoOrderWrapper-status">
              <StatusComponent
                id={data.status}
                withComment={data.statusComment}
                statusData={statusData}
                viewedComment={false}
              />
            </div>
          </div>
          <div className="InfoOrderWrapper-row">
            <div className="InfoOrderWrapper-label">Комментарий админа</div>
            <div className="InfoOrderWrapper-value">{data.statusComment}</div>
          </div>
          <div className="InfoOrderWrapper-row">
            <div className="InfoOrderWrapper-label">Оценка</div>
            <div className="InfoOrderWrapper-value InfoOrderWrapper-value-rating">
              {/* eslint-disable-next-line no-nested-ternary */}
              {data.rating === 1 ? (
                <LikeActiveIcon />
              ) : data.rating === 0 ? (
                <DislikeActiveIcon />
              ) : (
                ``
              )}
            </div>
          </div>
          {/* TODO: back this logic in future */}
          {/* <div className="InfoOrderWrapper-area"> */}
          {/*    <InputArea */}
          {/*        value={currentStatusComment} */}
          {/*        disabled={disabledComment} */}
          {/*        placeholder="Комментарий к статусу" */}
          {/*        onChange={({target: {value}}) => setStatusComment(value)} */}
          {/*        addButton={<div> */}
          {/*            {!disabledComment */}
          {/*                ? (<Button */}
          {/*                    onClick={() => { */}
          {/*                        changeComment({ */}
          {/*                            id, */}
          {/*                            value: currentStatusComment, */}
          {/*                            toggleDisabledComment, */}
          {/*                            ultimateLogout, */}
          {/*                             history, */}
          {/*                        }) */}
          {/*                    }} */}
          {/*                    size="small" */}
          {/*                > */}
          {/*                    Добавить */}
          {/*                </ButtonDefault>) */}
          {/*                : (<span */}
          {/*                    onClick={() => toggleDisabledComment(false)} */}
          {/*                    className="ButtonInsideArea-text" */}
          {/*                > */}
          {/*                    Изменить */}
          {/*                </span>) */}
          {/*            } */}
          {/*        </div>} */}
          {/*    /> */}
          {/* </div> */}
        </div>
        <div className="InfoOrderWrapper">
          <div className="InfoOrderWrapper-row">
            <div className="InfoOrderWrapper-label">Имя Фамилия</div>
            <div className="InfoOrderWrapper-value">{data.fullName}</div>
          </div>
          <div className="InfoOrderWrapper-row">
            <div className="InfoOrderWrapper-label">Телефон</div>
            <div className="InfoOrderWrapper-value">{data.phone}</div>
          </div>
          <div className="InfoOrderWrapper-row">
            <div className="InfoOrderWrapper-label">Дата создания</div>
            <div className="InfoOrderWrapper-value">
              {data.date ? moment(data.date).format(`DD.MM.YYYY`) : `-`}
            </div>
          </div>
          <div className="InfoOrderWrapper-row">
            <div className="InfoOrderWrapper-label">Комментарий</div>
            <div className="InfoOrderWrapper-comment">{data.comment}</div>
          </div>
        </div>
      </div>
      <div className="AdminPanel-contentTableHead">
        <h2>Товары данного заказа</h2>
        <div className="AdminPanel-contentTotalCost">
          Общая стоимость заказа:
          <div className="AdminPanel-contentTotalValue">
            {makeCurrency(data.sum)} ₽
          </div>
        </div>
      </div>
      <div className="AdminPanel-contentTable">
        <TableComponent
          source="admin/order/products"
          columns={columns}
          extraBody={{ id }}
          ultimateLogout={ultimateLogout}
        />
      </div>
    </div>
  )
}

export default OrderInfo
