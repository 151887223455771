import React from "react"
import { CheckBoxDefault } from "Components/CheckBox"
import { InputCountComponent } from "Components/Input"
import makeCurrency from "api/constants"
import { ReactComponent as CartGreyIcon } from "Container/icons/cart_grey.svg"
import { ReactComponent as ClockIcon } from "Container/icons/clock.svg"
import { updateCart } from "../../helper"

const GoodsRow = ({
  refreshList,
  product,
  cartItems,
  setCartItems,
  unitData,
  hall,
  isCart,
  tobbaccoDisabled,
  setSelectedGuids,
  selectedGuids,
  category,
  currentPrice: currentPriceName,
  cartCountPending,
  setCardCountPending,
}) => {
  const id = product.productGuid || product.guid
  const isAvailableSoon = product.isDeleted === "0" && product.available === "0"
  const productMax = unitData[product.unitId]?.max || 10
  const productUnitName = unitData[product.unitId]?.name || ""
  const productUnitSingleName = productUnitName.split("/")[0] || productUnitName
  const currentPrice = Number(product.price) * Number(product.multiplicity || 1)

  const unitPrice =
    Number(product.unitCount) && currentPrice
      ? currentPrice / Number(product.unitCount)
      : 0

  const deleteCartItem = () => {
    const nextCartItems = Object.keys(cartItems)
      .filter(key => key !== id)
      .reduce((result, key) => {
        result[key] = cartItems[key]
        return result
      }, {})

    setCartItems(nextCartItems)
    updateCart({ guid: id, hall, quantity: 0 })
  }

  const handleCheckBoxClick = () => {
    const updatedGuids = selectedGuids.includes(id)
      ? selectedGuids.filter(el => el !== id)
      : [...selectedGuids, id]

    setSelectedGuids(updatedGuids)
  }

  return (
    <div id={id?.toString()} className="PriceTable-row-container">
      <div className={`${isCart ? "PriceTable_container_header" : ""}`}>
        {isCart && (
          <CheckBoxDefault
            checked={selectedGuids.includes(id)}
            onClick={handleCheckBoxClick}
          />
        )}
        <div className="PriceTable-item PriceTable-itemTitle PriceTable-header">
          <span className="PriceTable-itemName">{product.title}</span>
          <div className="PriceTable-itemName-subgroup">
            <div>{`Артикул: ${
              product.article.length > 0 ? product.article : "-"
            }`}</div>
            <div>{`${product.unitCount} ${productUnitName}`}</div>
            {isAvailableSoon && (
              <div className="PriceTable-info-badge_container">
                <ClockIcon />
                <div className="PriceTable-isAvailableSoon_text">
                  Скоро появится
                </div>
              </div>
            )}
            {isCart && <CartGreyIcon onClick={deleteCartItem} />}
          </div>
        </div>
      </div>

      <div className="PriceTable-row-body">
        <div
          className={`PriceTable-price-main ${
            product.promo ? "PriceTable-price-main_promo" : ""
          }`}
        >
          {`${makeCurrency(currentPrice)} ₽`}
        </div>
        <div className="PriceTable-price-secondary">{`${makeCurrency(
          unitPrice.toFixed(2),
        )} ₽ ${
          productUnitSingleName ? "/" : ""
        } ${productUnitSingleName}`}</div>
        <div className="PriceTable-item PriceTable-itemCount">
          <InputCountComponent
            tobbaccoDisabled={tobbaccoDisabled}
            maxCount={productMax}
            cartItems={cartItems}
            product={product}
            setCartItems={setCartItems}
            isCart={isCart}
            isAvailableSoon={isAvailableSoon}
            withSum
            category={category}
            currentPriceName={currentPriceName}
            refreshList={refreshList}
            cartCountPending={cartCountPending}
            setCardCountPending={setCardCountPending}
          />
        </div>
      </div>
    </div>
  )
}

export default GoodsRow
