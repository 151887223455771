import React, { useState, useEffect } from "react"
import { Radio } from "antd"
import { ButtonDefault } from "Components/Button"
import RadioComponent from "Components/Radio"
import SelectComponent from "Components/Select"
import { useParams, useHistory } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { ACCESS_PRICE_ROUTE } from "Container/Admin/constatnts"
import { onlyUnique } from "api/constants"
import getPriceItem, { sendPriceItem } from "./helper"

const SelectHalls = ({ priceAccessData, setPriceAccessData }) => {
  const withType = priceAccessData.type === 2 ? `with` : null
  const withoutType = priceAccessData.type === 3 ? `without` : null
  return (
    <SelectComponent
      name="halls"
      disabled={priceAccessData.pending}
      className="SelectHallsWrapper"
      mode="tags"
      allowClear
      value={priceAccessData.halls[withType || withoutType] || []}
      onChange={value => {
        if (
          !priceAccessData.halls[withType || withoutType] ||
          priceAccessData.halls[withType || withoutType].length < value.length
        ) {
          const lastEnter = value.pop().split(`,`).map(Number)
          const newValue = [...value, ...lastEnter].filter(onlyUnique)
          setPriceAccessData({
            ...priceAccessData,
            halls: {
              ...priceAccessData.halls,
              [withType || withoutType]: newValue,
            },
          })
        } else if (value) {
          setPriceAccessData({
            ...priceAccessData,
            halls: {
              ...priceAccessData.halls,
              [withType || withoutType]: value,
            },
          })
        }
      }}
    />
  )
}

const AccessPriceItem = ({ ultimateLogout }) => {
  const { id } = useParams()
  const history = useHistory()
  const [priceAccessData, setPriceAccessData] = useState({
    name: ``,
    type: 0,
    halls: { with: null, without: null },
    pending: false,
  })
  useEffect(() => {
    getPriceItem({
      ultimateLogout,
      history,
      id,
      setData: setPriceAccessData,
      data: priceAccessData,
    })
  }, [id])
  return (
    <div>
      <div
        className="AccessPriceEditBackWrapper"
        onClick={() => history.push(ACCESS_PRICE_ROUTE)}
      >
        <ArrowLeftOutlined color="#757681" />
        <div>Назад</div>
      </div>
      <div className="AccessPriceEditWrapper">
        <div className="AccessPriceEditTitle">{priceAccessData.name}</div>
        <Radio.Group
          name="price_access"
          value={priceAccessData.type}
          onChange={({ target: { value } }) => {
            setPriceAccessData({
              ...priceAccessData,
              type: value,
            })
          }}
        >
          <RadioComponent className="AccessPriceEditRadioWrapper" value={0}>
            Никому
          </RadioComponent>
          <RadioComponent className="AccessPriceEditRadioWrapper" value={1}>
            Доступен для всех клубов
          </RadioComponent>
          <RadioComponent className="AccessPriceEditRadioWrapper" value={2}>
            Доступен для списка клубов
          </RadioComponent>
          {priceAccessData.type === 2 && (
            <div className="AccessPriceEditHallsArea">
              <SelectHalls
                priceAccessData={priceAccessData}
                setPriceAccessData={setPriceAccessData}
                ultimateLogout={ultimateLogout}
                history={history}
              />
            </div>
          )}
          <RadioComponent className="AccessPriceEditRadioWrapper" value={3}>
            Доступен для всех клубов, кроме
          </RadioComponent>
          {priceAccessData.type === 3 && (
            <div className="AccessPriceEditHallsArea">
              <SelectHalls
                priceAccessData={priceAccessData}
                setPriceAccessData={setPriceAccessData}
                ultimateLogout={ultimateLogout}
                history={history}
              />
            </div>
          )}
        </Radio.Group>
        <ButtonDefault
          style={{ marginTop: `32px` }}
          onClick={() =>
            sendPriceItem({
              ultimateLogout,
              history,
              id,
              data: priceAccessData,
            })
          }
        >
          Сохранить
        </ButtonDefault>
      </div>
    </div>
  )
}

export default AccessPriceItem
