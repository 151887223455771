import React from "react"
import { Checkbox } from "antd"

const CheckBoxComponent = ({
  cartItems,
  setCartItems,
  product,
  label,
  tobbaccoDisabled,
  ...rest
}) => {
  const addItemsToCart = () => {
    if (cartItems[product.guid].count === 0) {
      const multiplicity = product.multiplicity
        ? Number(product.multiplicity)
        : 1

      setCartItems({
        ...cartItems,
        [product.guid]: {
          ...cartItems[[product.guid]],
          price: product.price,
          count: multiplicity,
        },
      })
    } else {
      setCartItems({
        ...cartItems,
        [product.guid]: { ...cartItems[[product.guid]], count: 0 },
      })
    }
  }

  const isChecked = val => val !== 0

  return (
    <div className="CheckboxWrapper">
      <Checkbox
        disabled={tobbaccoDisabled}
        checked={
          cartItems[product.guid] !== undefined
            ? isChecked(cartItems[product.guid].count)
            : false
        }
        onClick={addItemsToCart}
        {...rest}
      />
      <div className="CheckboxText" style={{ paddingLeft: `12px` }}>
        {label}
      </div>
    </div>
  )
}

export default CheckBoxComponent

export const CheckBoxDefault = ({ label, ...rest }) => (
  <div className="CheckboxWrapper">
    <Checkbox {...rest} />
    <div className="CheckboxText" style={{ paddingLeft: `12px` }}>
      {label}
    </div>
  </div>
)
